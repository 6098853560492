import React from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Typography } from '@mui/material';
import { BoltDrawDialog } from './BoltDrawDialog';
import { ChaiListPage, ChaiListTableColumn } from 'common';
import { getBoltDrawListQuery, IBoltListDraw } from 'api/boltDrawGql';

interface BoltDrawListColumn extends ChaiListTableColumn {
  format?: (value: string | number, row: IBoltListDraw) => string | JSX.Element;
}

const columns: BoltDrawListColumn[] = [
  {
    id: 'id',
    label: 'id',
    format: value => (
      <Typography variant="subtitle2" color="#1664C0">
        {value}
      </Typography>
    ),
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'visibleTime',
    label: '노출 기간',
    align: 'center',
    format: (_, row: IBoltListDraw) =>
      format(new Date(row.visibleFrom), 'yyyy-MM-dd') + ' - ' + format(new Date(row.visibleTo), 'yyyy-MM-dd'),
  },
  {
    id: 'enterableTime',
    label: '진행 기간',
    align: 'center',
    format: (_, row: IBoltListDraw) =>
      format(new Date(row.enterableFrom), 'yyyy-MM-dd') + ' - ' + format(new Date(row.enterableTo), 'yyyy-MM-dd'),
  },
  {
    id: 'winnerCount',
    align: 'center',
    label: '당첨자 수',
  },
  {
    id: 'status',
    label: '상태',
    align: 'center',
    format: value =>
      value === 'enabled' ? '진행중' : value === 'draw_completed' ? '완료' : value === 'disabled' ? '비활성화' : '',
  },
];

export const BoltDrawListContainer: React.FC<{}> = () => {
  const { boltDrawId } = useParams<{ boltDrawId?: string }>();

  return (
    <ChaiListPage
      path="/bolt-draw/"
      query={getBoltDrawListQuery}
      childId={boltDrawId}
      columns={columns}
      dialog={BoltDrawDialog}
      hideAddButton
      getQueryFilter={keyword => (keyword ? JSON.stringify({ id: keyword }) : '')}
    />
  );
};
