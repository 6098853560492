import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { boostCampaignListQuery, boostCampaignUpdateMutation, IBoostCampaignListItem } from 'api/boostCampaignGql';
import { IBrand } from 'api/brandGql';
import ChaiDivider from 'common/component/ChaiDivider';
import { format } from 'date-fns';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apolloClient from 'util/apolloClient';
import { wonString } from 'util/wonString';
import styles from '../boost.module.scss';
import { BOOST_TYPE_OPTIONS } from '../BoostPromotion/BoostPromotionDialogBoostSetting';
import { BoostContentTitle } from '../component/BoostContentTitle';
import { BoostCampaignDialog } from './BoostCampaignDialog';
import { BOOST_TARGET_TYPES } from './BoostCampaignDialogContent';
import { BoostParams } from './BoostContentContainer';

export const BoostCampaignContainer: React.FC<{ brandInformation?: IBrand }> = ({ brandInformation }) => {
  const history = useHistory();
  const { brandId, campaignId } = useParams<BoostParams>();
  const campaignDialogOpen = Boolean(campaignId);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [sort, setSort] = React.useState<'all' | 'active'>('all');
  const { data, refetch: refetchCampaignContainer } = useQuery(boostCampaignListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      skip: page * rowsPerPage,
      pageSize: rowsPerPage,
      filter: JSON.stringify({ brandId, statusLabel: sort === 'active' ? '집행중' : null }),
    },
    fetchPolicy: brandId === 'new' ? 'cache-only' : 'network-only', // apollo-client 버그 walk-around
  });
  const campaignList: IBoostCampaignListItem[] = data?.boostCampaignList?.list || [];
  const campaignListTotal: number = data?.boostCampaignList?.total;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddCampaign = () => {
    history.push(`/boost/${brandId}/campaign/new`);
  };

  const handleSwitchStatus = async (row: IBoostCampaignListItem) => {
    await apolloClient.mutate({
      mutation: boostCampaignUpdateMutation,
      variables: {
        id: Number(row.id),
        status: row.status === 'enabled' ? 'disabled' : 'enabled',
      },
    });
    refetchCampaignContainer();
  };

  return (
    <>
      <Grid item>
        <BoostContentTitle title="캠페인">
          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item xs>
              <FormControl fullWidth variant="standard">
                <InputLabel id="sort-select-label">상태</InputLabel>
                <Select
                  labelId="sort-select-label"
                  id="sort-select"
                  value={sort}
                  label="정렬"
                  onChange={e => setSort(e.target.value as 'all' | 'active')}
                >
                  <MenuItem value={'all'}>전체</MenuItem>
                  <MenuItem value={'active'}>집행중</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button variant="contained" size="small" onClick={handleAddCampaign}>
                추가
              </Button>
            </Grid>
          </Grid>
        </BoostContentTitle>
        <ChaiDivider />
        <Box m={2} />
        <Grid container direction="column" justifyContent="center">
          <Typography variant="subtitle2" align="right" color="textSecondary">
            전체: {campaignListTotal || 0}
          </Typography>
          <Box m={1} />
          <Grid container spacing={2}>
            {campaignList?.map(row => {
              return (
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} className={styles.listItemWrapper} key={row.id}>
                  <Card
                    variant="outlined"
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`/boost/${brandId}/campaign/${row.id}`)}
                  >
                    <CardHeader
                      classes={{
                        root: styles.cardHeader,
                        content: styles.cardContent,
                      }}
                      subheaderTypographyProps={{
                        variant: 'caption',
                      }}
                      style={{ paddingBottom: 0 }}
                    />
                    <CardContent style={{ paddingTop: 0 }}>
                      <Table size="small" style={{ tableLayout: 'fixed' }}>
                        <TableBody>
                          <TableRow style={{ paddingBottom: 8 }}>
                            <TableCell padding="none" style={{ borderBottom: 'none' }}>
                              <Typography noWrap variant="h6" color="primary">
                                {row.id}
                              </Typography>
                            </TableCell>
                            <TableCell padding="none" align="right" style={{ borderBottom: 'none' }}>
                              <Switch
                                size="small"
                                checked={row.status === 'enabled'}
                                color="primary"
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleSwitchStatus(row);
                                  return false;
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell padding="none" colSpan={2} style={{ borderBottom: 'none' }}>
                              <Box style={{ width: '100%' }}>
                                <Typography noWrap variant="subtitle1" color="textSecondary">
                                  {row.title}
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell padding="none" colSpan={2} style={{ borderBottom: 'none', lineHeight: '28px' }}>
                              <StatusChip status={row.statusLabel} />
                              {row.reasonLabels?.length > 0 &&
                                row.reasonLabels.map((reason, idx) => (
                                  <span key={`campaign-${row.id}-reason-label-${idx}`}>
                                    &nbsp;
                                    <StatusChip status={reason} />
                                  </span>
                                ))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <ChaiDivider />
                      <Table size="small">
                        <TableBody>
                          <InfoTableRow title="시작일" text={format(new Date(row.start), 'yyyy-MM-dd HH:mm:ss')} />
                          <InfoTableRow title="종료일" text={format(new Date(row.end), 'yyyy-MM-dd HH:mm:ss')} />
                          <InfoTableRow title="구매 가능 시간" text={`${row.buyableFrom}~${row.buyableTo}`} />
                          <InfoTableRow title="사용 가능 시간" text={`${row.usableFrom}~${row.usableTo}`} />
                        </TableBody>
                      </Table>
                      <ChaiDivider />
                      <Table size="small">
                        <TableBody>
                          <InfoTableRow
                            title="유형"
                            text={
                              `${BOOST_TYPE_OPTIONS.find(item => item.value === row.boostPromotion.type)?.label}${
                                row.boostPromotion.type === 'fcfs'
                                  ? ` (${(row.boostPromotion.countCap || 0).toLocaleString()}개)`
                                  : ''
                              }` || row.boostPromotion.type
                            }
                          />
                          <InfoTableRow
                            title="캠페인 대상"
                            text={`${(row.targetTypes || []).map(
                              targetType =>
                                BOOST_TARGET_TYPES.find(optionItem => optionItem.value === targetType)?.label ||
                                targetType
                            )}`}
                          />
                          <InfoTableRow
                            title="할인"
                            text={
                              `${row.boostPromotion.promotion.discount}`.indexOf('%') > 0
                                ? `${row.boostPromotion.promotion.discount}`
                                : `${Number(row.boostPromotion.promotion.discount)?.toLocaleString() || 0}원`
                            }
                          />
                          <InfoTableRow title="번개 수" text={`${row.boostPromotion.boltPrice}개`} />
                          <InfoTableRow
                            title="최대 할인금액"
                            text={`${(row.boostPromotion?.promotion?.maxDiscountAmount || 0).toLocaleString()}원`}
                          />
                          {/* <InfoTableRow title="노출 유저" text={'Group A, Group B'} /> */}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <CardActions style={{ backgroundColor: '#ededed', paddingLeft: 16, paddingRight: 16 }}>
                      <Table size="small" padding="none">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ borderBottom: 'none' }}>
                              <Typography variant="caption">
                                {`전체 예산 (${
                                  Math.round(
                                    ((row.usedCampaignBudget + row.reservedCampaignBudget) / row.amountCap) * 10000
                                  ) / 100
                                }% 소진)`}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none', textAlign: 'right' }}>
                              <Typography variant="caption">
                                {`${wonString(row.usedCampaignBudget)} / ${wonString(
                                  row.reservedCampaignBudget + row.usedCampaignBudget
                                )} / ${wonString(row.amountCap)}`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                              <Box
                                style={{
                                  position: 'relative',
                                  height: 16,
                                  width: '100%',
                                  marginTop: 4,
                                  marginBottom: 4,
                                  backgroundColor: '#bdbdbd',
                                }}
                              >
                                <Box
                                  style={{
                                    height: 16,
                                    display: 'inline-block',
                                    backgroundColor: '#3f51b5',
                                    width: `${Math.round((row.usedCampaignBudget / row.amountCap) * 10000) / 100}%`,
                                  }}
                                />
                                <Box
                                  style={{
                                    height: 16,
                                    display: 'inline-block',
                                    backgroundColor: '#9fa8da',
                                    width: `${Math.round((row.reservedCampaignBudget / row.amountCap) * 10000) / 100}%`,
                                  }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: 'none' }}>
                              <Typography variant="caption">
                                {`오늘 예산 (${
                                  !row.dailyAmountCap
                                    ? '없음)'
                                    : Math.round(
                                        ((row.todayUsedCampaignBudget + row.todayReservedCampaignBudget) /
                                          row.dailyAmountCap) *
                                          10000
                                      ) /
                                        100 +
                                      '% 소진)'
                                }`}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none', textAlign: 'right' }}>
                              <Typography variant="caption">
                                {`${wonString(row.todayUsedCampaignBudget)} / ${wonString(
                                  row.todayReservedCampaignBudget + row.todayUsedCampaignBudget
                                )} / ${wonString(row.dailyAmountCap)}`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                              <Box
                                style={{
                                  position: 'relative',
                                  height: 16,
                                  width: '100%',
                                  marginTop: 4,
                                  marginBottom: 4,
                                  backgroundColor: '#bdbdbd',
                                }}
                              >
                                <Box
                                  style={{
                                    height: 16,
                                    display: 'inline-block',
                                    backgroundColor: '#3f51b5',
                                    width: `${
                                      Math.round((row.todayUsedCampaignBudget / row.dailyAmountCap) * 10000) / 100
                                    }%`,
                                  }}
                                />
                                <Box
                                  style={{
                                    height: 16,
                                    display: 'inline-block',
                                    backgroundColor: '#9fa8da',
                                    width: `${
                                      Math.round((row.todayReservedCampaignBudget / row.dailyAmountCap) * 10000) / 100
                                    }%`,
                                  }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: 'none' }}></TableCell>
                            <TableCell style={{ borderBottom: 'none', textAlign: 'right' }}>
                              <Typography variant="caption" color="textSecondary">
                                지급된 캐시백 / 부스트 구매 / 예산
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2} align="center" padding="none" style={{ borderBottom: 'none' }}>
                              <Button
                                startIcon={<Icon>add_to_photos</Icon>}
                                color="primary"
                                fullWidth
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  history.push(`/boost/${brandId}/campaign/${row.id}/copy`);
                                }}
                              >
                                이 스킴으로 생성
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <TablePagination
            component="div"
            rowsPerPageOptions={[8, 12, 24, 48]}
            count={campaignListTotal || rowsPerPage * page + 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <Box m={8} />
      </Grid>
      <Dialog
        maxWidth="lg"
        open={campaignDialogOpen}
        fullWidth
        onClose={(_, reason) => reason !== 'backdropClick' && history.push(`/boost/${brandId}`)}
      >
        <BoostCampaignDialog onDialogSubmit={() => refetchCampaignContainer()} basePath={`/boost/${brandId}`} />
      </Dialog>
    </>
  );
};

const InfoTableRow = (props: { title: string; text: string }) => {
  const { title, text } = props;
  return (
    <TableRow>
      <TableCell style={{ borderBottom: 'none', paddingLeft: 0 }}>{title}</TableCell>
      <TableCell style={{ borderBottom: 'none', textAlign: 'right', paddingRight: 0 }}>{text}</TableCell>
    </TableRow>
  );
};

const StatusChip = (props: { status: string; color?: string }) => {
  const { status, color } = props;
  let chipColor: string = color || '#E0E0E0';
  switch (status) {
    case '집행예정':
    case '집행중':
      chipColor = '#B9F6CA';
      break;

    case '집행중지':
    case '집행불가':
      chipColor = '#FFCDD3';
      break;

    case '집행기간만료':
      chipColor = '#EEEEEE';
      break;
  }

  return <Chip size="small" label={status} style={{ backgroundColor: chipColor }} />;
};
