import { useLazyQuery } from '@apollo/client';
import { Box, Button, Grid, Icon, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { paymentTagListQuery } from 'api/boostTagGql';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from '../../Boost/boost.module.scss';
import { ICategoryTag, ICategoryTagPaymentTag } from './CategoryTagDialog';
import { CategoryTagInput } from './CategoryTagInput';

export const CategoryTagContent: React.FC<{ isEdit: boolean; content?: ICategoryTag }> = ({ content, isEdit }) => {
  const { setValue } = useFormContext<ICategoryTag>();

  const [getPaymentTagList, { data: paymentTagList }] = useLazyQuery(paymentTagListQuery, {
    variables: { pageSize: 99999 },
  });

  useEffect(() => {
    getPaymentTagList();
  }, [getPaymentTagList]);

  const addCondition = () => {
    setValue(`paymentTagIds.${content?.paymentTagIds?.length || 0}`, undefined);
  };

  return (
    <Box bgcolor="#ededed" style={{ borderRadius: 5, padding: 20 }}>
      <Grid container spacing={2} style={{ paddingTop: 0, paddingBottom: 0 }}>
        {content?.paymentTagIds?.map((paymentTag: ICategoryTagPaymentTag | undefined, idx: number) => (
          <CategoryTagIdItem
            idx={idx}
            isEdit={isEdit}
            key={`category-tag-id-item-${idx}`}
            paymentTag={paymentTag}
            paymentTagList={paymentTagList?.paymentTagList?.list}
          />
        ))}
      </Grid>
      <Box m={2} />
      <Box textAlign="right">
        {isEdit && (
          <Button color="primary" onClick={addCondition} startIcon={<AddIcon />}>
            조건 추가
          </Button>
        )}
      </Box>
    </Box>
  );
};

const CategoryTagIdItem: React.FC<{
  idx: number;
  isEdit: boolean;
  paymentTag: ICategoryTagPaymentTag | undefined;
  paymentTagList: any;
}> = ({ idx, paymentTag, paymentTagList, isEdit }) => {
  const { reset, getValues } = useFormContext<ICategoryTag>();
  const namePrefix: `paymentTagIds.${number}` = `paymentTagIds.${idx}`;
  const handleDelete = () => {
    const values = getValues();
    reset({
      ...values,
      paymentTagIds: values.paymentTagIds.filter((item, itemIdx: number) => idx !== itemIdx),
    });
  };

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={1}>
          <Box textAlign="right" style={{ paddingRight: 24 }}>
            <Typography variant="subtitle2" color="textPrimary" className={styles.conditionOperator}>
              {idx !== 0 ? 'Or' : 'Where'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={10}>
          {isEdit && <CategoryTagInput paymentTagList={paymentTagList} namePrefix={namePrefix} />}
          {!isEdit && (
            <Typography variant="subtitle2" color="textPrimary" className={styles.conditionItem}>
              {paymentTag?.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={1} className={styles.conditionDropdown}>
          {isEdit && idx !== 0 && (
            <IconButton color="primary" onClick={() => handleDelete()} size="small">
              <Icon color="action">highlight_off</Icon>
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
