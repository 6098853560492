import { Typography } from '@mui/material';
import { categoryTagListQuery } from 'api/boostTagGql';
import { ChaiListPage, ChaiListTableColumn } from 'common';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CategoryTagDialog } from './CategoryTagDialog';

interface CategoryTagListColumn extends ChaiListTableColumn {
  id: 'id' | 'name' | 'paymentTags';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any, row: any) => string | JSX.Element;
}

const columns: CategoryTagListColumn[] = [
  {
    id: 'name',
    label: '이름',
    format: value => (
      <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
        {value}
      </Typography>
    ),
  },
  {
    id: 'paymentTags',
    label: '조건',
    format: (value: any, row) => (
      <>{value?.reduce((acc: string, item: { name: string }) => `${acc.length ? `${acc}, ` : ''}${item.name}`, '')}</>
    ),
  },
];

export const CategoryTagListContainer: React.FC<{}> = props => {
  const { categoryTagId } = useParams<{ categoryTagId?: string }>();

  return (
    <ChaiListPage
      path="/boost/categoryTag/"
      query={categoryTagListQuery}
      getQueryFilter={keyword => (keyword ? JSON.stringify({ name: keyword }) : '')}
      childId={categoryTagId}
      columns={columns}
      dialog={CategoryTagDialog}
    />
  );
};
