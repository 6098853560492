import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserTagListContainer } from './UserTagListContainer';

export const UserTag: React.FC<{}> = () => {
  return (
    <>
      <Switch>
        <Route path="/tag/userTag/:userTagId" render={() => <UserTagListContainer />} />
        <Route path="/" render={() => <UserTagListContainer />} />
      </Switch>
    </>
  );
};
