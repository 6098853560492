import gql from 'graphql-tag';

export const getUserTagListQuery = gql`
  query getTagList($skip: Int, $pageSize: Int, $filter: String) {
    getTagList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        name
        status
        category
        dateHistory {
          createdAt
          updatedAt
        }
      }
      total
    }
  }
`;

export const getUserTagQuery = gql`
  query getTagById($id: Int!) {
    getTagById(id: $id) {
      id
      name
      status
      category
      dateHistory {
        createdAt
        updatedAt
      }
    }
  }
`;

export const getTagTargetListQuery = gql`
  query getTagTargetList($tagName: String!, $skip: Int, $pageSize: Int) {
    getTagTargetList(tagName: $tagName, skip: $skip, pageSize: $pageSize) {
      list {
        id
        targetId
        type
        dateHistory {
          createdAt
          updatedAt
        }
        tag {
          name
        }
      }
      total
    }
  }
`;

// createTagV2(tagName: String!, targetIdFile: FileUpload!, action: String!): Boolean

export const createUserTagMutate = gql`
  mutation createTagV2($tagName: String!, $targetIdFile: FileUpload!, $action: String!) {
    createTagV2(tagName: $tagName, targetIdFile: $targetIdFile, action: $action)
  }
`;

export const updateUserTagMutate = gql`
  mutation updateTagV2($tagName: String!, $status: String!) {
    updateTagV2(tagName: $tagName, status: $status) {
      id
      name
    }
  }
`;

export const deleteUserTagMutate = gql`
  mutation deleteTagUserV2($tagName: String!, $userIds: [String]) {
    deleteTagUserV2(tagName: $tagName, userIds: $userIds)
  }
`;
