import { Typography } from '@mui/material';
import { boostTargetConditionListQuery } from 'api/boostTagGql';
import { ChaiListPage, ChaiListTableColumn } from 'common';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BoostTagDialog, BoostTagLogicalType } from './BoostTagDialog';

interface BoostTagListColumn extends ChaiListTableColumn {
  id: 'id' | 'title' | 'condition';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any, row: any) => string | JSX.Element;
}

const columns: BoostTagListColumn[] = [
  {
    id: 'title',
    label: '타이틀',
    format: value => (
      <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
        {value}
      </Typography>
    ),
  },
  {
    id: 'condition',
    label: '조건',
    format: (value: any, row) => <>{JSON.stringify(value)}</>,
  },
];

export interface BoostTagData {
  id: number;
  title: string;
  condition: {
    [key in BoostTagLogicalType]?: (number | { [key in BoostTagLogicalType]?: number[] })[];
  };
}

export const BoostTagListContainer: React.FC<{}> = props => {
  const { conditionId } = useParams<{ conditionId?: string }>();

  return (
    <ChaiListPage
      path="/boost/boostTag/"
      query={boostTargetConditionListQuery}
      childId={conditionId}
      columns={columns}
      dialog={BoostTagDialog}
    />
  );
};
