import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CategoryTagListContainer } from './CategoryTagListContainer';

export const CategoryTag: React.FC<{}> = props => {
  return (
    <>
      <Switch>
        <Route path="/boost/categoryTag/:categoryTagId" render={() => <CategoryTagListContainer />} />
        <Route path="/" render={() => <CategoryTagListContainer />} />
      </Switch>
    </>
  );
};
