import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { boostPaymentTagAddMutate, boostPaymentTagUpdateMutate, paymentTagQuery } from 'api/boostTagGql';
import { useAlert } from 'common/context/alert';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { BoostTagComparisonType, BoostTagLogicalType } from 'type/tag/common';
import {
  BoostCardPaymentTagTargetType,
  BoostPaymentTagForm,
  BoostSimplePaymentTagTargetType,
  IPaymentTagConditionGroupItem,
  IPaymentTagConditionSingleItem,
} from 'type/tag/paymentTag';
import { BoostPaymentTagDialogContent } from './BoostPaymentTagDialogContent';
import { makePayloadArray } from './helper';
import { store as reducers } from 'reducers';
import { addError, addSuccess } from 'state/notistackState';

export const BoostPaymentTagDialog: React.FC<{ id: string }> = ({ id }) => {
  const alert = useAlert();
  const history = useHistory();
  const { tagId } = useParams<{ tagId?: string }>();

  const [getTargetCondition, { data }] = useLazyQuery(paymentTagQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const currentTag = data?.paymentTag;

  const [addOrUpdateBoostPaymentTag, { loading }] = useMutation(
    tagId === 'new' ? boostPaymentTagAddMutate : boostPaymentTagUpdateMutate,
    {
      onCompleted: () => {
        reducers.dispatch(addSuccess(`결제 태그가 업데이트 되었습니다.`));
        handleClose();
      },
      onError: err => {
        reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
        handleClose();
      },
    }
  );

  const methods = useForm<BoostPaymentTagForm>({
    shouldUnregister: false,
  });
  const { getValues, handleSubmit, reset: formReset } = methods;

  const handleClose = () => {
    history.push('/boost/paymentTag/');
  };

  const addToFormKey = (prefix: string, keyArray: string[]) => {
    return (item: IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem, idx: number) => {
      if ('items' in item) {
        // IPaymentConditionGroupItem
        keyArray.push(`${prefix}.${idx}.operator`);
        item.items.forEach((_, subIdx) => {
          keyArray.push(`${prefix}.${idx}.items.${subIdx}.target`);
          keyArray.push(`${prefix}.${idx}.items.${subIdx}.value`);
          keyArray.push(`${prefix}.${idx}.items.${subIdx}.comparison`);
        });
      } else {
        // IPaymentTagConditionSingleItem
        keyArray.push(`${prefix}.${idx}.target`);
        keyArray.push(`${prefix}.${idx}.value`);
        keyArray.push(`${prefix}.${idx}.comparison`);
      }
    };
  };

  const beforeSubmit = () => {
    const values = getValues();
    const hasCardPaymentCondition = values.cardPaymentCondition?.some(checkConditionIsEmpty) || false;
    const hasPaymentCondition = values.paymentCondition?.some(checkConditionIsEmpty) || false;

    if (hasCardPaymentCondition && !hasPaymentCondition) {
      formReset({
        ...values,
        paymentCondition: [],
      });
    }
    if (!hasCardPaymentCondition && hasPaymentCondition) {
      formReset({
        ...values,
        cardPaymentCondition: [],
      });
    }

    const newValues = getValues();
    const formKeys: any[] = [];
    newValues.cardPaymentCondition?.map(addToFormKey('cardPaymentCondition', formKeys));
    handleSubmit(onSubmit)();
  };

  const onSubmit = () => {
    const {
      paymentCondition,
      cardPaymentCondition,
      cardPaymentConditionRootOperator,
      paymentConditionRootOperator,
      name,
    } = getValues();

    const paymentConditionTargets = paymentCondition?.map(getConditionTargets)?.flat();
    if (paymentConditionTargets.includes(BoostSimplePaymentTagTargetType.PRODUCT_ID)) {
      // productId 설정되어있으면 다른 타겟을 설정할 수 없음
      const cardPaymentConditionTargets = cardPaymentCondition.map(getConditionTargets).flat();
      if (
        cardPaymentConditionTargets.length > 0 ||
        paymentConditionTargets.find(item => item !== BoostSimplePaymentTagTargetType.PRODUCT_ID)
      ) {
        alert.open('오류', 'PID조건을 다른 조건과 조합할 수 없습니다.');
        return false;
      }
    }

    const payload = {
      cardPaymentCondition: {
        [cardPaymentConditionRootOperator]: cardPaymentCondition?.map(makePayloadArray),
      },
      paymentCondition: {
        [paymentConditionRootOperator]: paymentCondition?.map(makePayloadArray),
      },
    };

    if (!payload.cardPaymentCondition?.[cardPaymentConditionRootOperator]?.length) {
      payload.cardPaymentCondition = {};
    }
    if (!payload.paymentCondition?.[paymentConditionRootOperator]?.length) {
      payload.paymentCondition = {};
    }

    const variables = {
      name: name,
      condition: payload,
      id: tagId === 'new' ? undefined : Number(id),
    };

    addOrUpdateBoostPaymentTag({ variables });
  };

  useEffect(() => {
    if (tagId !== 'new') {
      tagId &&
        getTargetCondition({
          variables: { id: Number(tagId) },
        });
    } else {
      // 빈 폼 기본값
      formReset({
        cardPaymentConditionRootOperator: BoostTagLogicalType.OR,
        paymentConditionRootOperator: BoostTagLogicalType.OR,
        cardPaymentCondition: [
          {
            target: BoostCardPaymentTagTargetType.MERCHANT_NAME,
            comparison: BoostTagComparisonType.INCLUDE,
          },
        ],
        paymentCondition: [
          {
            target: BoostSimplePaymentTagTargetType.MERCHANT_ID,
            comparison: BoostTagComparisonType.EQUAL,
          },
        ],
      });
    }
  }, [getTargetCondition, formReset, tagId]);

  useEffect(() => {
    if (!currentTag || !currentTag.condition) {
      return;
    }
    const setNotComparison = (item: IPaymentTagConditionSingleItem) => {
      if (item.not && item.comparison === BoostTagComparisonType.EQUAL) {
        return {
          ...item,
          not: undefined,
          comparison: BoostTagComparisonType.NOTEQUAL,
        };
      }
      if (item.not && item.comparison === BoostTagComparisonType.INCLUDE) {
        return {
          ...item,
          not: undefined,
          comparison: BoostTagComparisonType.NOTINCLUDE,
        };
      }
      return item;
    };
    const { condition } = currentTag;
    const paymentConditionRootOperator = condition.paymentCondition
      ? (Object.keys(condition.paymentCondition)?.[0] as BoostTagLogicalType)
      : BoostTagLogicalType.OR;
    const paymentCondition =
      condition.paymentCondition?.[paymentConditionRootOperator]?.map((item: any) => {
        if (BoostTagLogicalType.AND in item || BoostTagLogicalType.OR in item) {
          const operator = Object.keys(item)?.[0] as BoostTagLogicalType;
          return {
            operator,
            items: item[operator].map(setNotComparison),
          };
        }
        return setNotComparison(item);
      }) || [];

    const cardPaymentConditionRootOperator = condition.paymentCondition
      ? (Object.keys(condition.cardPaymentCondition)?.[0] as BoostTagLogicalType)
      : BoostTagLogicalType.OR;
    const cardPaymentCondition =
      condition.cardPaymentCondition?.[cardPaymentConditionRootOperator]?.map((item: any) => {
        if (BoostTagLogicalType.AND in item || BoostTagLogicalType.OR in item) {
          const operator = Object.keys(item)?.[0] as BoostTagLogicalType;
          return {
            operator,
            items: item[operator].map(setNotComparison),
          };
        }
        return setNotComparison(item);
      }) || [];

    formReset({
      name: currentTag.name,
      cardPaymentCondition,
      cardPaymentConditionRootOperator: cardPaymentConditionRootOperator || BoostTagLogicalType.OR,
      paymentCondition,
      paymentConditionRootOperator: paymentConditionRootOperator || BoostTagLogicalType.OR,
    });
  }, [currentTag, formReset]);

  return (
    <>
      <DialogTitle>결제 태그 설정</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <BoostPaymentTagDialogContent />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button onClick={handleClose} color="inherit">
              취소
            </Button>
            <Button disabled={loading} onClick={beforeSubmit} color="primary">
              저장
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const checkConditionIsEmpty = (item: IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem) => {
  if ('items' in item) {
    // IPaymentConditionGroupItem
    return item.items.some(subItem => !!subItem.value);
  } else {
    // IPaymentTagConditionSingleItem
    return !!item.value;
  }
};

const getConditionTargets = (item: IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem) => {
  if ('items' in item) {
    // IPaymentConditionGroupItem
    return item.items.map(subItem => subItem.target);
  } else {
    // IPaymentTagConditionSingleItem
    return item.target;
  }
};
