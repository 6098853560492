import { useLazyQuery, useMutation } from '@apollo/client';
import { Autocomplete, Box, Button, Paper, TextField } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { businessCodeListQuery, merchantListQuery, selectGroupMerchantMgmtListMutation } from 'api/boostTagGql';
import { ChaiTextForm } from 'common';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { BoostPaymentTagForm } from 'type/tag/paymentTag';
import { hasErrorFormProps } from 'util/formFieldProps';
import styles from './boostPaymentTag.module.scss';

export const BoostPaymentTagMerchantInput: React.FC<{
  namePrefix:
    | `paymentCondition.${number}`
    | `cardPaymentCondition.${number}`
    | `paymentCondition.${number}.items.${number}`
    | `cardPaymentCondition.${number}.items.${number}`;
  openListDialog: (list: any) => void;
}> = ({ namePrefix, openListDialog }) => {
  const {
    watch: formWatch,
    control: formControl,
    formState: { errors },
  } = useFormContext<BoostPaymentTagForm>();
  const inputType: string = formWatch(`${namePrefix}.target`);
  const { register } = formControl;
  const [getGroupMerchantList, { data: groupMerchantListData }] = useMutation(selectGroupMerchantMgmtListMutation, {
    notifyOnNetworkStatusChange: true,
    context: {
      cardGraph: true,
    },
  });

  const [getBusinessCodeList, { data: businessCodeListData }] = useMutation(businessCodeListQuery, {
    context: {
      cardGraph: true,
    },
    variables: { typeCode: 'MERCHANT', groupCode: 'BUSINESS', codeName: '' },
  });

  const [getMerchantList, { data: merchantListData }] = useLazyQuery(merchantListQuery, {
    variables: { pageSize: 99999 },
  });

  const groupMerchantList: GroupMerchantList[] = groupMerchantListData?.selectGroupMerchantMgmtList || [];
  const businessCodeList: BusinessCodeList[] = businessCodeListData?.selectCommonCodeInfoList || [];
  const merchantList = merchantListData?.merchantList?.list;

  useEffect(() => {
    if (inputType === 'cardGroupMerchantId') {
      getGroupMerchantList();
    }
    if (inputType === 'businessCode') {
      getBusinessCodeList();
    }
    if (inputType === 'merchantId') {
      getMerchantList();
    }
  }, [getGroupMerchantList, getBusinessCodeList, getMerchantList, inputType]);

  if (inputType === 'cardGroupMerchantId') {
    return (
      <BoostPaymentTagMerchantInputAutocomplete
        placeholder="그룹 가맹점 검색"
        list={groupMerchantList}
        namePrefix={namePrefix}
        idKey="groupMerchantNo"
        nameKey="groupMerchantName"
        openListDialog={openListDialog}
      />
    );
  }

  if (inputType === 'businessCode') {
    return (
      <BoostPaymentTagMerchantInputAutocomplete
        placeholder="영업 코드 검색"
        list={businessCodeList}
        namePrefix={namePrefix}
        idKey="codeId"
        nameKey="codeName"
        showId={true}
        openListDialog={openListDialog}
      />
    );
  }

  if (inputType === 'merchantId') {
    return (
      <BoostPaymentTagMerchantInputAutocomplete
        placeholder="가맹점 검색"
        list={merchantList}
        namePrefix={namePrefix}
        idKey="id"
        nameKey="displayName"
        openListDialog={openListDialog}
      />
    );
  }

  return (
    <ChaiTextForm
      margin="none"
      size="small"
      variant="outlined"
      placeholder="이름 입력"
      name={`${namePrefix}.value`}
      register={register}
      errors={errors}
      required
      inputProps={{
        className: styles.backgroundWhite,
      }}
    />
  );
};

// TODO: 파일 분할
interface GroupMerchantList {
  groupMerchantNo: string;
  groupMerchantName: string;
}

interface BusinessCodeList {
  codeName: string;
  codeId: string;
}

const BoostPaymentTagMerchantInputAutocomplete: React.FC<{
  list: any[];
  namePrefix:
    | `paymentCondition.${number}`
    | `cardPaymentCondition.${number}`
    | `paymentCondition.${number}.items.${number}`
    | `cardPaymentCondition.${number}.items.${number}`;
  placeholder: string;
  idKey: string;
  nameKey: string;
  openListDialog: (list: any) => void;
  showId?: boolean;
}> = ({ list, namePrefix, placeholder, idKey, nameKey, openListDialog, showId }) => {
  const {
    setValue,
    clearErrors,
    formState: { errors },
    watch,
    register,
  } = useFormContext<BoostPaymentTagForm>();
  const inputValue = watch(`${namePrefix}.value`);
  const [inputStringValue, setInputStringValue] = React.useState<string>();
  const value = list?.find(item => item[idKey] === inputValue) || null;
  const errorProps = hasErrorFormProps({ error: errors, name: `${namePrefix}.value` });
  register(`${namePrefix}.value`, { required: true });

  return (
    <Autocomplete
      options={list || []}
      autoComplete
      autoHighlight
      isOptionEqualToValue={(option, value) => option[idKey] === value[idKey]}
      getOptionLabel={option => {
        return (
          (showId ? `${option[nameKey]} (${option[idKey]})` : `${option[nameKey]}`) || `(이름 없음) (${option[idKey]})`
        );
      }}
      filterOptions={options => {
        if (!inputStringValue?.length) {
          return [];
        }
        return options.filter(
          option =>
            `${option[nameKey]}(${option[idKey]})`.replace(/\s/g, '').indexOf(inputStringValue.replace(/\s/g, '')) > -1
        );
      }}
      onChange={(e, value) => {
        clearErrors(`${namePrefix}.value`);
        setValue(`${namePrefix}.value`, value?.[idKey]);
      }}
      onInputChange={(e, value) => {
        setInputStringValue(value);
      }}
      value={value}
      PaperComponent={({ children }) => (
        <Paper>
          {children}
          <Box
            style={{ padding: 4 }}
            textAlign="center"
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            <Button
              fullWidth
              onClick={() =>
                openListDialog(
                  list.map(item => {
                    return {
                      id: item[idKey],
                      name: item[nameKey],
                    };
                  })
                )
              }
              startIcon={<ListIcon />}
            >
              전체 목록 보기
            </Button>
          </Box>
        </Paper>
      )}
      renderOption={(props, option) => (
        <li {...props} key={option[idKey]}>
          {showId ? `${option[nameKey]} (${option[idKey]})` : `${option[nameKey]}`}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          name={`${namePrefix}.value`}
          fullWidth
          placeholder={placeholder}
          size="small"
          variant="outlined"
          {...errorProps}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: styles.backgroundWhite,
            },
          }}
        />
      )}
    />
  );
};
