import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { Control, Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { hasErrorFormProps } from 'util/formFieldProps';

interface IChaiSelectForm {
  context?: boolean;
  control?: Control<any>;
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  errors?: DeepMap<any, FieldError>;
  variant?: 'standard' | 'filled' | 'outlined';
}

export const ChaiSelectForm: React.FC<IChaiSelectForm> = methods => {
  if (methods?.context) {
    return <ChaiSelectFormWithContext {...methods} />;
  } else {
    return <ChaiSelectFormRender {...methods} />;
  }
};

const ChaiSelectFormWithContext: React.FC<IChaiSelectForm> = methods => {
  const {
    control,
    formState: { errors },
  } = useFormContext<any>();
  if (!control || !errors) {
    throw new Error(`Form context load error: ${methods.name}`);
  }

  return <ChaiSelectFormRender {...methods} {...{ control, errors }} />;
};

const ChaiSelectFormRender: React.FC<IChaiSelectForm> = ({
  name,
  label,
  control,
  disabled,
  children,
  defaultValue,
  required,
  errors,
  variant = 'standard',
}) => {
  const errorProp = errors && hasErrorFormProps({ name, error: errors });
  return (
    <FormControl margin="dense" fullWidth disabled={disabled}>
      <InputLabel variant={variant} shrink>
        {label}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <>
            <Select defaultValue={defaultValue} variant={variant} {...field} error={errorProp?.error}>
              {children}
            </Select>
            {errorProp?.error && (
              <FormHelperText error sx={{ margin: 0 }}>
                {errorProp.helperText}
              </FormHelperText>
            )}
          </>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
      />
    </FormControl>
  );
};
