import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import {
  addBoostImportantMutation,
  getBoostImportantQuery,
  IBoostImportant,
  updateBoostImportantMutation,
} from 'api/boostImportantGql';
import { ChaiTextContextForm } from 'common';
import ChaiDivider from 'common/component/ChaiDivider';
import { store as reducers } from 'reducers';
import { addSuccess, addError } from 'state/notistackState';

export const BoostOptionalImportantDialog: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory();
  const location = useLocation();

  const form = useForm<IBoostImportant>();
  const { reset, watch, handleSubmit, setValue } = form;

  const { data } = useQuery(getBoostImportantQuery, {
    variables: {
      sortKey: id + decodeURI(location.hash),
      table: 'importantOptional',
    },
  });

  const [addOrUpdateOptionalImportant, { loading }] = useMutation(
    id === 'new' ? addBoostImportantMutation : updateBoostImportantMutation,
    {
      onCompleted: () => {
        reducers.dispatch(addSuccess(`선택 유의사항이 업데이트 되었습니다.`));
        handleClose();
      },
      onError: err => {
        reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
        handleClose();
      },
    }
  );

  useEffect(() => {
    if (!id || id === 'new') {
      return;
    }

    if (data) {
      reset(data.boostImportant);
    }
  }, [id, reset, data]);

  const handleClose = () => {
    history.push('/important/optional/');
  };

  const onSubmit = (values: IBoostImportant) => {
    if (!validateContent(values)) {
      return false;
    }

    const { type, title, priority } = values;
    const sortKey = priority + '#' + type + '-' + title;

    addOrUpdateOptionalImportant({
      variables: { ...values, prevSortKey: values.sortKey, sortKey, table: 'importantOptional' },
    });
  };

  const validateContent = ({ content }: IBoostImportant) => {
    if (content === '') {
      reducers.dispatch(addError('유의사항을 입력해주세요.'));
      return false;
    }

    return true;
  };

  const onEditorContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const content = e.target.value;
    setValue('content', content);
  };

  const editorContent = watch('content');

  return (
    <FormProvider {...form}>
      <DialogTitle>선택 유의사항</DialogTitle>
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <ChaiTextContextForm label="구분자" name="type" />
          </Grid>
          <Grid item xs={4}>
            <ChaiTextContextForm label="유의사항 모듈 타이틀" name="title" required />
          </Grid>
          <Grid item xs={4}>
            <ChaiTextContextForm label="개별 유의사항 우선순위" name="priority" type="number" required />
          </Grid>
        </Grid>
        <Box m={2} />
        <Typography variant="subtitle2" color="textSecondary">
          유의사항
        </Typography>
        <ChaiDivider />
        <Box style={{ height: '300px' }}>
          <TextField fullWidth multiline minRows="12" value={editorContent || ''} onChange={onEditorContentChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button onClick={() => handleClose()} color="inherit">
            취소
          </Button>
          <Button disabled={loading} onClick={handleSubmit(onSubmit)}>
            저장
          </Button>
        </Grid>
      </DialogActions>
    </FormProvider>
  );
};
