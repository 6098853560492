import { Box, Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ChaiDropdownMenu } from 'common';
import React from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { BoostTagComparisonType } from 'type/tag/common';
import {
  BoostCardPaymentTagTargetType,
  BoostPaymentTagForm,
  BoostSimplePaymentTagTargetType,
  IPaymentTagConditionGroupItem,
  IPaymentTagConditionSingleItem,
} from 'type/tag/paymentTag';
import styles from './boostPaymentTag.module.scss';
import { BoostPaymentTagDialogSingleItem } from './BoostPaymentTagDialogSingleItem';

export const BoostPaymentTagDialogGroupItem: React.FC<{
  type: string;
  nameBase: `paymentCondition.${number}` | `cardPaymentCondition.${number}`;
  idx: number;
  operator: string;
  subConditions: IPaymentTagConditionSingleItem[];
  openListDialog: (list: any[]) => void;
}> = ({ type, nameBase, idx, operator, subConditions, openListDialog }) => {
  const { watch: formWatch, control: formControl, getValues, setValue, reset } = useFormContext<BoostPaymentTagForm>();
  const groupOperator: string = formWatch(`${nameBase}.operator`);
  const conditions: IPaymentTagConditionSingleItem[] = formWatch(`${nameBase}.items`) || [];

  const addSingleCondition = () => {
    const conditions: IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem = getValues(nameBase) || [];

    // 그룹 아이템인지 한번 더 체크
    if ('items' in conditions) {
      type === 'paymentCondition' &&
        setValue(`${nameBase}.items.${conditions.items.length}`, {
          target: BoostSimplePaymentTagTargetType.MERCHANT_ID,
          comparison: BoostTagComparisonType.EQUAL,
          value: '',
        });
      type === 'cardPaymentCondition' &&
        setValue(`${nameBase}.items.${conditions.items.length}`, {
          target: BoostCardPaymentTagTargetType.GROUP_MERCHANT,
          comparison: BoostTagComparisonType.INCLUDE,
          value: '',
        });
    }
  };

  const handleDelete = () => {
    const values = getValues();
    const arrayRootKey = type;
    const currentTypeArray = get(values, arrayRootKey).filter((item: any, itemIdx: number) => idx !== itemIdx);
    reset({
      ...values,
      [arrayRootKey]: currentTypeArray,
    });
  };

  // 그룹 조건 -> 단일 조건 변경
  const handleSwap = () => {
    const values = getValues();
    const currentItem = get(values, `${nameBase}.items.0`);
    const currentTypeArray = get(values, type);
    currentTypeArray[idx] = { ...currentItem };
    reset({
      ...values,
      [type]: currentTypeArray,
    });
  };

  return (
    <Grid item xs={12}>
      <Grid container className={styles.noPadding} direction="row">
        <Grid item style={{ width: 100 }}>
          {idx === 1 ? (
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  variant="outlined"
                  style={{ height: 40, width: 75, fontSize: 14, fontWeight: 'bold' }}
                >
                  <MenuItem value={'and'}>And</MenuItem>
                  <MenuItem value={'or'}>Or</MenuItem>
                </Select>
              )}
              // defaultValue={operator}
              name={
                type === 'cardPaymentCondition' ? 'cardPaymentConditionRootOperator' : 'paymentConditionRootOperator'
              }
              control={formControl}
            />
          ) : (
            <Typography className={styles.operatorString}>{idx === 0 ? 'Where' : operator}</Typography>
          )}
        </Grid>
        <Grid item xs={9}>
          <Box style={{ backgroundColor: 'white', padding: 20 }}>
            <Grid container justifyContent="space-between">
              {conditions.map((condition, subIdx) => {
                return (
                  <Grid item xs={12} key={`target-merchant-tag-${type}-item-${nameBase}-${subIdx}`}>
                    <BoostPaymentTagDialogSingleItem
                      nameBase={nameBase}
                      isSub
                      type={type}
                      operator={groupOperator}
                      idx={subIdx}
                      openListDialog={openListDialog}
                    />
                    <Box m={2} />
                  </Grid>
                );
              })}
            </Grid>
            <Box textAlign="left">
              <Button
                color="primary"
                aria-controls="condition-add"
                aria-haspopup="true"
                onClick={addSingleCondition}
                startIcon={<AddIcon />}
              >
                조건 추가
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item style={{ lineHeight: '40px', marginLeft: 4, width: '40px' }}>
          <ChaiDropdownMenu
            name="form-delete"
            buttonColor="inherit"
            menuList={[
              {
                icon: 'highlight_off',
                title: '삭제',
                action: () => handleDelete(),
              },
              {
                icon: 'swap_horiz',
                title: '조건유형 변경',
                action: () => handleSwap(),
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
