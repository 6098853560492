import {DataGrid, GridCellEditCommitParams, GridColDef} from "@mui/x-data-grid";
import {useMutation, useQuery} from "@apollo/client";
import {ISettlementUpdateArgs, settlementPreviewQuery, settlementUpdateMutation} from "../../api/settlementGql";
import {
  Box,
  Button,
  ButtonGroup, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {IAdjustmentData, IVerificationData} from "./Settlement";
import {ChaiTextForm} from "../../common";
import {useForm} from "react-hook-form";

interface ISettlementListProps {
  verificationDataList: IVerificationData[];
  adjustmentDataList: IAdjustmentData[];
  onChangeAdjustmentData(merchantId: string, field: string, value: number | string): never;
}

interface ISettlement {
  merchantId: string;
  name: string;
  displayName: string;
  amount: number;
  feeAmount: number;
  balance: number;
  bankCode: string;
  bankAccount: string;
  bankHolder: string;
}

interface ISettlementListRow extends ISettlement {
  id: number;
  firstAmount: number;
  adjustment: number;
  manualTransfer: number;
  lastAmount: number;
  status: string;
  memo: string;
}

interface ISettlementPreviewQuery {
  settlementPreview: ISettlement[];
}

function DialogOTP(props: {
  isOpen: boolean,
  onSubmit: (data: { otp: string }) => void,
  handleClose: () => void,
}) {
  const { isOpen, onSubmit, handleClose } = props;
  const { register, handleSubmit } = useForm<{ otp: string }>();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpen}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>송금확정</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={20}>
              <ChaiTextForm register={register} required name="otp" label="otp"/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            취소
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            완료
          </Button>
        </DialogActions>

      </form>
    </Dialog>
  )
}

function DialogDontTransfer(props: {
  isOpen: boolean,
  onSubmit: (data: { exception: string }) => void,
  handleClose: () => void,
}) {
  const { isOpen, onSubmit, handleClose } = props;
  const { register, handleSubmit } = useForm<{ exception: string }>();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpen}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>송금없이 완료 처리</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={20}>
              <ChaiTextForm register={register} required name="exception" label="미송금 사유"/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            취소
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            완료
          </Button>
        </DialogActions>

      </form>
    </Dialog>
  )
}

function CustomFooter(props: {
  count: number,
  rows: ISettlementListRow[],
  selectionModel: number[],
  removeRows: (keys: number[]) => void,
  onSubmitSettlement: (args: ISettlementUpdateArgs) => Promise<void>
}) {
  const [ isOpenDialogDontTransfer, setIsOpenDialogDontTransfer ] = useState<boolean>(false);
  const [ isOpenDialogOTP, setIsOpenDialogOTP ] = useState<boolean>(false);

  const openDialogDontTransfer = () => setIsOpenDialogDontTransfer(true);
  const closeDialogDontTransfer = () => setIsOpenDialogDontTransfer(false);

  const openDialogOTP = () => setIsOpenDialogOTP(true);
  const closeDialogOTP = () => setIsOpenDialogOTP(false);

  function onSubmitSettlement(data: { otp: string }) {
    const selectedRows = props.rows.filter((row, i) => props.selectionModel.includes(i));
    const merchantIds = selectedRows.map<string>((row) => row.merchantId);

    props.onSubmitSettlement({
      merchantIds,
      otp: data.otp,
    }).then(() => {
      props.removeRows(props.selectionModel);
      closeDialogOTP();
    });
  }

  function onClickSettlement() {
    //송금확정 버튼 클릭
    const selectedRows = props.rows.filter((row, i) => props.selectionModel.includes(i));
    const merchantIds = selectedRows.map<string>((row) => row.merchantId);
    const isCanSettlement = selectedRows.every(row => row.status === '정산 가능');

    if (merchantIds.length < 1) {
      alert('선택된 건이 없습니다.');
      return false;
    }

    if (isCanSettlement && merchantIds.length !== 0) {
      openDialogOTP();
    } else {
      alert('정산 불가능한 건이 있습니다.');
    }
  }

  function onClickDontTransfer() {
    //송금없이 완료 처리
    const selectedRows = props.rows.filter((row, i) => props.selectionModel.includes(i));
    const selectedMerchantIds = selectedRows.map<string>(row => row.merchantId);

    if (selectedMerchantIds?.length < 1) {
      alert('송금없이 완료 처리할 건을 선택해주세요');
      return;
    }

    openDialogDontTransfer();
  }

  function onSubmitDontTransfer(data: { exception: string }) {
    const selectedRows = props.rows.filter((row, i) => props.selectionModel.includes(i));

    if (selectedRows) {
      props.onSubmitSettlement({
          merchantIds: selectedRows.map<string>((row) => row.merchantId),
          transferException: data.exception,
      }).then(() => {
        props.removeRows(props.selectionModel);
      });
    }

    closeDialogDontTransfer();
  }

  return (
    <Box sx={{padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
        {props.count} 개 선택됨
      </div>
      <div>
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button onClick={onClickSettlement}>송금확정</Button>
          <Button onClick={onClickDontTransfer}>송금없이 완료 처리</Button>
        </ButtonGroup>
      </div>
      <DialogOTP
        isOpen={isOpenDialogOTP}
        onSubmit={onSubmitSettlement}
        handleClose={closeDialogOTP}
      />
      <DialogDontTransfer
        isOpen={isOpenDialogDontTransfer}
        handleClose={closeDialogDontTransfer}
        onSubmit={onSubmitDontTransfer}
      />
    </Box>
  );
}

export function SettlementList(props: ISettlementListProps) {
  const {data} = useQuery<ISettlementPreviewQuery>(settlementPreviewQuery);
  const [settlementUpdate] = useMutation<any, ISettlementUpdateArgs>(settlementUpdateMutation);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [rows, setRows] = useState<ISettlementListRow[]>([]);

  const settlementList: ISettlement[] | null = data?.settlementPreview ?? null;

  const columns: GridColDef[] = [
    {field: 'merchantId', headerName: 'MID'},
    {field: 'name', headerName: '가맹점 명', sortable: true},
    {field: 'firstAmount', headerName: '최초 정산 금액', sortable: true},
    {field: 'adjustment', headerName: '보정 금액', editable: true, type: 'number'},
    {field: 'manualTransfer', headerName: '수기 이체 금액'},
    {field: 'lastAmount', headerName: '최종 정산 금액', type: 'number', width: 250},
    {field: 'status', headerName: '검증 상태'},
    {field: 'memo', headerName: 'memo', editable: true},
  ];

  async function handleSettlement(args: ISettlementUpdateArgs) {
    if (args.merchantIds.length < 1) throw new Error('settlement args must have merchantIds more than 1');

    if (!args.transferException) {
      // 송금 확정
      args.adjustmentList = props.adjustmentDataList.map(data => {
        delete data.manualTransfer;
        return data;
      });
    }

    const result = await settlementUpdate({
      variables: args
    });

    if (result?.data?.settlementUpdate !== true) {
      throw new Error('failed to update settlement');
    }
  }

  function removeRows(keys: number[]) {
    const newRows = rows.filter((row, i) => !keys.includes(i));
    setRows(newRows);
  }

  function onCellEditCommit(commitData: GridCellEditCommitParams) {
    const { id, field, value } = commitData;
    let row: ISettlementListRow | null = null;

    if (typeof id === "number") {
      row = rows.at(id) ?? null;
    }

    if (row && (typeof value == 'string' || typeof value == 'number')) {
      props.onChangeAdjustmentData(row.merchantId, field, value);
    }
  }

  useEffect(() => {
    setRows(settlementList?.map<ISettlementListRow>((info, i) => ({
      ...info,
      id: i,
      firstAmount: info.amount - info.feeAmount,
      adjustment: 0,
      manualTransfer: 0,
      lastAmount: info.amount - info.feeAmount,
      status: '',
      memo: '',
    })) ?? []);
  }, [settlementList]);

  useEffect(() => {
    let newRows = [...rows];

    newRows = newRows.map((row) => {
      const verificationData = props.verificationDataList.find((info) => info.merchantId === row.merchantId);
      const adjustmentData = props.adjustmentDataList.find((info) => info.merchantId === row.merchantId);

      row.status = '미검증';
      row.adjustment = adjustmentData?.amount ?? 0;
      row.manualTransfer = adjustmentData?.manualTransfer ?? 0;
      row.lastAmount = row.firstAmount + row.adjustment + row.manualTransfer;
      row.memo = adjustmentData?.memo ?? '';

      if (verificationData) {
        if (verificationData.amount === row.lastAmount) {
          row.status = '정산 가능';
        } else {
          row.status = '정산 불가능';
        }
      }

      return row;
    });

    setRows(newRows);
  }, [props.verificationDataList, props.adjustmentDataList]);

  if (!rows) {
    return null;
  }

  return (
    <div style={{height: 500}}>
      <DataGrid
        sx={{
          '& .red-row': {
            color: '#f05f53',
          },
        }}
        columns={columns}
        rows={rows}
        checkboxSelection={true}
        hideFooterPagination={true}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel.filter((val): val is number => typeof val === 'number'))}
        onCellEditCommit={onCellEditCommit}
        components={{
          Footer: CustomFooter
        }}
        componentsProps={{
          footer: {
            count: selectionModel.length,
            rows: rows,
            selectionModel: selectionModel,
            removeRows: removeRows,
            onSubmitSettlement: handleSettlement
          }
        }}
        getRowClassName={(params) => {
          if (params.row.status === '정산 불가능') {
            return 'red-row';
          }
          return '';
        }}
      />
    </div>
  );
}