import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAuthAsync, ILogin, IRequestOTP, loginAsync, LoginAuthData, requestOTPAsync } from 'api/loginApi';

interface LoginState {
  otp: {
    code: string;
    loading: boolean;
    error?: string;
  };
  login: {
    loading: boolean;
    error?: string;
  };
  getAuth: {
    loading: boolean;
    error?: string;
  };
}

const initialState = {
  otp: {
    code: '',
    loading: false,
  },
  login: {
    loading: false,
  },
} as LoginState;

export const requestOTP = createAsyncThunk('login/requestOTP', async ({ id, password }: IRequestOTP) => {
  return (await requestOTPAsync({ id, password })) as string;
});

export const login = createAsyncThunk('login/doLogin', async ({ id, password, code }: ILogin) => {
  return (await loginAsync({ id, password, code })) as string;
});

export const getAuth = createAsyncThunk<LoginAuthData, void>('login/getAuth', async () => {
  return (await getAuthAsync()) as LoginAuthData;
});

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(requestOTP.pending, state => {
      state.otp = {
        loading: true,
        code: state.otp.code,
      };
    });
    builder.addCase(requestOTP.fulfilled, (state, action: PayloadAction<string>) => {
      state.otp = {
        loading: false,
        code: action.payload,
      };
    });
    builder.addCase(requestOTP.rejected, (state, action: any) => {
      state.otp = {
        loading: false,
        code: initialState.otp.code,
        error: action.error?.message,
      };
    });
    builder.addCase(login.pending, state => {
      state.login = {
        loading: true,
      };
    });
    builder.addCase(login.fulfilled, (state, action: PayloadAction<string>) => {
      state.login = {
        loading: false,
      };
    });
    builder.addCase(login.rejected, (state, action) => {
      state.login = {
        loading: false,
        error: action.error?.message || 'unknown error',
      };
    });
    builder.addCase(getAuth.pending, state => {
      state.getAuth = {
        loading: true,
      };
    });
    builder.addCase(getAuth.fulfilled, (state, action: PayloadAction<LoginAuthData>) => {
      state.getAuth = {
        loading: false,
      };
    });
    builder.addCase(getAuth.rejected, (state, action) => {
      state.getAuth = {
        loading: false,
        error: action.error?.message || 'unknown error',
      };
    });
  },
});

export default loginSlice.reducer;
