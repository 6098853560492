import gql from 'graphql-tag';

export const getBoostUpQuery = gql`
  query boostUp($id: Int!) {
    boostUp(id: $id) {
      id
      title
      budget
      dailyBudget
      description
      startAt
      endAt
      status
      boltPrice
      possibleMoments
      visibleFrom
      visibleTo
      targetTypes
      boostUpSchemePolicies {
        probability
        cashbackAmountMin
        cashbackAmountMax
        cashbackAmountUnit
        discountRate
        resultType
        level
      }
    }
  }
`;

export const getBoostUpDefaultQuery = gql`
  query boostUpDefault($targetTypes: [String]) {
    boostUpDefault(targetTypes: $targetTypes) {
      id
    }
  }
`;

export const getBoostUpListQuery = gql`
  query boostUpList($skip: Int, $pageSize: Int, $filter: String, $id: Int) {
    boostUpList(skip: $skip, pageSize: $pageSize, filter: $filter, id: $id) {
      list {
        id
        title
        budget
        description
        startAt
        endAt
        status
        boltPrice
        visibleFrom
        visibleTo
      }
      total
    }
  }
`;

export const boostUpAddMutation = gql`
  mutation boostUpAdd(
    $title: String!
    $budget: Int!
    $dailyBudget: Int!
    $description: String
    $startAt: String!
    $endAt: String!
    $status: String!
    $boltPrice: Int!
    $boostUpSchemePolicies: [BoostUpSchemeInput]
    $possibleMoments: [String]
    $visibleFrom: Date
    $visibleTo: Date
    $targetTypes: [String]
    $batchApply: Boolean
  ) {
    boostUpAdd(
      title: $title
      budget: $budget
      dailyBudget: $dailyBudget
      description: $description
      startAt: $startAt
      endAt: $endAt
      status: $status
      boltPrice: $boltPrice
      boostUpSchemePolicies: $boostUpSchemePolicies
      possibleMoments: $possibleMoments
      visibleFrom: $visibleFrom
      visibleTo: $visibleTo
      targetTypes: $targetTypes
      batchApply: $batchApply
    )
  }
`;

export const boostUpUpdateMutation = gql`
  mutation boostUpUpdate(
    $id: Int!
    $title: String!
    $budget: Int!
    $dailyBudget: Int!
    $description: String
    $startAt: String!
    $endAt: String!
    $status: String!
    $boltPrice: Int!
    $boostUpSchemePolicies: [BoostUpSchemeInput]
    $possibleMoments: [String]
    $visibleFrom: Date
    $visibleTo: Date
    $targetTypes: [String]
    $batchApply: Boolean
  ) {
    boostUpUpdate(
      id: $id
      title: $title
      budget: $budget
      dailyBudget: $dailyBudget
      description: $description
      startAt: $startAt
      endAt: $endAt
      status: $status
      boltPrice: $boltPrice
      boostUpSchemePolicies: $boostUpSchemePolicies
      possibleMoments: $possibleMoments
      visibleFrom: $visibleFrom
      visibleTo: $visibleTo
      targetTypes: $targetTypes
      batchApply: $batchApply
    )
  }
`;

export const getBoostUpImportantQuery = gql`
  query boostUpImportant {
    boostUpImportant
  }
`;

export const boostUpImportantUpdateMutation = gql`
  mutation boostUpImportantUpdate($value: String!) {
    boostUpImportantUpdate(value: $value)
  }
`;
