import { useDispatch } from 'react-redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import thunk from "redux-thunk";
import { createLogger } from 'redux-logger';

import login from './state/loginState';
import notistack from './state/notistackState';

const reducer = combineReducers({ login, notistack });
const logger = createLogger();

export type RootState = ReturnType<typeof reducer>;
export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(logger),
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
