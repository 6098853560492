import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { ChaiDropdownMenu } from 'common';
import React, { useEffect, useRef } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { BoostTagComparisonType, BoostTagLogicalType } from 'type/tag/common';
import { BoostPaymentTagForm } from 'type/tag/paymentTag';
import styles from './boostPaymentTag.module.scss';
import { BoostPaymentTagMerchantInput } from './BoostPaymentTagMerchantInput';

const hasIncludeComparison = (inputTarget: string) => {
  if (['merchantId', 'businessCode', 'productId'].includes(inputTarget)) {
    return false;
  }
  return true;
};

const hasEqualComparison = (inputTarget: string) => {
  if (['cardGroupMerchantId'].includes(inputTarget)) {
    return false;
  }
  return true;
};

const hasNotEqualComparison = (inputTarget: string) => {
  if (['cardGroupMerchantId', 'productId'].includes(inputTarget)) {
    return false;
  }
  return true;
};

export const BoostPaymentTagDialogSingleItem: React.FC<{
  nameBase: `paymentCondition.${number}` | `cardPaymentCondition.${number}`;
  type: string;
  isSub?: boolean;
  idx: number;
  operator: string;
  openListDialog: (list: any) => void;
}> = ({ nameBase, type, isSub, idx, operator, openListDialog }) => {
  const { watch: formWatch, control: formControl, reset, setValue, getValues } = useFormContext<BoostPaymentTagForm>();
  const firstRef = useRef(false);
  const namePrefix:
    | `paymentCondition.${number}`
    | `cardPaymentCondition.${number}`
    | `paymentCondition.${number}.items.${number}`
    | `cardPaymentCondition.${number}.items.${number}` = isSub ? `${nameBase}.items.${idx}` : nameBase;
  const inputTarget = formWatch(`${namePrefix}.target`);

  // target값이 변경될 경우 value 값을 초기화한다
  useEffect(() => {
    if (firstRef.current) {
      setValue(`${namePrefix}.value`, '');
      if (hasIncludeComparison(inputTarget)) {
        setValue(`${namePrefix}.comparison`, BoostTagComparisonType.INCLUDE);
      } else {
        setValue(`${namePrefix}.comparison`, BoostTagComparisonType.EQUAL);
      }
    } else {
      firstRef.current = true;
    }
  }, [inputTarget, namePrefix, setValue]);

  const handleDelete = () => {
    const values = getValues();
    if (isSub) {
      const arrayRootKey = `${nameBase}.items`;
      const rootIdx = Number(nameBase.replace(/.*\./, ''));
      const currentTypeArray = get(values, type);
      const currentSubArray = get(values, arrayRootKey).filter((item: any, itemIdx: number) => idx !== itemIdx);
      currentTypeArray[rootIdx] = {
        ...currentTypeArray[rootIdx],
        items: currentSubArray,
      };
      reset({
        ...values,
        [type]: currentTypeArray,
      });
    } else {
      const arrayRootKey = type;
      const currentTypeArray = get(values, arrayRootKey).filter((item: any, itemIdx: number) => idx !== itemIdx);
      reset({
        ...values,
        [arrayRootKey]: currentTypeArray,
      });
    }
  };

  // Single Item -> Group Item
  const handleSwap = () => {
    const values = getValues();
    const currentItem = get(values, namePrefix);
    const currentTypeArray = get(values, type);
    currentTypeArray[idx] = { operator: BoostTagLogicalType.AND, items: [currentItem] };
    reset({
      ...values,
      [type]: currentTypeArray,
    });
  };

  return (
    <Grid container>
      <Grid item style={{ width: isSub ? 84 : 100 }}>
        {idx === 1 && (
          <Controller
            render={({ field }) => (
              <Select {...field} variant="outlined" style={{ height: 40, width: 75, fontSize: 14, fontWeight: 'bold' }}>
                <MenuItem value={'and'}>And</MenuItem>
                <MenuItem value={'or'}>Or</MenuItem>
              </Select>
            )}
            // defaultValue={isSub ? operator || 'and' : 'and'}
            name={
              isSub
                ? `${nameBase}.operator`
                : type === 'cardPaymentCondition'
                ? 'cardPaymentConditionRootOperator'
                : 'paymentConditionRootOperator'
            }
            control={formControl}
          />
        )}
        {idx !== 1 && <Typography className={styles.operatorString}>{idx === 0 ? 'Where' : operator}</Typography>}
      </Grid>
      <Grid item xs={9}>
        <Grid container justifyContent="space-between">
          <Grid item xs={5}>
            <Controller
              render={({ field }) =>
                type === 'cardPaymentCondition' ? (
                  <Select
                    {...field}
                    variant="outlined"
                    style={{ height: 40, width: '100%', fontSize: 14, fontWeight: 'bold', backgroundColor: 'white' }}
                  >
                    <MenuItem value={'cardMerchantName'}>가맹점명</MenuItem>
                    <MenuItem value={'cardGroupMerchantId'}>그룹 가맹점</MenuItem>
                    <MenuItem value={'businessCode'}>영업코드</MenuItem>
                  </Select>
                ) : (
                  <Select
                    {...field}
                    variant="outlined"
                    style={{ height: 40, width: '100%', fontSize: 14, fontWeight: 'bold', backgroundColor: 'white' }}
                  >
                    <MenuItem value={'merchantId'}>가맹점명</MenuItem>
                    <MenuItem value={'sub_merchant_name'}>하위 가맹점명</MenuItem>
                    <MenuItem value={'productId'}>상품 ID</MenuItem>
                  </Select>
                )
              }
              name={`${namePrefix}.target`}
              control={formControl}
            />
          </Grid>
          <Grid item>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  variant="outlined"
                  style={{ height: 40, width: '100%', fontSize: 14, fontWeight: 'bold', backgroundColor: 'white' }}
                >
                  {hasEqualComparison(inputTarget) && <MenuItem value={'equal'}>=</MenuItem>}
                  {hasNotEqualComparison(inputTarget) && <MenuItem value={'notEqual'}>≠</MenuItem>}
                  {hasIncludeComparison(inputTarget) && <MenuItem value={'include'}>포함</MenuItem>}
                  {hasIncludeComparison(inputTarget) && <MenuItem value={'notInclude'}>⊄</MenuItem>}
                </Select>
              )}
              // defaultValue={'equal'}
              name={`${namePrefix}.comparison`}
              control={formControl}
            />
          </Grid>
          <Grid item xs={5}>
            <BoostPaymentTagMerchantInput namePrefix={namePrefix} openListDialog={openListDialog} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ lineHeight: '40px', marginLeft: 4, width: '40px' }}>
        <ChaiDropdownMenu
          name="form-delete"
          buttonColor="inherit"
          menuList={[
            {
              icon: 'highlight_off',
              title: '삭제',
              action: () => handleDelete(),
            },
            ...(isSub
              ? []
              : [
                  {
                    icon: 'swap_horiz',
                    title: '조건유형 변경',
                    action: () => handleSwap(),
                  },
                ]),
          ]}
        />
      </Grid>
    </Grid>
  );
};
