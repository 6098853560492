import gql from 'graphql-tag';

export const boostCampaignListQuery = gql`
  query boostCampaignList($skip: Int, $pageSize: Int, $filter: String) {
    boostCampaignList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        title
        description
        createdAt
        updatedAt
        start
        end
        usableFrom
        usableTo
        buyableFrom
        buyableTo
        boostBudgetId
        boostPromotionId
        status
        statusLabel
        targetUserTags
        totalCountCapPerUser
        dailyCountCapPerUser
        amountCap
        dailyAmountCap
        reasonLabels
        targetTypes
        boostPromotion {
          type
          boltPrice
          title
          subTitle
          countCap
          brand {
            id
            name
            logoImageUrl
            typoImageUrl
            color
          }
          promotion {
            discount
            maxDiscountAmount
          }
        }
        boostBudget {
          budgetTotal
          remainingTotal
        }
        usedCampaignBudget
        reservedCampaignBudget
        todayUsedCampaignBudget
        todayReservedCampaignBudget
      }
      total
    }
  }
`;

export const boostCampaignQuery = gql`
  query boostCampaign($id: Int!) {
    boostCampaign(id: $id) {
      id
      title
      description
      createdAt
      updatedAt
      start
      end
      usableFrom
      usableTo
      buyableFrom
      buyableTo
      boostBudgetId
      boostPromotionId
      status
      statusLabel
      targetUserTags
      totalCountCapPerUser
      dailyCountCapPerUser
      amountCap
      dailyAmountCap
      targetTypes
      animationType
      attributionUrl
      adSpendCalculationType
      adSpendUnitPrice
      adSpendRatio
      adSpendWeight
      boostPromotion {
        brand {
          id
          name
          logoImageUrl
          typoImageUrl
          color
          landingUrl
          fallbackUrl
          iosAppId
          androidPackage
        }
      }
      # boostBudget
      brandId
      boostCampaignContents {
        brandContentId
        visibleFrom
        visibleTo
      }
      boostUpPolicy
      boostUpPolicyName
    }
  }
`;

export const boostCampaignAddMutation = gql`
  mutation boostCampaignAdd(
    $brandId: Int!
    $boostBudgetId: Int!
    $boostPromotionId: Int!
    $start: Date!
    $end: Date!
    $title: String!
    $description: String!
    $status: String
    $usableFrom: String
    $usableTo: String
    $buyableFrom: String
    $buyableTo: String
    $targetUserTags: [String]
    $totalCountCapPerUser: Int
    $dailyCountCapPerUser: Int
    $amountCap: BigInt
    $dailyAmountCap: BigInt
    $countCap: Int
    $boostCampaignContents: [BoostCampaignContentInput]
    $targetTypes: [String]
    $animationType: String
    $attributionUrl: String
    $boostUpPolicy: Int
    $adSpendCalculationType: String
    $adSpendUnitPrice: BigInt
    $adSpendRatio: Float
    $adSpendWeight: Float
  ) {
    boostCampaignAdd(
      brandId: $brandId
      boostBudgetId: $boostBudgetId
      boostPromotionId: $boostPromotionId
      start: $start
      end: $end
      title: $title
      description: $description
      status: $status
      usableFrom: $usableFrom
      usableTo: $usableTo
      buyableFrom: $buyableFrom
      buyableTo: $buyableTo
      targetUserTags: $targetUserTags
      totalCountCapPerUser: $totalCountCapPerUser
      dailyCountCapPerUser: $dailyCountCapPerUser
      amountCap: $amountCap
      dailyAmountCap: $dailyAmountCap
      countCap: $countCap
      boostCampaignContents: $boostCampaignContents
      targetTypes: $targetTypes
      animationType: $animationType
      attributionUrl: $attributionUrl
      boostUpPolicy: $boostUpPolicy
      adSpendCalculationType: $adSpendCalculationType
      adSpendUnitPrice: $adSpendUnitPrice
      adSpendRatio: $adSpendRatio
      adSpendWeight: $adSpendWeight
    )
  }
`;

export const boostCampaignUpdateMutation = gql`
  mutation boostCampaignUpdate(
    $id: Int!
    $brandId: Int
    $boostBudgetId: Int
    $boostPromotionId: Int
    $start: Date
    $end: Date
    $title: String
    $description: String
    $status: String
    $usableFrom: String
    $usableTo: String
    $buyableFrom: String
    $buyableTo: String
    $targetUserTags: [String]
    $totalCountCapPerUser: Int
    $dailyCountCapPerUser: Int
    $amountCap: BigInt
    $dailyAmountCap: BigInt
    $countCap: Int
    $boostCampaignContents: [BoostCampaignContentInput]
    $targetTypes: [String]
    $animationType: String
    $attributionUrl: String
    $boostUpPolicy: Int
    $adSpendCalculationType: String
    $adSpendUnitPrice: BigInt
    $adSpendRatio: Float
    $adSpendWeight: Float
  ) {
    boostCampaignUpdate(
      id: $id
      brandId: $brandId
      boostBudgetId: $boostBudgetId
      boostPromotionId: $boostPromotionId
      start: $start
      end: $end
      title: $title
      description: $description
      status: $status
      usableFrom: $usableFrom
      usableTo: $usableTo
      buyableFrom: $buyableFrom
      buyableTo: $buyableTo
      targetUserTags: $targetUserTags
      totalCountCapPerUser: $totalCountCapPerUser
      dailyCountCapPerUser: $dailyCountCapPerUser
      amountCap: $amountCap
      dailyAmountCap: $dailyAmountCap
      countCap: $countCap
      boostCampaignContents: $boostCampaignContents
      targetTypes: $targetTypes
      animationType: $animationType
      attributionUrl: $attributionUrl
      boostUpPolicy: $boostUpPolicy
      adSpendCalculationType: $adSpendCalculationType
      adSpendUnitPrice: $adSpendUnitPrice
      adSpendRatio: $adSpendRatio
      adSpendWeight: $adSpendWeight
    )
  }
`;

export interface IBoostCampaignListItem {
  id: number;
  title: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  start: Date;
  end: Date;
  usableFrom: string;
  usableTo: string;
  buyableFrom: string;
  buyableTo: string;
  boostBudgetId: number;
  boostPromotionId: number;
  adSpendCalculationType: string;
  status: string;
  statusLabel: string;
  targetUserTags?: [string];
  totalCountCapPerUser: number;
  dailyCountCapPerUser: number;
  amountCap: number;
  dailyAmountCap: number;
  targetTypes: string[];
  boostPromotion: BoostPromotion;
  boostBudget: BoostBudget;
  reservedCampaignBudget: number; // 소진 가능한 예산 (부스트 구매)
  usedCampaignBudget: number; // 소진된 예산 (부스트 사용)
  todayUsedCampaignBudget: number; // 오늘 소진된 예산 (부스트 사용)
  todayReservedCampaignBudget: number; // 오늘 소진 가능한 예산 (부스트 구매)
  reasonLabels: [string]; // 소진 이유
}

interface BoostPromotion {
  typoImg: any;
  type: string;
  boltPrice: number;
  title: string;
  subTitle: string;
  countCap: number;
  discountAmountMax: number;
  promotion: {
    discount: string;
    maxDiscountAmount: number;
    priceMin: number;
  };
  brand: {
    id: number;
    name: string;
    logoImageUrl: string;
    typoImageUrl: string;
    color: string;
    landingUrl: string;
    fallbackUrl: string;
    iosAppId: string;
    androidPackage: string;
  };
}

interface BoostBudget {
  budgetTotal: number;
  remainingTotal: number;
  dailyCap: number;
}

export interface IBoostCampaignList {
  list: [IBoostCampaignListItem];
  total: number;
}
