import React from 'react';
import { Box, Divider, Card } from '@mui/material';
import { INotificationMessage } from 'type/api/notification';
import styles from '../notification.module.scss';

const MessageCard: React.FC<{
  msg: INotificationMessage;
}> = ({ msg }) => {
  return (
    <Card className={styles.message}>
      {msg.subject && (
        <>
          {msg.subject}
          <Box m={1} />
          <Divider />
          <Box m={1} />
        </>
      )}
      {msg.content}
    </Card>
  );
};

export default MessageCard;
