import apolloClient from 'util/apolloClient';

import { authMutation } from './authGql';
import { requestMutation, confirmMutation } from './loginGql';

/**
 * Request OTP Code
 * @param {Object} args
 * @param {string} args.id
 * @param {string} args.password
 * @return {Promise<string>}
 */

export async function requestOTPAsync({ id, password }: IRequestOTP): Promise<string> {
  const {
    data: { request: code },
  } = await apolloClient.mutate({
    mutation: requestMutation,
    variables: {
      id,
      password,
    },
  });

  return code;
}

/**
 * Try Login
 * @param {Object} args
 * @param {string} args.id
 * @param {string} args.password
 * @return {Promise<string>}
 */
export async function loginAsync({ id, password, code }: ILogin): Promise<string> {
  const {
    data: { confirm },
  } = await apolloClient.mutate({
    mutation: confirmMutation,
    variables: {
      id,
      password,
      code,
    },
  });

  return confirm;
}

/**
 * get Auth
 */
export async function getAuthAsync(): Promise<LoginAuthData> {
  const {
    data: { auth },
  } = await apolloClient.mutate({
    mutation: authMutation,
    variables: {},
  });

  return auth;
}

export interface IRequestOTP {
  id: string;
  password: string;
}

export interface ILogin {
  id: string;
  password: string;
  code: string;
}

export interface LoginAuthData {
  id: string;
  uid: string;
  phone: string;
  fullname: string;
  passwordReset: boolean;
  token?: any; // ?
  otp: boolean;
  groups: Group[];
}

interface Group {
  id: string;
  name: string;
  code: string;
  description: string;
  actions: Action[];
}

interface Action {
  id: string;
  name: string;
  code: string;
  description: string;
}
