import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BoostTagListContainer } from './BoostTagListContainer';

export const BoostTag: React.FC<{}> = props => {
  return (
    <>
      <Switch>
        <Route path="/boost/boostTag/:conditionId" render={() => <BoostTagListContainer />} />
        <Route path="/" render={() => <BoostTagListContainer />} />
      </Switch>
    </>
  );
};
