import {BrowserRouter, Switch, Route, Redirect, useHistory} from 'react-router-dom';
import {Box, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Menu from 'page/Menu/Menu';

import LoginContainer from 'page/Login/LoginContainer';
import NotificationContainer from 'page/Notification/NotificationContainer';
import {useAuth} from './auth';
import {BoostTag} from 'page/Tag/BoostTag/BoostTag';
import {BoostPaymentTag} from 'page/Tag/PaymentTag/BoostPaymentTag';
import {BoostRouter} from 'page/Boost/boostContainer';
import {CategoryTag} from 'page/Tag/CategoryTag/CategoryTag';
import {UserTag} from 'page/Tag/UserTag/UserTag';
import {BoostMission} from 'page/Boost/BoostMission/BoostMission';
import {BoostCalendar} from 'page/Boost/Calendar/BoostCalendar';
import {BoostUp} from 'page/Boost/BoostUp/BoostUp';
import {BoostRequiredImportant} from 'page/BoostImportant/BoostRequirerdImportant/BoostRequiredImportant';
import {BoostOptionalImportant} from 'page/BoostImportant/BoostOptionalImportant/BoostOptionalImportant';
import {BoltDraw} from 'page/BoltDraw/BoltDraw';
import {Settlement} from "./page/Settlement/Settlement";
import {SettlementHistory} from "./page/SettlementHistory/SettlementHistory";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    minHeight: '100vh',
    boxSizing: 'border-box',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(12),
    display: 'flex',
    flex: 1,
  },
}));

function RequireAuth({children}: { children: JSX.Element }) {
  let auth = useAuth();
  let history = useHistory();

  const groupRouteConfigs: { path: string; groups: string[]; }[] = [
    {path: '/settlement', groups: ['finance']},
    {path: '/settlementHistory', groups: ['finance']},
  ];

  if (auth?.user) {
    const {groups} = auth.user;
    const groupCodes: string[] = groups.map(group => group.code);

    const isCanAccess = groupRouteConfigs
      .filter((config) => window.location.pathname.toLowerCase() === config.path.toLowerCase())
      .every((config) => config.groups.some((groupCode) => groupCodes.includes(groupCode)));

    if (!isCanAccess) {
      alert('접근 권한이 없습니다.');
      history.goBack();
    }
  }

  return children;
}

// Root Route
function Routes() {
  const auth = useAuth();
  const classes = useStyles();

  // 비 로그인 라우트
  if (!auth.user) {
    return (
      <BrowserRouter>
        <Box height="100vh">
          <Switch>
            <Route exact path="/login" component={LoginContainer}/>
            <Redirect to="/login"/>
          </Switch>
        </Box>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Box display="flex">
        <Menu/>
        <Box className={classes.contentWrapper}>
          <Switch>
            <Route path="/settlement" render={() => <RequireAuth><Settlement/></RequireAuth>}/>
            <Route path="/settlementHistory" render={() => <RequireAuth><SettlementHistory/></RequireAuth>}/>
            <Route path="/login" component={LoginContainer}/>
            <Route path="/notification" component={NotificationContainer}/>
            <Route path="/boost/paymentTag" render={() => <BoostPaymentTag/>}/>
            <Route path="/boost/boostTag" render={() => <BoostTag/>}/>
            <Route path="/boost/categoryTag" render={() => <CategoryTag/>}/>
            <Route path="/boost/mission" render={() => <BoostMission/>}/>
            <Route path="/boost/calendar" render={() => <BoostCalendar/>}/>
            <Route path="/boost/up" render={() => <BoostUp/>}/>
            <Route path="/boost" render={() => <BoostRouter/>}/>
            <Route path="/tag/userTag" render={() => <UserTag/>}/>
            <Route path="/important/required" render={() => <BoostRequiredImportant/>}/>
            <Route path="/important/optional" render={() => <BoostOptionalImportant/>}/>
            <Route path="/bolt-draw" render={() => <BoltDraw/>}/>
            <Route exact path="/" component={() => <Redirect to={'/notification'}/>}/>
          </Switch>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

export default Routes;
