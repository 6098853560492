import { Box, Button, Grid, Icon, IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import { DataGrid, GridApi, GridColDef, GridRowData, GridRowId } from '@mui/x-data-grid';
import { ChaiRadioForm, ChaiTextContextForm, ChaiWonForm } from 'common';
import ChaiDivider from 'common/component/ChaiDivider';
import { useFormContext } from 'react-hook-form';
import { wonString } from 'util/wonString';
import { useEffect } from 'react';
import { getBoostUpImportantQuery } from 'api/boostUpGql';
import { useLazyQuery } from '@apollo/client';
import { BoostUpSchemePolicy } from 'type/boost/boostUp';
import styles from '../boost.module.scss';
import IconMiss from 'asset/icBoostupMiss24@3x.png';
import IconSmall from 'asset/icBoostupSmall24@3x.png';
import IconLarge from 'asset/icBoostupLarge24@3x.png';

const dataGridValueFormatter: (id: GridRowId | undefined, api: GridApi, formatter: (row: any) => any) => any = (
  id,
  api,
  formatter
) => {
  if (!id) return '';
  const row = api.getRow(id);
  if (!row) return '';
  return formatter(row);
};

const getSchemeColumns: (onDelete: (param: GridRowData) => void) => GridColDef[] = onDelete => [
  {
    field: 'resultType',
    headerName: '케이스',
    sortable: false,
    disableColumnMenu: true,
    width: 90,
    valueFormatter: ({ id, api }) =>
      dataGridValueFormatter(
        id,
        api,
        ({ resultType, level }) => `${resultType}${resultType === 'win' ? ` ${level}` : ''}`
      ),
  },
  {
    field: 'delete',
    headerName: ' ',
    sortable: false,
    disableColumnMenu: true,
    width: 20,
    renderCell: params =>
      params.row.resultType === 'win' && (
        <IconButton size="small" onClick={() => onDelete(params.row)}>
          <Icon>highlight_off</Icon>
        </IconButton>
      ),
  },
  {
    field: 'probability',
    headerName: '확률',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    width: 90,
    valueParser: value => {
      if (!value) return 0;
      if (value > 100) return 100;
      return +value;
    },
    valueFormatter: ({ api, id }) => dataGridValueFormatter(id, api, ({ probability }) => `${probability}%`),
  },
  {
    field: 'cashbackAmountMin',
    headerName: '캐시백 최소 금액',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueParser: value => {
      if (!value) return 0;
      return +value;
    },
    valueFormatter: ({ api, id }) =>
      dataGridValueFormatter(id, api, ({ cashbackAmountMin }) => wonString(cashbackAmountMin)),
  },
  {
    field: 'cashbackAmountMax',
    headerName: '캐시백 최대 금액',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueParser: value => {
      if (!value) return 0;
      return +value;
    },
    valueFormatter: ({ api, id }) =>
      dataGridValueFormatter(id, api, ({ cashbackAmountMax }) => wonString(cashbackAmountMax)),
  },
  {
    field: 'cashbackAmountUnit',
    headerName: '추가 캐시백 금액 단위',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueParser: value => {
      if (!value) return 0;
      return +value;
    },
    valueFormatter: ({ api, id }) =>
      dataGridValueFormatter(id, api, ({ cashbackAmountUnit }) => wonString(cashbackAmountUnit)),
  },
  {
    field: 'discountRate',
    headerName: '추가 할인율',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    width: 110,
    valueParser: value => {
      if (!value) return 0;
      return +value;
    },
    valueFormatter: ({ api, id }) => dataGridValueFormatter(id, api, ({ discountRate }) => `${discountRate}%`),
  },
];

export const BoostUpDialogContent: React.FC<{ id: string }> = ({ id }) => {
  const { watch, setValue } = useFormContext<any>();

  const [getCommonDescription, { data: commonDescription }] = useLazyQuery(getBoostUpImportantQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getCommonDescription();
  }, [getCommonDescription]);

  const boostUpSchemePolicies: BoostUpSchemePolicy[] = watch('boostUpSchemePolicies');
  const sortedPolicies = boostUpSchemePolicies
    ? [...boostUpSchemePolicies].sort((a, b) => {
        if (a.resultType === 'jackpot') {
          return -1;
        }
        if (b.resultType === 'jackpot') {
          return 1;
        }
        if (a.resultType === 'miss') {
          return -1;
        }
        if (b.resultType === 'miss') {
          return 1;
        }
        return a.level - b.level;
      })
    : [];

  const description: string = watch('description');
  const visibleDateSet: 'disabled' | 'enabled' = watch('visibleDateSet');
  const onDeleteRow = (params: GridRowData) => {
    let newLevel = 0;
    const newArray = boostUpSchemePolicies
      .filter(item => item.id !== params.id)
      .map((item, idx) => {
        if (item.resultType !== 'win') {
          return { ...item, id: `${idx}` };
        }
        newLevel += 1;
        return { ...item, id: `${idx}`, level: newLevel };
      });
    setValue('boostUpSchemePolicies', newArray);
  };

  return (
    <>
      <ChaiRadioForm
        context
        required
        name="status"
        label="강화 폴리시 상태"
        labelMargin={4}
        values={[
          { label: '비활성화', value: 'disabled' },
          { label: '활성화', value: 'enabled' },
        ]}
      />
      <ChaiTextContextForm name="title" label="강화 폴리시 타이틀" placeholder="유저에게 노출되지 않습니다." required />
      <Box m={1} />
      <Grid container>
        <Grid item xs={6}>
          <ChaiRadioForm
            context
            multiple
            name="possibleMoments"
            label="강화 적용 시점"
            labelMargin={4}
            values={[
              { label: '후적용 시', value: 'manual_use_boost' },
              { label: '구매 전', value: 'before_buy_boost' },
              { label: '구매 후', value: 'after_buy_boost' },
            ]}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <ChaiRadioForm
            context
            multiple
            name="targetTypes"
            label="강화 적용 캠페인 대상"
            labelMargin={4}
            values={[
              { label: '레드', value: 'red' },
              { label: '신용카드', value: 'credit_card' },
              { label: '간편결제', value: 'e_wallet' },
            ]}
            required
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <ChaiRadioForm
            context
            name="visibleDateSet"
            label="노출기간 설정"
            labelMargin={4}
            values={[
              { label: '비활성화', value: 'disabled' },
              { label: '활성화', value: 'enabled' },
            ]}
            required
          />
          {visibleDateSet === 'enabled' && (
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Box sx={{ flexGrow: 1, paddingRight: '16px' }}>
                <ChaiTextContextForm
                  label="노출 시작"
                  name="visibleFromDate"
                  type="date"
                  margin="none"
                  noHelperText
                  required
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <ChaiTextContextForm
                  label=""
                  name="visibleFromTime"
                  type="time"
                  defaultValue="00:00"
                  margin="none"
                  noHelperText
                  required
                />
              </Box>
              <Box sx={{ width: 32, textAlign: 'center', padding: '19px 8px 5px 8px' }}>~</Box>
              <Box sx={{ flexGrow: 1, paddingRight: '16px' }}>
                <ChaiTextContextForm
                  label="노출 종료"
                  name="visibleToDate"
                  type="date"
                  margin="none"
                  noHelperText
                  required
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <ChaiTextContextForm
                  label=""
                  name="visibleToTime"
                  type="time"
                  defaultValue="23:59"
                  margin="none"
                  noHelperText
                  required
                />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <ChaiTextContextForm label="사용/구매 가능 시간" name="startAt" type="time" />
          </Box>
          <Box sx={{ width: 32, textAlign: 'center', padding: '25px 8px 5px 8px' }}>~</Box>
          <Box sx={{ flexGrow: 1 }}>
            <ChaiTextContextForm label="" name="endAt" type="time" />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <ChaiWonForm name="budget" label="예산" required />
          </Box>
          <Box sx={{ width: 32 }} />
          <Box sx={{ flexGrow: 1 }}>
            <ChaiWonForm name="dailyBudget" label="일예산" required />
          </Box>
        </Grid>
      </Grid>
      <ChaiTextContextForm
        name="boltPrice"
        label="1회당 번개 소진 개수"
        type="number"
        required
        endAdornment={<InputAdornment position="end">개</InputAdornment>}
      />
      <Box m={1} />
      <Box m={4} />
      <Typography variant="caption" color="textSecondary">
        강화 결과 세부 설정
      </Typography>
      <ChaiDivider />
      <Alert severity="warning">
        강화와 연결되는 캠페인이 정률 스킴인 경우 추가 캐시백과 추가 할인율 모두 적용됩니다.
        <br />
        정액 스킴일 경우 실제로는 추가 캐시백만 적용됩니다.
      </Alert>
      <Box m={1} />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          hideFooter
          rows={sortedPolicies || []}
          columns={getSchemeColumns(onDeleteRow)}
          density="compact"
          // isCellEditable={params => params.row > 2}
          onCellEditCommit={params => {
            const newArray = [...boostUpSchemePolicies];
            const idx = newArray.findIndex(item => item.id === params.id);
            newArray[idx] = {
              ...newArray[idx],
              [params.field]: params.value,
            };
            setValue('boostUpSchemePolicies', newArray);
          }}
        />
      </div>
      <Box m={1} />
      <Button
        fullWidth
        variant="outlined"
        size="small"
        onClick={() => {
          setValue('boostUpSchemePolicies', [
            ...boostUpSchemePolicies,
            {
              id: `${boostUpSchemePolicies.length}`,
              probability: 0,
              cashbackAmountMin: 0,
              cashbackAmountMax: 0,
              cashbackAmountUnit: 0,
              discountRate: 0,
              resultType: 'win',
              level: boostUpSchemePolicies.length - 1,
            },
          ]);
        }}
      >
        win 스킴 추가 +
      </Button>
      <Box m={4} />
      <Typography variant="caption" color="textSecondary">
        강화 유의사항 미리보기
      </Typography>
      <ChaiDivider />
      <Paper className={styles.wrapper}>
        {createDescription(description, commonDescription?.boostUpImportant, boostUpSchemePolicies)}
      </Paper>
      <Box p={1} />
      <ChaiTextContextForm name="description" label="개별 유의사항" multiline />
    </>
  );
};

const createDescription = (
  description: string,
  commonDescription: string,
  boostUpSchemePolicies: BoostUpSchemePolicy[]
) => {
  return (
    <div>
      <div className={styles.gray} dangerouslySetInnerHTML={{ __html: commonDescription || '' }}></div>
      <div className={styles.padding}></div>
      <table className={styles.schemeTable}>
        <thead>
          <tr>
            <th>적립금액</th>
            <th>추가할인율</th>
            <th>강화확률</th>
          </tr>
        </thead>
        <tbody>
          {(boostUpSchemePolicies || [])
            .sort((a, b) => (a.cashbackAmountMax || 0) - (b.cashbackAmountMax || 0))
            .map((item, idx) => {
              const icon =
                idx === 0 && item.cashbackAmountMax === 0
                  ? IconMiss
                  : idx === boostUpSchemePolicies.length - 1
                  ? IconLarge
                  : IconSmall;
              return (
                <tr key={`boost-up-detail-scheme-row-${idx}`}>
                  <td className={styles.schemeTableCashbackAmountMax}>
                    <img src={icon} className={styles.icon} alt="miss" />
                    {item.cashbackAmountMin === item.cashbackAmountMax
                      ? `${item.cashbackAmountMin.toLocaleString()}원`
                      : `${item.cashbackAmountMin.toLocaleString()}~${item.cashbackAmountMax.toLocaleString()}원`}
                  </td>
                  <td className={styles.schemeTableProbability}>{item.discountRate}%</td>
                  <td className={styles.schemeTableProbability}>{item.probability}%</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className={styles.padding}></div>
      <div className={styles.gray}>{description}</div>
    </div>
  );
};
