import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { hasErrorFormProps } from 'util/formFieldProps';

export const ChaiWonForm: React.FC<{
  name: string;
  label?: string;
  min?: number;
  max?: number;
  value?: string | null;
  defaultValue?: string | number;
  placeholder?: string;
  register?: any; // react-hook-form register
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  multiline?: boolean;
  margin?: any;
  size?: 'small' | 'medium' | undefined;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  maxRows?: number;
  inputProps?: any;
  errors?: DeepMap<any, FieldError>;
  startAdornment?: any;
  endAdornment?: any;
  valueAsText?: boolean; // 값을 text로 리턴합니다
  validate?(a: number): boolean | string;
}> = ({
  name,
  label,
  min,
  max,
  value = null,
  defaultValue,
  placeholder,
  inputProps = {},
  required = false,
  disabled = false,
  readOnly = false,
  margin = 'dense',
  size,
  variant = 'standard',
  maxRows,
  startAdornment,
  endAdornment,
  valueAsText = false,
  validate,
}) => {
  const registerRef: {
    required?: boolean;
    valueAsNumber?: boolean;
    pattern?: RegExp;
    min?: number;
    max?: number;
    validate?(a: number): boolean | string;
  } = {};
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<any>();

  if (!register || !errors) {
    throw new Error(`Form context load error: ${name}`);
  }

  if (required) {
    registerRef.required = true;
  }
  if (min) {
    registerRef.min = min;
  }
  if (max) {
    registerRef.max = max;
  }
  if (validate) {
    registerRef.validate = validate;
  }
  const inputRef = register && register(name, registerRef);
  let additionalProps: any = {};

  if (name) {
    additionalProps = {
      name,
      label,
      ...hasErrorFormProps({ name, error: errors }),
      ...additionalProps,
    };
  } else {
    additionalProps = {
      label,
      ...additionalProps,
    };
  }

  return (
    <Controller
      render={({ field }) => (
        <NumberFormat
          fullWidth
          InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }}
          {...{
            name,
            value: field.value,
            ref: field.ref,
            margin,
            placeholder,
            variant,
            disabled,
            ...additionalProps,
          }}
          InputProps={{
            startAdornment,
            endAdornment: <InputAdornment position="end">원</InputAdornment>,
            readOnly,
            ...inputProps,
          }}
          getInputRef={inputRef.ref}
          onValueChange={values => {
            field.onChange({
              target: {
                name: name,
                value: valueAsText ? `${values.floatValue}` : values.floatValue,
              },
            });
          }}
          customInput={TextField}
          thousandSeparator
          isNumericString
        />
      )}
      name={name}
      control={control}
    />
  );
};
