import gql from 'graphql-tag';

export const brandQuery = gql`
  query brand($id: Int!) {
    brand(id: $id) {
      id
      name
      logoImageUrl
      typoImageUrl
      color
      landingUrl
      fallbackUrl
      iosAppId
      androidPackage
    }
  }
`;

export const brandListQuery = gql`
  query brandList($skip: Int, $pageSize: Int, $filter: String) {
    brandList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        name
        # logoImageUrl
        # typoImageUrl
        # color
        # landingUrl
        # fallbackUrl
        # iosAppId
        # androidPackage
      }
      total
    }
  }
`;

export const brandContentListQuery = gql`
  query brandContentList($id: Int!, $visible: String) {
    brandContentList(id: $id, visible: $visible) {
      list {
        id
        sourceFileName
        bucketFileName
        type
        status
        visible
        priority
        errorMessage
        urls {
          type
          url
        }
      }
      total
    }
  }
`;

export const brandUploadContent = gql`
  mutation brandUploadContent($brandId: Int!, $file: FileUpload!, $sourceFileName: String!, $visible: String!) {
    brandUploadContent(brandId: $brandId, file: $file, sourceFileName: $sourceFileName, visible: $visible) {
      name
      status
      url
    }
  }
`;

export const brandSwapContentPriority = gql`
  mutation brandSwapContentPriority($contentId: Int!, $contentIdToSwap: Int!) {
    brandSwapContentPriority(contentId: $contentId, contentIdToSwap: $contentIdToSwap)
  }
`;

export const brandDeleteContent = gql`
  mutation brandDeleteContent($contentId: Int!) {
    brandDeleteContent(contentId: $contentId)
  }
`;

export const brandChangeContentVisible = gql`
  mutation brandChangeContentVisible($contentId: Int!, $visible: String!) {
    brandChangeContentVisible(contentId: $contentId, visible: $visible)
  }
`;

export const brandUploadImageMutation = gql`
  mutation brandUploadImage($imageFile: FileUpload!) {
    brandUploadImage(imageFile: $imageFile) {
      filename
      path
    }
  }
`;

export const brandAddMutation = gql`
  mutation brandAdd(
    $name: String!
    $logoImageUrl: String!
    $typoImageUrl: String!
    $color: String!
    $landingUrl: String
    $fallbackUrl: String
    $iosAppId: String
    $androidPackage: String
  ) {
    brandAdd(
      name: $name
      logoImageUrl: $logoImageUrl
      typoImageUrl: $typoImageUrl
      color: $color
      landingUrl: $landingUrl
      fallbackUrl: $fallbackUrl
      iosAppId: $iosAppId
      androidPackage: $androidPackage
    )
  }
`;

export const brandUpdateMutation = gql`
  mutation brandUpdate(
    $id: Int!
    $name: String
    $logoImageUrl: String
    $typoImageUrl: String
    $color: String
    $landingUrl: String
    $fallbackUrl: String
    $iosAppId: String
    $androidPackage: String
  ) {
    brandUpdate(
      id: $id
      name: $name
      logoImageUrl: $logoImageUrl
      typoImageUrl: $typoImageUrl
      color: $color
      landingUrl: $landingUrl
      fallbackUrl: $fallbackUrl
      iosAppId: $iosAppId
      androidPackage: $androidPackage
    )
  }
`;

export interface IBrand {
  id?: number;
  name: string;
  logoImageUrl: string;
  typoImageUrl: string;
  color: string;
  landingUrl?: string;
  fallbackUrl?: string;
  iosAppId?: string;
  androidPackage?: string;
}
