import { useLazyQuery } from '@apollo/client';
import { Box, Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { paymentTagListQuery } from 'api/boostTagGql';
import { ChaiDropdownMenu, ChaiTextForm } from 'common';
import React, { useEffect } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import styles from '../../Boost/boost.module.scss';
import { BoostTagCondition, BoostTagForm, BoostTagLogicalType, BoostTagType } from './BoostTagDialog';
import { BoostTagInput } from './BoostTagInput';

export const BoostTagContent: React.FC<{ content?: BoostTagForm; isEdit?: boolean }> = ({
  content,
  isEdit = false,
}) => {
  const { setValue } = useFormContext<BoostTagForm>();
  const { watch } = useFormContext<BoostTagForm>();
  const operator = watch('rootOperator');

  const [getPaymentTagList, { data: paymentTagList }] = useLazyQuery(paymentTagListQuery, {
    variables: { pageSize: 99999 },
  });

  useEffect(() => {
    getPaymentTagList();
  }, [getPaymentTagList]);

  const addSingleCondition = () => {
    setValue(`conditions.${content?.conditions?.length || 0}`, { type: BoostTagType.SINGLE });
  };

  const addGroupCondition = () => {
    setValue(`conditions.${content?.conditions?.length || 0}`, {
      type: BoostTagType.GROUP,
      subOperator: BoostTagLogicalType.AND,
      subConditions: [{ type: BoostTagType.SINGLE }],
    });
  };

  return (
    <Box bgcolor="#ededed" style={{ borderRadius: 5, padding: 20 }}>
      <Grid container spacing={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
        {content?.conditions?.map((condition: BoostTagCondition, idx: number) => (
          <TargetMerchantConditionItem
            isEdit={isEdit}
            idx={idx}
            key={`target-merchant-condition-item-${idx}`}
            condition={condition}
            paymentTagList={paymentTagList?.paymentTagList?.list}
            operator={operator}
          />
        ))}
      </Grid>
      {isEdit && (
        <>
          <Box m={2} />
          <ChaiDropdownMenu
            name="boost-tag-add-dropdown"
            buttonText="조건 추가"
            startIcon="add"
            menuList={[
              {
                icon: 'add_box',
                title: '단일 조건 추가',
                action: () => addSingleCondition(),
              },
              {
                icon: 'library_add',
                title: '조건 그룹 추가',
                subtitle: '더 많은 조건 중첩을 위한 그룹',
                action: () => addGroupCondition(),
              },
            ]}
          />
        </>
      )}
    </Box>
  );
};

const TargetMerchantConditionItem: React.FC<{
  idx: number;
  subIdx?: number;
  isEdit: boolean;
  condition: BoostTagCondition;
  paymentTagList: any;
  operator: BoostTagLogicalType;
}> = ({ idx, subIdx, isEdit, condition, paymentTagList, operator }) => {
  const { watch, reset, control, getValues, setValue } = useFormContext<BoostTagForm>();
  const subOperator: BoostTagLogicalType | undefined = watch(`conditions.${idx}.subOperator`);

  const isGroup = condition.type === 'group';
  const isSub = typeof subIdx !== 'undefined';
  const namePrefix: `conditions.${number}` | `conditions.${number}.subConditions.${number}` = isSub
    ? `conditions.${idx}.subConditions.${subIdx}`
    : `conditions.${idx}`;
  const handleDelete = () => {
    const values = getValues();
    if (isSub) {
      const currentConditions = values.conditions;
      const currentSubConditions = currentConditions?.[idx].subConditions;
      currentConditions[idx] = {
        ...currentConditions[idx],
        subConditions: currentSubConditions?.filter((item: BoostTagCondition, itemIdx: number) => subIdx !== itemIdx),
      };
      reset({
        ...values,
        conditions: currentConditions,
      });
    } else {
      reset({
        ...values,
        conditions: values.conditions.filter((item: BoostTagCondition, itemIdx: number) => idx !== itemIdx),
      });
    }
  };

  const handleSwap = () => {
    const values = getValues();
    const currentConditions = values.conditions;
    const isGroup = currentConditions[idx].type === BoostTagType.GROUP;
    if (isGroup) {
      currentConditions[idx] = {
        type: BoostTagType.SINGLE,
        value: currentConditions[idx].subConditions?.[0]?.value,
      };
    } else {
      currentConditions[idx] = {
        type: BoostTagType.GROUP,
        subOperator: BoostTagLogicalType.AND,
        subConditions: [
          {
            type: BoostTagType.SINGLE,
            value: currentConditions[idx].value,
          },
        ],
      };
    }

    reset({
      ...values,
      conditions: currentConditions,
    });
  };

  const addSingleCondition = () => {
    const newIdx = condition?.subConditions?.length || 0;
    setValue(`conditions.${idx}.subConditions.${newIdx}`, { type: BoostTagType.SINGLE });
  };

  let prefixType: 'where' | 'select' | 'text' = 'text';
  if ((!isSub && idx === 0) || subIdx === 0) {
    prefixType = 'where';
  } else if ((!isSub && idx === 1) || subIdx === 1) {
    if (isEdit) {
      prefixType = 'select';
    }
  }
  const itemValue = Number(get(getValues(), `${namePrefix}.value`));
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={2}>
          {prefixType === 'where' && (
            <Typography variant="subtitle2" color="textPrimary" className={styles.conditionOperator}>
              Where
            </Typography>
          )}
          {prefixType === 'select' && (
            <Controller
              render={({ field }) => {
                return (
                  <Select variant="outlined" style={{ height: 40, fontSize: 14, fontWeight: 'bold' }} {...field}>
                    <MenuItem value={'and'}>And</MenuItem>
                    <MenuItem value={'or'}>Or</MenuItem>
                  </Select>
                );
              }}
              name={isSub ? `conditions.${idx}.subOperator` : `rootOperator`}
              control={control}
            />
          )}
          {prefixType === 'text' && (
            <Typography variant="subtitle2" color="textPrimary" className={styles.conditionOperator}>
              {isSub ? subOperator : operator}
            </Typography>
          )}
        </Grid>
        <Grid item xs={9}>
          {!isGroup &&
            (isEdit ? (
              <BoostTagInput paymentTagList={paymentTagList} namePrefix={namePrefix} />
            ) : (
              <ChaiTextForm
                readOnly
                size="small"
                margin="none"
                name={`${namePrefix}.value`}
                value={paymentTagList?.find((item: { id: number }) => item.id === itemValue)?.name || ' '}
                variant="outlined"
                inputProps={{ style: { backgroundColor: 'white' } }}
              />
            ))}
          {isGroup && (
            <Box className={styles.conditionGroupBox}>
              {/* 그룹 child */}
              <Grid container spacing={2} justifyContent="space-between">
                {condition?.subConditions?.map((subCondition, subIdx) => {
                  return (
                    <TargetMerchantConditionItem
                      idx={idx}
                      subIdx={subIdx}
                      isEdit={isEdit}
                      key={`target-merchant-condition-sub-item-${subIdx}`}
                      condition={subCondition}
                      paymentTagList={paymentTagList}
                      operator={operator}
                    />
                  );
                })}
              </Grid>
              {/* 조건 추가 버튼 (그룹) */}
              {isEdit && (
                <>
                  <Box m={2} />
                  <Box textAlign="right">
                    <Button
                      color="primary"
                      aria-controls="condition-add"
                      aria-haspopup="true"
                      onClick={addSingleCondition}
                      startIcon={<AddIcon />}
                    >
                      조건 추가
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Grid>
        {/* 조건 변경/삭제 버튼 */}
        {isEdit && (
          <Grid item xs={1} className={styles.conditionDropdown}>
            <ChaiDropdownMenu
              name="form-delete"
              buttonColor="inherit"
              menuList={[
                {
                  icon: 'highlight_off',
                  title: '삭제',
                  action: () => handleDelete(),
                },
                ...(isSub
                  ? []
                  : [
                      {
                        icon: 'swap_horiz',
                        title: '조건유형 변경',
                        action: () => handleSwap(),
                      },
                    ]),
              ]}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
