import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { RootState } from 'reducers';
import { removeSnackbar } from 'state/notistackState';

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store: RootState) => store.notistack || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayed, setDisplayed] = useState<string[]>([]);

  React.useEffect(() => {
    notifications.notistacks.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;
      enqueueSnackbar(message, {
        key,
        ...options,
        onEntered: (event, appearing, key) => {
          dispatch(removeSnackbar(key));
        },
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
      });

      setDisplayed(displayed => [...displayed, key]);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, setDisplayed, displayed]);

  return null;
};

export default Notifier;
