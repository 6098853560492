import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import ChaiDivider from 'common/component/ChaiDivider';
import React, { useEffect } from 'react';
import { useAlert } from 'common/context/alert';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { boostUpImportantUpdateMutation, getBoostUpImportantQuery } from 'api/boostUpGql';
import apolloClient from 'util/apolloClient';
import { ChaiRichTextEditor } from 'common/component/ChaiRichTextEditor';

interface ICommonDescriptionForm {
  commonDescription: string;
}

export const BoostUpDescriptionDialog: React.FC<{ setDialogStatus: (status: boolean) => void }> = ({
  setDialogStatus,
}) => {
  const history = useHistory();
  const alert = useAlert();
  const form = useForm<ICommonDescriptionForm>({ shouldUnregister: false });
  const { watch, setValue, handleSubmit } = form;
  const [getCommonDescription, { data }] = useLazyQuery(getBoostUpImportantQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const commonDescription = data?.boostUpImportant;

    setValue('commonDescription', commonDescription);
  }, [data, setValue]);

  useEffect(() => {
    getCommonDescription();
  }, [getCommonDescription]);

  const handleCancel = () => {
    closeDialog();
    history.push('/boost/up/');
  };

  const closeDialog = () => {
    setDialogStatus(false);
  };

  const onEditorStateChange = (editorState?: string) => {
    if (editorState) {
      setValue('commonDescription', editorState);
    }
  };

  const onSubmit = (data: ICommonDescriptionForm) => {
    alert.confirm('공통 유의사항 저장', '변경 사항이 모든 강화 폴리시에 반영됩니다. 변경하시겠습니까?', async () => {
      try {
        await apolloClient.mutate({
          mutation: boostUpImportantUpdateMutation,
          variables: { value: data.commonDescription + '<br>' },
        });
        closeDialog();
      } catch (e) {
        console.error(e);
      }
    });
  };

  const editorContent = watch('commonDescription');

  return (
    <FormProvider {...form}>
      <DialogTitle>강화 공통 유의사항 설정</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" color="textSecondary">
          강화 유의사항
        </Typography>
        <ChaiDivider />
        <Alert severity="warning">집행 중인 모든 강화 폴리시에 적용됩니다.</Alert>
        <Box m={2} />
        <Box style={{ height: '400px' }}>
          <ChaiRichTextEditor style={{ height: '90%' }} value={editorContent || ''} onChange={onEditorStateChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button onClick={() => handleCancel()} color="inherit">
              취소
            </Button>
            <Button onClick={handleSubmit(onSubmit)} color="primary">
              저장
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </FormProvider>
  );
};
