import gql from 'graphql-tag';

// Queries
export const getMenuMapRouterQuery = gql`
  query getMenuMapToRouter {
    getMenuMapToRouter
  }
`;

// Mutations
export const requestMutation = gql`
  mutation request($id: String!, $password: String!) {
    request(id: $id, password: $password)
  }
`;

export const confirmMutation = gql`
  mutation confirm($code: String!, $id: String, $password: String) {
    confirm(code: $code, id: $id, password: $password)
  }
`;

export const updatePasswordMutation = gql`
  mutation updatePassword($password: String!, $newPassword: String!) {
    updatePassword(password: $password, newPassword: $newPassword)
  }
`;

export const requestResetMutation = gql`
  mutation requestReset(
    $carrier: String!
    $phone: String!
    $birth: String!
    $name: String!
    $sex: String!
    $foreigner: String!
  ) {
    requestReset(carrier: $carrier, phone: $phone, birth: $birth, name: $name, sex: $sex, foreigner: $foreigner)
  }
`;

export const confirmResetMutation = gql`
  mutation confirmReset(
    $phone: String!
    $birth: String!
    $name: String!
    $sex: String!
    $foreigner: String!
    $code: String!
    $uid: String!
  ) {
    confirmReset(phone: $phone, birth: $birth, name: $name, sex: $sex, foreigner: $foreigner, code: $code, uid: $uid)
  }
`;
