import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { boostBudgetListQuery, IBoostBudget } from 'api/boostBudgetGql';
import { boostListQuery, boostQuery, IBoostPromotionListItem } from 'api/boostPromotionGql';
import { getBoostUpListQuery } from 'api/boostUpGql';
import { brandContentListQuery, IBrand } from 'api/brandGql';
import { getUserTagListQuery } from 'api/userTagGql';
import {
  ChaiAutoCompleteList,
  ChaiSelectForm,
  ChaiRadioForm,
  ChaiTextContextForm,
  ChaiTextForm,
  ChaiWonForm,
} from 'common';
import ChaiDivider from 'common/component/ChaiDivider';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { IBoostUp } from 'type/boost/boostUp';
import styles from '../boost.module.scss';
import { RenderPageSummary } from '../BoostPromotion/BoostPromotionDialogContent';
import BoostPreviewComponent from '../component/BoostPreviewComponent';
import { BoostParams } from './BoostContentContainer';

export const BOOST_TARGET_TYPES = [
  { label: '레드', value: 'red' },
  { label: '신용카드', value: 'credit_card' },
  { label: '간편결제', value: 'e_wallet' },
];

export const BoostCampaignDialogContent: React.FC<{ page: number; brandInformation?: IBrand }> = ({
  page,
  brandInformation,
}) => {
  const { campaignId, action } = useParams<BoostParams>();
  const { getValues, watch } = useFormContext<any>();
  const formValues = getValues();

  const [getBoost, { data: boostData }] = useLazyQuery(boostQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const boostPromotionId = watch('boostPromotionId');
  const boostPromotionData = boostData?.boostPromotion;

  useEffect(() => {
    if (boostPromotionId) {
      getBoost({ variables: { id: Number(boostPromotionId) } });
    }
  }, [getBoost, boostPromotionId]);

  const PageContent = () => {
    switch (page) {
      case 0:
        return <RenderPage0 id={campaignId} />;
      case 1:
        return <CampaignBoostPromotionPage brandName={brandInformation?.name} boostPromotion={boostPromotionData} />;
      case 2:
        return <RenderPage1 />;
      case 3:
        return <RenderPage3 />;
      case 4:
        return <CampaignSummary values={formValues} />;
    }
    return <></>;
  };

  let previewProps: any = {
    name: boostPromotionData?.subTitle || brandInformation?.name || '',
    color: brandInformation?.color,
    logoImg: boostPromotionData?.logoImageUrl || brandInformation?.logoImageUrl || '',
  };

  if (page >= 1 || action === 'copy') {
    previewProps = {
      ...previewProps,
      type: boostPromotionData?.type,
      subText: boostPromotionData?.title,
      smallText: boostPromotionData?.benefitDescription,
      bolt: boostPromotionData?.boltPrice || '0',
    };
  }

  // 복사
  if (action === 'copy') {
    return (
      <>
        <Grid container spacing={6}>
          <Grid item xs={8}>
            <RenderPage0 id={'new'} />
            <Box m={1} />
            <CampaignBoostPromotionPage brandName={brandInformation?.name} boostPromotion={boostPromotionData} />
            <Box m={2} />
            <RenderPage1 />
            <Box m={1} />
            <RenderPage3 />
          </Grid>
          <Grid item xs={4}>
            <Box className={styles.sticky} style={{ top: 0, right: 0 }}>
              <Typography variant="subtitle2" color="textSecondary">
                미리보기
              </Typography>
              <Box m={1} />
              <BoostPreviewComponent {...previewProps} />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={8}>
        {PageContent()}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" color="textSecondary">
          미리보기
        </Typography>
        <Box m={1} />
        <BoostPreviewComponent {...previewProps} />
      </Grid>
    </Grid>
  );
};

const RenderPage0 = ({ id }: { id?: string }) => (
  <>
    <ChaiTextForm label="캠페인 ID" value={id === 'new' ? '캠페인ID는 자동으로 부여됩니다.' : id} disabled />
    <ChaiTextContextForm label="타이틀" name="title" required shrink />
    <ChaiTextContextForm label="설명" name="description" shrink />
  </>
);

const RenderPage1 = () => {
  const { brandId } = useParams<BoostParams>();
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<any>();
  const [getBudgetList, { data }] = useLazyQuery(boostBudgetListQuery, {
    variables: { pageSize: 9999, filter: JSON.stringify({ brandId }) },
    notifyOnNetworkStatusChange: true,
  });
  const [getUserTagList, { data: userTagData }] = useLazyQuery(getUserTagListQuery, {
    variables: { pageSize: 9999 },
    notifyOnNetworkStatusChange: true,
  });
  const budgetList: IBoostBudget[] = data?.boostBudgetList?.list || [];
  const budgetId = watch('boostBudgetId');
  const userTags = watch('targetUserTags');
  const adSpendCalculationType = watch('adSpendCalculationType');
  const userTagList = userTagData?.getTagList?.list || [];
  const targetUserTagList =
    userTags?.map((tag: any) => userTagList?.find((userTag: any) => userTag.name === tag)).filter(Boolean) || [];
  const targetBudget = budgetList?.find(budget => budget.id === budgetId) || null;
  const budgetType = targetBudget?.type || '';
  const campaignAmount = (targetBudget && targetBudget?.remainingTotal) || 0;
  const dailyCap = (targetBudget?.dailyCap || 0).toLocaleString();

  useEffect(() => {
    getBudgetList();
    getUserTagList();
  }, [getBudgetList, getUserTagList]);

  useEffect(() => {
    if (budgetType === 'chai_only') {
      setValue('adSpendCalculationType', 'chai');
    }
  }, [budgetType, setValue]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            autoComplete
            autoHighlight
            options={userTagList}
            getOptionLabel={(option: any) => option?.name}
            onChange={(e, value) => {
              setValue(
                'targetUserTags',
                value.map(tag => tag.name)
              );
            }}
            value={targetUserTagList}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip size="small" label={option.name} {...getTagProps({ index })} />)
            }
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                value=""
                label="유저 태그"
                size="small"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: styles.backgroundWhite,
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            autoComplete
            autoHighlight
            options={budgetList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => option.title}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )}
            onChange={(e, value) => {
              setValue('adSpendCalculationType', '');
              setValue('boostBudgetId', value?.id);
            }}
            value={targetBudget}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label="예산 선택"
                size="small"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: styles.backgroundWhite,
                  },
                }}
              />
            )}
            // error={!!errors?.boostBudgetId}
            // helperText={errors?.boostBudgetId?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <ChaiTextForm
            label="남은 예산"
            name="campaignAmount"
            readOnly
            value={campaignAmount.toLocaleString()}
            endAdornment={<InputAdornment position="end">원</InputAdornment>}
          />
        </Grid>
        <Grid item xs={6}>
          <ChaiTextForm
            label="하루 예산"
            name="dailyCap"
            readOnly
            value={dailyCap}
            endAdornment={<InputAdornment position="end">원</InputAdornment>}
          />
        </Grid>
        <Grid item xs={6}>
          <ChaiWonForm errors={errors} required label="캠페인 전체 예산" name="amountCap" />
        </Grid>
        <Grid item xs={6}>
          <ChaiWonForm errors={errors} required label="캠페인 하루 예산" name="dailyAmountCap" />
        </Grid>
        <Grid item xs={6}>
          <ChaiSelectForm
            label="가맹점 청구 타입"
            name="adSpendCalculationType"
            errors={errors}
            required
            defaultValue=""
            control={control}
          >
            {budgetType === 'ratio' ? (
              <MenuItem value="ratio">가맹점 분담(%)</MenuItem>
            ) : budgetType === 'unit_price' ? (
              [
                <MenuItem key="cps" value="cps">
                  CPS(원)
                </MenuItem>,
                <MenuItem key="cpa" value="cpa">
                  CPA(원)
                </MenuItem>,
              ]
            ) : budgetType === 'chai_only' ? (
              <MenuItem value="chai">차이 부담(%)</MenuItem>
            ) : (
              <MenuItem value="">예산을 선택해주세요</MenuItem>
            )}
          </ChaiSelectForm>
        </Grid>
        <Grid item xs={6}>
          {adSpendCalculationType === 'none' ? (
            <ChaiTextForm placeholder="청구 타입을 선택해주세요" name="adSpendPriceNone" value="" label="" readOnly />
          ) : adSpendCalculationType === 'cps' || adSpendCalculationType === 'cpa' ? (
            <ChaiWonForm label="CPS/CPA 단가" required name="adSpendUnitPrice" />
          ) : adSpendCalculationType === 'ratio' ? (
            <ChaiTextContextForm
              name="adSpendRatio"
              label="분담률"
              type="number"
              min={0}
              max={100}
              required
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
          ) : (
            <ChaiTextForm
              name="adSpendChai"
              label="분담률"
              disabled
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
          )}
        </Grid>
        {adSpendCalculationType === 'cpa' && (
          <>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <ChaiTextContextForm
                name="adSpendWeight"
                defaultValue="50"
                label="신규 유저 비율"
                type="number"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <ChaiTextContextForm
            name="dailyCountCapPerUser"
            label="유저 1인당 1일 최대 부스트 구매 수"
            type="number"
            startAdornment={<InputAdornment position="start">하루에</InputAdornment>}
            endAdornment={<InputAdornment position="end">개</InputAdornment>}
          />
        </Grid>
        <Grid item xs={6}>
          <ChaiTextContextForm
            name="totalCountCapPerUser"
            label="유저 1인당 기간 내 최대 부스트 구매 수"
            type="number"
            startAdornment={<InputAdornment position="start">캠페인 내</InputAdornment>}
            endAdornment={<InputAdornment position="end">개</InputAdornment>}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6} style={{ paddingRight: 8 }}>
              <ChaiTextContextForm label="시작일" name="startAtDate" type="date" />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 8 }}>
              <ChaiTextContextForm label="" name="startAtTime" type="time" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6} style={{ paddingRight: 8 }}>
              <ChaiTextContextForm label="종료일" name="endAtDate" type="date" />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 8 }}>
              <ChaiTextContextForm label="" name="endAtTime" type="time" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="space-between">
            <Grid item xs>
              <ChaiTextContextForm label="구매 가능 시간 (HH:MM)" name="buyableFrom" type="time" />
            </Grid>
            <Grid item style={{ marginTop: 24, marginBottom: 4, paddingLeft: 8, paddingRight: 8, lineHeight: '24px' }}>
              ~
            </Grid>
            <Grid item xs>
              <ChaiTextContextForm label="" name="buyableTo" type="time" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="space-between">
            <Grid item xs>
              <ChaiTextContextForm label="사용 가능 시간 (HH:MM)" name="usableFrom" type="time" />
            </Grid>
            <Grid item style={{ marginTop: 24, marginBottom: 4, paddingLeft: 8, paddingRight: 8, lineHeight: '24px' }}>
              ~
            </Grid>
            <Grid item xs>
              <ChaiTextContextForm label="" name="usableTo" type="time" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const CampaignBoostPromotionPage = ({ brandName, boostPromotion }: { brandName?: string; boostPromotion: any }) => {
  const { brandId } = useParams<BoostParams>();
  const { watch, setValue } = useFormContext<any>();
  const [getBoostList, { data }] = useLazyQuery(boostListQuery, {
    variables: { pageSize: 99999, filter: JSON.stringify({ brandId }) },
    notifyOnNetworkStatusChange: true,
  });

  const boostList: IBoostPromotionListItem[] = data?.boostPromotionList?.list || [];
  const boostPromotionId = watch('boostPromotionId');
  const targetBoost = boostList?.find(budget => budget.id === `${boostPromotionId}`) || null;

  useEffect(() => {
    getBoostList();
  }, [getBoostList]);

  return (
    <>
      <ChaiRadioForm
        context
        name="targetTypes"
        label="캠페인 대상 (중복선택 가능)"
        required
        multiple
        values={BOOST_TARGET_TYPES}
      />
      <Box m={2} />
      <Autocomplete
        autoComplete
        autoHighlight
        options={boostList}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.name || `${brandName} 부스트 (${option.id})`}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        onChange={(e, value) => {
          setValue('boostPromotionId', value?.id);
        }}
        value={targetBoost}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="부스트 프로모션 선택"
            size="small"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: styles.backgroundWhite,
              },
            }}
          />
        )}
      />
      {targetBoost && (
        <>
          <Box m={2} />
          <Paper style={{ backgroundColor: '#eeeeee', padding: 24 }}>
            <RenderPageSummary values={boostPromotion} />
          </Paper>
        </>
      )}
    </>
  );
};

const RenderPage3 = () => {
  const { watch } = useFormContext<any>();
  const hasMediaContent = watch('hasMediaContent');
  const hasBoostUp = watch('hasBoostUp');
  const currentBoostUpName = watch('boostUpPolicyName');
  return (
    <>
      <ChaiRadioForm
        context
        required
        name="animationType"
        label="강조 애니메이션"
        labelMargin={8}
        values={[
          { label: '없음', value: 'normal' },
          { label: '로고 스케일', value: 'logo_scale' },
        ]}
      />
      <Box m={2} />
      <ChaiRadioForm
        context
        required
        name="hasMediaContent"
        label="미디어 콘텐츠 노출 여부"
        labelMargin={8}
        values={[
          { label: '노출안함', value: 'disabled' },
          { label: '노출함', value: 'enabled' },
        ]}
      />
      <Box m={2} />
      <>{hasMediaContent === 'enabled' && <RenderMediaContent />}</>
      <Box m={2} />
      <ChaiTextContextForm name="attributionUrl" label="어트리뷰션 링크 (attributionUrl)" />
      <Box m={2} />
      <ChaiRadioForm
        context
        required
        name="hasBoostUp"
        label="강화 설정"
        labelMargin={8}
        values={[
          { label: '노출안함', value: 'disabled' },
          { label: '노출함', value: 'enabled' },
        ]}
      />
      <>
        <Box m={2} />
        {hasBoostUp === 'enabled' && (
          <ChaiAutoCompleteList<IBoostUp>
            name="boostUpPolicy"
            label="강화 폴리시 선택"
            query={getBoostUpListQuery}
            getLabel={(item: IBoostUp) => `${item.title}`}
            getName={(item: IBoostUp) => `${item.title} (${item.id})`}
            makeQueryVariable={(searchString?: string) => ({
              pageSize: 10,
              filter: searchString?.trim() ?? undefined,
            })}
            makeFirstQueryVariable={(currentId?: number) => ({
              id: currentId,
            })}
            defaultInputString={currentBoostUpName}
          />
        )}
      </>
    </>
  );
};

const RenderMediaContent = () => {
  const { setValue, watch, getValues, reset } = useFormContext<any>();
  const mediaContentInput = watch('mediaContentInput');

  const { brandId } = useParams<BoostParams>();
  const { data } = useQuery(brandContentListQuery, {
    variables: { id: Number(brandId), visible: 'visible' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: brandId === 'new' ? 'cache-only' : 'network-only', // apollo-client 버그 walk-around
  });
  const mediaContentList: { id: number; sourceFileName: string; type: string }[] = data?.brandContentList?.list || [];
  const handleAddMediaContent = () => {
    setValue('mediaContentInput', [
      ...(mediaContentInput || []),
      {
        brandContentId: undefined,
        visibleFromDate: undefined,
        visibleFromTime: undefined,
        visibleToDate: undefined,
        visibleToTime: undefined,
      },
    ]);
  };

  const handleDelete = (idx: number) => {
    const values = getValues();
    reset({
      ...values,
      mediaContentInput: values.mediaContentInput.filter((item: any, itemIdx: number) => idx !== itemIdx),
    });
  };

  useEffect(() => {
    if (!mediaContentInput?.length) {
      setValue('mediaContentInput', [
        {
          brandContentId: undefined,
          visibleFromDate: undefined,
          visibleFromTime: undefined,
          visibleToDate: undefined,
          visibleToTime: undefined,
        },
      ]);
    }
  }, [mediaContentInput, setValue]);

  return (
    <>
      <Typography variant="subtitle2" color="textSecondary">
        미디어 콘텐츠 목록
      </Typography>
      <Box m={1} />
      {mediaContentInput?.map((mediaContent: any, idx: number) => (
        <Grid container spacing={1} alignItems="flex-end" key={`campaign-media-content-${idx}`}>
          <Grid item xs={1}>
            <Box style={{ padding: 4, textAlign: 'right' }}>
              <Typography variant="subtitle2" color="textSecondary">
                {idx + 1}:
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              autoComplete
              autoHighlight
              options={mediaContentList}
              getOptionLabel={option => `${option.sourceFileName} (${option.type.substr(0, 1)})`}
              value={mediaContentList?.find(item => item.id === mediaContent.brandContentId) || null}
              onChange={(e, value) => {
                setValue(`mediaContentInput.${idx}.brandContentId`, value?.id);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label="콘텐츠 선택"
                  variant="standard"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: styles.backgroundWhite,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <ChaiTextContextForm
              label="노출 시작"
              name={`mediaContentInput.${idx}.visibleFromDate`}
              type="date"
              margin="none"
              noHelperText
              required
            />
          </Grid>
          <Grid item xs={3}>
            <ChaiTextContextForm
              label=""
              name={`mediaContentInput.${idx}.visibleFromTime`}
              type="time"
              defaultValue="00:00"
              margin="none"
              noHelperText
              required
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              size="small"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                handleDelete(idx);
              }}
            >
              <Icon>clear</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <ChaiTextContextForm
              label={'노출 종료'}
              name={`mediaContentInput.${idx}.visibleToDate`}
              type="date"
              margin="none"
              noHelperText
              required
            />
          </Grid>
          <Grid item xs={3}>
            <ChaiTextContextForm
              label=""
              name={`mediaContentInput.${idx}.visibleToTime`}
              type="time"
              defaultValue="23:59"
              margin="none"
              noHelperText
              required
            />
          </Grid>
        </Grid>
      ))}
      <Box m={1} />
      <Button onClick={handleAddMediaContent} color="inherit" fullWidth variant="outlined">
        미디어 콘텐츠 추가
      </Button>
    </>
  );
};

const CampaignSummary = ({ values }: { values: any }) => (
  <>
    <Typography variant="subtitle2" color="textSecondary">
      부스트 캠페인 정보
    </Typography>
    <ChaiDivider />
    <Box m={2} />
    <Grid container spacing={6}>
      <Grid item>
        <Typography variant="subtitle2">부스트 캠페인 ID</Typography>
        <Box m={1} />
        <Typography>{values?.id}</Typography>
      </Grid>
    </Grid>
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Typography variant="subtitle2">타이틀*</Typography>
        <Box m={1} />
        <Typography>{values?.title}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2">설명</Typography>
        <Box m={1} />
        <Typography>{values?.description}</Typography>
      </Grid>
    </Grid>
    <Box m={6} />
    <Typography variant="subtitle2" color="textSecondary">
      캠페인 설명
    </Typography>
    <ChaiDivider />
    <Box m={2} />
    {!!values?.targetUserTags?.length && (
      <>
        <Typography variant="subtitle2">유저 태그</Typography>
        <Box m={1} />
        <Grid container spacing={6}>
          <Grid item>
            <ToggleButtonGroup exclusive aria-label="text alignment" value="normal">
              {values?.targetUserTags?.map((tag: string, idx: number) => (
                <ToggleButton
                  key={`boost-campaign-user-tag-preview-${idx}`}
                  value="normal"
                  aria-label="left aligned"
                  size="small"
                  className={styles.toggleButton}
                >
                  {tag}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </>
    )}
    <Box m={2} />
    <Typography variant="subtitle2">캠페인 총 예산</Typography>
    <Box m={1} />
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Typography variant="subtitle2">유저 1인당 1일 최대 부스트 구매 수</Typography>
        <Box m={1} />
        <Typography>하루에 {values?.dailyCountCapPerUser || '-'} 개</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2">유저 1인당 기간 내 최대 부스트 구매 수</Typography>
        <Box m={1} />
        <Typography>캠페인 내 {values?.totalCountCapPerUser || '-'} 개</Typography>
      </Grid>
    </Grid>
  </>
);
