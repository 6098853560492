import { Box, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { hasErrorFormProps } from 'util/formFieldProps';

export interface ChaiRadioFormValue {
  label: string;
  value: string | boolean;
}

export const ChaiRadioForm: React.FC<{
  context?: boolean;
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
  errors?: DeepMap<any, FieldError>;
  register?: any; // react-hook-form register
  values: ChaiRadioFormValue[];
  multiple?: boolean;
  labelMargin?: number;
}> = ({ context, name, label, register, required, values, multiple, labelMargin = 16 }) => {
  const {
    register: contextRegister,
    formState: { errors: contextErrors },
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  const value = getValues(name);
  const [formValue, setFormValue] = useState(value);
  let errorText = '';

  if (context && (!contextRegister || !contextErrors)) {
    throw new Error(`Form context load error: ${name}`);
  }

  if (context && name) {
    const { helperText } = hasErrorFormProps({ name, error: errors });
    if (helperText) {
      errorText = helperText;
    }
  }

  useEffect(() => {
    const registerProp = required ? { required } : {};
    context ? contextRegister(name, registerProp) : register(name, registerProp);
  }, [context, contextRegister, name, register, required]);

  useEffect(() => {
    setFormValue(value);
  }, [setFormValue, value]);

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      {errorText && (
        <Typography variant="caption" color="secondary">
          {errorText}
        </Typography>
      )}
      <Box style={{ height: labelMargin }} />
      <ToggleButtonGroup
        exclusive={multiple ? false : true}
        aria-label="text alignment"
        value={formValue}
        onChange={(_, format) => {
          // multiple 모드가 아닐 시 재선택 했을 때 버튼이 꺼지지 않게 합니다
          if (!multiple && format === null) {
            return;
          }
          setFormValue(format);
          setValue(name, format);
        }}
      >
        {values.map(({ value, label }, idx) => (
          <ToggleButton
            value={value}
            key={`chai-radio-${name}-${idx}`}
            aria-label="left aligned"
            size="small"
            style={styles.toggleButton}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Box m={1} />
    </>
  );
};

interface StylesDictionary {
  [Key: string]: React.CSSProperties;
}
const styles: StylesDictionary = {
  toggleButton: {
    marginRight: 8,
    marginLeft: 0,
    borderRadius: 16,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    height: 32,
    minWidth: 112,
  },
};
