import { Checkbox, FormControlLabel } from '@mui/material';

const ChaiCheckboxForm: React.FC<{
  name: string;
  label: string;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  defaultValue?: boolean;
  register?: any; // react-hook-form register
}> = ({ name, label, register, color = 'primary', defaultValue }) => {
  return (
    <FormControlLabel
      control={<Checkbox {...register(name)} color={color} name={name} defaultChecked={defaultValue} />}
      label={label}
    />
  );
};

export default ChaiCheckboxForm;
