import React, { useMemo } from 'react';
import TextEditor from 'react-quill';
// theme "snow" has a toolbar while "bubble" does not
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

interface IRange {
  index: number;
  length: number;
}

interface Quill {
  getSelection: () => IRange;
  insertEmbed: (range: IRange, type: string, content: string) => void;
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const ChaiRichTextEditor: React.FC<{
  theme?: string;
  style?: React.CSSProperties;
  value: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
}> = ({ theme = 'snow', style, value, onChange, readOnly = false }) => {
  const quillRef = React.useRef<TextEditor>();

  const imageUrlHandler = () => {
    if (!quillRef) {
      return;
    }
    const quillComponent: any = quillRef.current;
    const editor: Quill = quillComponent.getEditor();
    const range = editor.getSelection();
    const url = prompt('이미지 주소를 입력해주세요.');
    if (url) {
      editor.insertEmbed(range, 'image', url);
    }
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, 'bold', 'italic', 'underline', 'strike', 'blockquote', { color: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          image: imageUrlHandler,
        },
      },
    };
  }, []);

  return (
    <TextEditor
      ref={el => (el ? (quillRef.current = el) : null)}
      theme={theme}
      style={style}
      value={value.replaceAll('\n', '<br>')}
      modules={modules}
      formats={formats}
      onChange={onChange}
      readOnly={readOnly}
    />
  );
};
