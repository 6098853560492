import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  MenuItem,
  Typography,
} from '@mui/material';
import { Alert } from '@mui/lab';
import { ChaiCheckboxForm, ChaiSelectForm, ChaiTextForm } from 'common';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { INotificationData, INotificationMessage, NotificationTypeEnum } from 'type/api/notification';
import { defaultVariables } from '../constants';
import MessageKakaoButtonContainer from './MessageKakaoButtonContainer';

// 차이 앱 스킴 정리 문서
const CHAI_SCHEME_DOC =
  'https://www.notion.so/chaifinance/c82f311836df4caab371d04221ca85c5?v=cfd027aaa3344c61a32b7ac2a0aa9968';

const MessageEditDialogContainer: React.FC<{
  open: boolean;
  data?: INotificationData;
  editDialogTargetMessage?: INotificationMessage;
  handleUpdateMessage: (values: INotificationMessage) => void;
  handleClose: () => void;
}> = ({ data, editDialogTargetMessage, handleUpdateMessage, open, handleClose }) => {
  const {
    register,
    control: formControl,
    watch: formWatch,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm<INotificationMessage>();
  useFieldArray({
    control: formControl,
    name: 'buttons',
  });

  const [isFormReady, setIsFormReady] = useState(false);
  const messageType = formWatch('type');
  const buttons = formWatch('buttons');

  // dialog 열 때 내용 리프레시
  const onEnter = () => {
    reset({
      type: editDialogTargetMessage?.type,
      subject: editDialogTargetMessage?.subject,
      content: editDialogTargetMessage?.content,
      kakaoTitle: editDialogTargetMessage?.kakaoTitle,
      templateCode: editDialogTargetMessage?.templateCode,
      scheme: editDialogTargetMessage?.scheme,
      buttons: editDialogTargetMessage?.buttons,
      isAd: editDialogTargetMessage?.isAd,
    });
    clearErrors();
    setIsFormReady(true);
  };

  const onExited = () => {
    setIsFormReady(false);
  };

  const onSubmit = (message: INotificationMessage) => {
    if (validateMessageVariableValidation(message)) {
      return;
    }
    handleUpdateMessage({ ...message, buttons });
  };

  const validateMessageVariableValidation = (message: INotificationMessage) => {
    if (!data) {
      return;
    }

    const { subject, content, kakaoTitle } = message;
    const { variables } = data;
    return (
      validateVariable(variables, 'subject', subject) ||
      validateVariable(variables, 'content', content) ||
      validateVariable(variables, 'kakaoTitle', kakaoTitle)
    );
  };

  // 입력한 텍스트에 포함되어있는 변수 유효성 체크
  const validateVariable = (
    variable: string[],
    key: 'subject' | 'content' | 'kakaoTitle',
    targetString: string | undefined
  ) => {
    if (!targetString) {
      return false;
    }
    const reg = /#{([^}]+)}/g;
    const matches = Array.from(targetString.matchAll(reg)).map(match => match[1]);
    const errors = matches?.filter(val => !variable.concat(defaultVariables).includes(val));
    if (errors.length > 0) {
      setError(
        key,
        {
          message: `잘못 입력한 변수가 포함되어 있습니다: #{${errors[0]}}`,
        },
        {
          shouldFocus: true,
        }
      );
      return true;
    }
    return false;
  };

  // TODO: 백엔드 구현과 어드민 메세지 수정 구현이 다른 방향이라 수정해야함
  // isCommon 값에 따라 messageType도 공통 하나만 나와야 합니다(메세지 추가/삭제 불필요)
  // NotifiactionEntity.isCommon이 true 이면 NotificationMessageEntity.type은 common 하나로 고정 (fcm, kakao, sms 선택 불가)
  // NotifiactionEntity.isCommon이 false 이면 NotificationMessageEntity.type은 fcm, kakao, sms중에 하나 이상(commom 선택 불가)
  // 메세지를 추가/삭제하는 형태가 아니라 그 위에 전송 방법의 활성화 상태에 따라 해당하는 메세지가 보여야 하는 구조가 되어야 합니다(지금은 동일한 타입을 여러번 추가를 할 수 있는 것처럼 만들어져있네요)

  return (
    <Dialog
      maxWidth="md"
      open={open}
      TransitionProps={{
        onEnter: onEnter,
        onExited: onExited,
      }}
    >
      <DialogTitle>메세지 수정</DialogTitle>
      <DialogContent>
        {!data && <Alert severity="error">데이터가 없습니다</Alert>}
        {isFormReady && (
          <>
            <ChaiSelectForm control={formControl} name="type" label="메세지 타입">
              <MenuItem value={NotificationTypeEnum.COMMON}>공통 메세지</MenuItem>
              <MenuItem value={NotificationTypeEnum.FCM}>푸시알림</MenuItem>
              <MenuItem value={NotificationTypeEnum.KAKAO}>카카오</MenuItem>
              <MenuItem value={NotificationTypeEnum.SMS}>문자</MenuItem>
            </ChaiSelectForm>
            {/* 변수 (읽기전용) */}
            <ChaiTextForm
              name="variables"
              label="변수"
              defaultValue={['fullname', ...(data?.variables || [])].join(', ')}
              disabled
            />
            <DialogContentText>*변수는 {'#{변수}'} 형태로 제목/내용에 사용 가능합니다.</DialogContentText>
            {/* 제목 */}
            <ChaiTextForm register={register} errors={errors} name="subject" label="제목" required multiline />
            {/* 내용 */}
            <ChaiTextForm register={register} errors={errors} name="content" label="내용" required multiline />
            {/* 앱 스킴 - FCM 타입에서만 표시 */}
            {[NotificationTypeEnum.COMMON, NotificationTypeEnum.FCM].includes(messageType) && (
              <ChaiTextForm register={register} errors={errors} name="scheme" label="앱 스킴" />
            )}
            {/* 광고 여부 체크박스 - SMS 타입에서만 표시 */}
            {[NotificationTypeEnum.COMMON, NotificationTypeEnum.SMS].includes(messageType) && (
              <ChaiCheckboxForm
                register={register}
                name="isAd"
                label="광고 여부"
                defaultValue={editDialogTargetMessage?.isAd}
              />
            )}
            {/* 카카오 제목, 템플릿 코드, 카카오 버튼 - 카카오 타입에서만 표시 */}
            {[NotificationTypeEnum.COMMON, NotificationTypeEnum.KAKAO].includes(messageType) && (
              <>
                <ChaiTextForm register={register} errors={errors} name="kakaoTitle" label="카카오 제목 (선택)" />
                <ChaiTextForm register={register} errors={errors} name="templateCode" label="템플릿 코드" />
                <MessageKakaoButtonContainer buttons={buttons} setValue={setValue} />
              </>
            )}
            {/* 앱 스킴 설명 - FCM, 카카오 타입에서만 표시 */}
            {[NotificationTypeEnum.COMMON, NotificationTypeEnum.FCM, NotificationTypeEnum.KAKAO].includes(
              messageType
            ) && (
              <Typography align="right">
                *스킴은 차이 앱으로 연결할 때 사용합니다.{' '}
                <Link href={CHAI_SCHEME_DOC} target="_blank" rel="noreferrer">
                  [문서]
                </Link>
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="inherit">
          취소
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary">
          적용
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageEditDialogContainer;
