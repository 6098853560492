import { Icon, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { hasErrorFormProps } from 'util/formFieldProps';

interface IChaiTextForm {
  name?: string;
  label?: string;
  type?: string;
  min?: number;
  max?: number;
  value?: string | number;
  defaultValue?: string | number | Date;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  multiline?: boolean;
  shrink?: boolean;
  margin?: any;
  size?: 'small' | 'medium' | undefined;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  maxRows?: number;
  inputProps?: any;
  startAdornment?: any;
  endAdornment?: any;
  noHelperText?: boolean;
}

interface IChaiTextFormWithRegister extends IChaiTextForm {
  register?: any; // react-hook-form register
  errors?: DeepMap<any, FieldError>;
}

export const ChaiTextContextForm: React.FC<IChaiTextForm> = ({
  name,
  label,
  type,
  value = '',
  defaultValue,
  placeholder,
  inputProps = {},
  required = false,
  disabled = false,
  readOnly = false,
  multiline = false,
  margin = 'dense',
  min,
  max,
  size,
  variant = 'standard',
  maxRows,
  startAdornment,
  endAdornment,
  noHelperText,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<any>();
  if (!register || !errors) {
    throw new Error(`Form context load error: ${name}`);
  }

  return (
    <ChaiTextForm
      name={name}
      type={type}
      register={register}
      errors={errors}
      margin={margin}
      required={required}
      disabled={disabled}
      multiline={multiline}
      maxRows={maxRows}
      placeholder={placeholder}
      defaultValue={defaultValue}
      size={size}
      min={min}
      max={max}
      variant={variant}
      label={label}
      value={value}
      inputProps={inputProps}
      readOnly={readOnly}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      noHelperText={noHelperText}
    />
  );
};

export const ChaiTextForm: React.FC<IChaiTextFormWithRegister> = ({
  name,
  label,
  type,
  register,
  value = null,
  defaultValue,
  placeholder,
  inputProps = {},
  errors,
  required = false,
  disabled = false,
  readOnly = false,
  multiline = false,
  margin = 'dense',
  size,
  variant = 'standard',
  maxRows,
  startAdornment,
  endAdornment,
  noHelperText,
  min,
  max,
}) => {
  const registerRef: { required?: boolean; valueAsNumber?: boolean; pattern?: RegExp; min?: number; max?: number } = {};
  if (required) {
    registerRef.required = true;
  }
  if (type === 'number') {
    registerRef.valueAsNumber = true;
    registerRef.min = min;
    registerRef.max = max;
  }
  if (type === 'time') {
    // 시간 입력
    registerRef.pattern = /^[0-9]{2,4}:[0-5][0-9]$/;
  }
  if (type === 'date') {
    registerRef.pattern = /^[0-9]{4}-[0-1][0-9]-[0-3][0-9]$/;
  }
  const inputRef = register && register(name, registerRef);
  let additionalProps: any = {
    value: value || undefined,
  };

  if (name) {
    additionalProps = {
      name,
      label: label === '' ? ' ' : label, // 라벨이 없어서 폼이 밀리는 현상 방지
      ...hasErrorFormProps({ name, error: errors }),
      ...additionalProps,
    };
    if (noHelperText) {
      additionalProps.helperText = undefined;
    }
  } else {
    additionalProps = {
      label: label === '' ? ' ' : label, // 라벨이 없어서 폼이 밀리는 현상 방지
      ...additionalProps,
      value: value || '', // name이 없는 주어지는 값만 사용하는 텍스트필드일 때, value까지 없으면 uncontrolled component가 되는것을 방지
    };
  }

  if (type === 'time') {
    return (
      <TextField
        fullWidth
        type="text"
        InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }}
        {...inputRef}
        {...{
          placeholder,
          multiline,
          maxRows,
          margin,
          size,
          variant,
          InputProps: {
            startAdornment,
            endAdornment: (
              <InputAdornment position="end">
                <Icon style={{ fontSize: 20 }}>access_time</Icon>
              </InputAdornment>
            ),
            readOnly,
            ...inputProps,
          },
          required: !!label && label.length > 0 && required,
          disabled,
          defaultValue,
          ...additionalProps,
        }}
      />
    );
  }

  return (
    <TextField
      {...inputRef}
      fullWidth
      type={type}
      margin={margin}
      required={!!label && label.length > 0 && required}
      disabled={disabled}
      multiline={multiline}
      maxRows={maxRows}
      placeholder={placeholder}
      defaultValue={defaultValue}
      size={size}
      variant={variant}
      InputLabelProps={{ shrink: true, style: { whiteSpace: 'nowrap' } }}
      inputProps={{
        max: '9999-12-31',
      }}
      InputProps={{
        startAdornment,
        endAdornment,
        readOnly,
        ...inputProps,
      }}
      {...additionalProps}
    />
  );
};

export default ChaiTextForm;
