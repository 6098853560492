export enum BoostTagLogicalType {
  OR = 'or',
  AND = 'and',
}
export enum BoostTagComparisonType {
  EQUAL = 'equal',
  INCLUDE = 'include',
  NOTEQUAL = 'notEqual',
  NOTINCLUDE = 'notInclude',
}
