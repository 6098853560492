import { DocumentNode, useLazyQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IGraphListData } from 'type/common';

interface IComponentProps<T> {
  name: string; // form name
  label?: string; // form label
  query: DocumentNode; // GQL query
  multiple?: boolean; // 다중 선택
  variant?: 'standard' | 'filled' | 'outlined';
  getId?: (item: T) => string | number; // 폼에서 value값으로 사용할 id
  getName?: (item: T) => string; // 폼에서 리스트에 출력할 이름 값
  getLabel?: (item: T) => string; // 선택 완료한 항목을 출력할 때 사용할 라벨 값
  makeQueryVariable?: (searchString?: string) => object; // 검색 입력값으로 쿼리에 사용할 필터 생성
  makeFirstQueryVariable?: (currentId?: number) => object; // 첫 폼 로드에 사용할 쿼리에 사용할 필터 생성
  defaultInputString?: string; // 초기 값 검색을 위한 defualt value
}

/*
 * add readme
 */
export const ChaiAutoCompleteList = <T extends { id?: string | number; name?: string }>({
  name,
  query,
  label,
  multiple = false,
  variant = 'standard',
  defaultInputString = '',
  getId = item => item?.id || 'unknown ID',
  getLabel = item => item?.name || 'unknown label',
  getName = item => item?.name || 'unknown Name',
  makeQueryVariable = () => ({ pageSize: 10 }),
  makeFirstQueryVariable,
}: IComponentProps<T> & { children?: ReactNode }) => {
  const { watch, setValue } = useFormContext();
  const queryCalled = useRef(false);
  const [inputStringValue, setInputStringValue] = useState<string>(defaultInputString || ''); // undefined, null 입력 시 문제 발생
  const [defaultValue, setDefaultValue] = useState<T | null>();
  const [getAutocompleteList, { loading }] = useLazyQuery<IGraphListData<T>>(query, {
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      // 데이터 로드 시 리스트 갱신
      // graph-server 쪽에서 사용하는 공통 구조에서 리스트를 불러옵니다
      // 예) result.queryName { list: [], total: 123 }
      const list: T[] = data ? Object.entries(data)?.[0][1]?.list || [] : [];
      if (data && list.length > 0) {
        setOptions(list);
      }
      if (!queryCalled.current) {
        queryCalled.current = true;
        setDefaultValue(list.find(item => getId(item) === currentId) || null);
      }
    },
  });
  const [options, setOptions] = useState<T[]>([]);
  const currentId = watch(name) ?? null;

  // 최초 1회만 로드
  useEffect(() => {
    if (queryCalled.current) {
      return;
    }
    getAutocompleteList({
      variables: makeFirstQueryVariable ? makeFirstQueryVariable(currentId) : makeQueryVariable(inputStringValue),
    });
  }, [getAutocompleteList, inputStringValue, makeQueryVariable, makeFirstQueryVariable, currentId]);

  // 외부에서 값을 바꿨을 시 핸들링
  useEffect(() => {
    if (!queryCalled.current || !makeFirstQueryVariable) {
      return;
    }
    getAutocompleteList({
      variables: makeFirstQueryVariable(currentId),
    });
  }, [getAutocompleteList, inputStringValue, makeQueryVariable, currentId, makeFirstQueryVariable]);

  // 검색 텍스트 변경 시 데이터 로드
  useEffect(() => {
    if (!queryCalled.current) {
      return;
    }
    getAutocompleteList({
      variables: makeQueryVariable(inputStringValue),
    });
  }, [getAutocompleteList, inputStringValue, makeQueryVariable]);

  if (!queryCalled.current) {
    return (
      <TextField
        fullWidth
        disabled
        value=""
        label={label}
        size="small"
        variant={variant}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CircularProgress color="inherit" size="1em" />
            </InputAdornment>
          ),
          style: {
            backgroundColor: 'white',
          },
        }}
      />
    );
  }

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      multiple={multiple}
      options={options}
      isOptionEqualToValue={(option, value) => getId(option) === getId(value)}
      getOptionLabel={option => getLabel(option)}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getName(option)}
        </li>
      )}
      onChange={(_, value) => {
        if (!value) {
          setValue(name, undefined);
        } else if (Array.isArray(value)) {
          setValue(name, value.map(getId));
        } else {
          setValue(name, getId(value));
        }
      }}
      defaultValue={defaultValue}
      inputValue={inputStringValue}
      onInputChange={(_, value) => {
        if (!queryCalled.current) {
          // 초기 로드가 일어나기 전에 쿼리 중첩 시도 방지
          return;
        }
        setInputStringValue(value);
      }}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          value=""
          label={label}
          size="small"
          variant={variant}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            style: {
              backgroundColor: 'white',
            },
          }}
        />
      )}
    />
  );
};
