import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { boostTagAddMutate, boostTagUpdateMutate, boostTargetConditionQuery } from 'api/boostTagGql';
import { ChaiTextForm } from 'common';
import { useAlert } from 'common/context/alert';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { BoostTagContent } from './BoostTagDialogContent';
import { store as reducers } from 'reducers';
import { addError, addSuccess } from 'state/notistackState';

export enum BoostTagType {
  SINGLE = 'single',
  GROUP = 'group',
}

export enum BoostTagLogicalType {
  OR = 'or',
  AND = 'and',
}

export interface BoostTagCondition extends BoostTagSubCondition {
  subOperator?: BoostTagLogicalType;
  subConditions?: BoostTagSubCondition[];
}

export interface BoostTagSubCondition {
  type: BoostTagType;
  value?: number;
  hasProductIdCondition?: boolean;
}

export interface BoostTagForm {
  title?: string;
  description?: string;
  rootOperator: BoostTagLogicalType;
  conditions: BoostTagCondition[];
}

export const BoostTagDialog: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory();
  const [title, setTitle] = React.useState('');
  const methods = useForm<BoostTagForm>({
    shouldUnregister: false,
  });
  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
  } = methods;
  const alert = useAlert();
  const formValues = watch();
  const [getBoostTag, { data }] = useLazyQuery(boostTargetConditionQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { id: Number(id) },
    fetchPolicy: id === 'new' ? 'cache-only' : 'network-only',
  });

  useEffect(() => {
    if (id !== 'new') {
      setTitle('부스트 태그 설정');
      getBoostTag();
    } else {
      setTitle('부스트 태그 추가');
      reset({
        rootOperator: BoostTagLogicalType.AND,
        conditions: [{ type: BoostTagType.SINGLE }],
      });
    }
  }, [id, setTitle, reset, getBoostTag]);

  useEffect(() => {
    if (data?.boostTargetCondition) {
      const conditionData = data?.boostTargetCondition;
      const rootOperator = Object.keys(conditionData.condition)[0] as BoostTagLogicalType;
      const conditions = conditionData.condition[rootOperator].map((item: any) => {
        if (!item) {
          return { type: BoostTagType.SINGLE };
        }
        if (typeof item === 'object') {
          const subOperator = Object.keys(item)[0] as BoostTagLogicalType;
          // group item
          return {
            type: BoostTagType.GROUP,
            subOperator,
            subConditions: item[subOperator].map((subItem: number) => ({ type: BoostTagType.SINGLE, value: subItem })),
          };
        } else {
          return { type: BoostTagType.SINGLE, value: item };
        }
      });

      reset({
        title: conditionData.title,
        description: conditionData.description,
        rootOperator,
        conditions,
      });
    }
  }, [data, reset]);

  const handleClose = () => {
    history.push('/boost/boostTag/');
  };

  const [addOrUpdateBoostTag, { loading }] = useMutation(id === 'new' ? boostTagAddMutate : boostTagUpdateMutate, {
    onCompleted: () => {
      reducers.dispatch(addSuccess(`부스트 태그가 업데이트 되었습니다.`));
      handleClose();
    },
    onError: err => {
      reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
      handleClose();
    },
  });

  const onSubmit = async () => {
    const { rootOperator, conditions, title, description } = getValues();
    const hasProductIdCondition: boolean[] = [];
    if (hasProductIdCondition.includes(true) && hasProductIdCondition.includes(false)) {
      alert.open('오류', 'PID조건이 포함된 결제태그는 다른 결제태그와 중복선택할 수 없습니다.');
      return false;
    }

    const payload = {
      [rootOperator]: conditions?.map(condition => {
        if (condition.subOperator && condition.subConditions) {
          return {
            [condition.subOperator]: condition.subConditions.map(subCondition => {
              hasProductIdCondition.push(subCondition.hasProductIdCondition || false);
              return subCondition.value;
            }),
          };
        } else {
          hasProductIdCondition.push(condition.hasProductIdCondition || false);
        }
        return condition.value;
      }),
    };

    addOrUpdateBoostTag({
      variables: {
        id: id === 'new' ? undefined : Number(id),
        condition: payload,
        description,
        title,
      },
    });
  };

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ChaiTextForm register={register} errors={errors} label="이름" name="title" shrink required />
        <ChaiTextForm register={register} errors={errors} label="설명" name="description" shrink multiline required />
        <Box m={2} />
        <Typography variant="subtitle2" color="textSecondary">
          조건
        </Typography>
        <Box m={2} />
        <FormProvider {...methods}>
          <BoostTagContent isEdit={true} content={formValues} />
        </FormProvider>
        <Box m={2} />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button onClick={() => handleClose()} color="inherit">
              취소
            </Button>
            <Button disabled={loading} onClick={handleSubmit(onSubmit)} color="primary">
              저장
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
