import { Grid, TableCell, TableRow } from '@mui/material';
import { ChaiListTable, ChaiListTableColumn } from 'common';
import React from 'react';
import { useEffect } from 'react';
import ErrorIcon from '@mui/icons-material/Error';

// Array 를 받아와서 테이블로 표시해줍니다.
export const ChaiSimpleListTable: React.FC<{
  name: string;
  columns: ChaiListTableColumn[];
  headerName?: string;
  hideHeader?: boolean;
  tableOnly?: boolean;
  size?: 'small' | 'medium' | undefined;
  list: any[];
  rowsPerPageOptions?: number[];
}> = ({
  name,
  columns,
  hideHeader = false,
  tableOnly = false,
  headerName,
  size,
  list = [],
  rowsPerPageOptions = [20, 50, 100],
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const filteredList = searchKeyword
    ? list.filter(item => {
        const searchTarget = columns.filter(column => column.searchTarget);
        for (let i = 0; i < searchTarget.length; i += 1) {
          if (item[searchTarget[i].id]?.indexOf(searchKeyword) > -1) {
            return true;
          }
        }
        return false;
      })
    : list;
  const slicedList = filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  useEffect(() => {
    setPage(0);
  }, [searchKeyword, setPage]);

  return (
    <ChaiListTable
      total={filteredList.length}
      hideTotal={tableOnly}
      hidePagenation={tableOnly}
      columns={columns}
      rowsPerPage={rowsPerPage}
      page={page}
      headerName={headerName}
      hideHeader={hideHeader || tableOnly}
      size={size}
      handleOnChangeSearch={handleOnChangeSearch}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    >
      {slicedList.map(row => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={`${name}-row-${row.id}`}>
            {columns.map(column => {
              const value = row[column.id];
              return (
                <TableCell key={column.id} align={column.align}>
                  {column.format ? column.format(value, row) : value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
      {slicedList.length === 0 && (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <ErrorIcon fontSize="small" />
              &nbsp;데이터가 없습니다.
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </ChaiListTable>
  );
};
