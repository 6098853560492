import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { hasErrorFormProps } from 'util/formFieldProps';
import styles from '../../Boost/boost.module.scss';
import { ICategoryTag } from './CategoryTagDialog';

export const CategoryTagInput: React.FC<{
  paymentTagList: any;
  namePrefix: `paymentTagIds.${number}`;
}> = ({ paymentTagList, namePrefix }) => (
  <PaymentTagAutocomplete placeholder="결제 태그 입력" list={paymentTagList} namePrefix={namePrefix} />
);

const PaymentTagAutocomplete: React.FC<{
  list: any[];
  namePrefix: `paymentTagIds.${number}`;
  placeholder: string;
}> = ({ list, namePrefix, placeholder }) => {
  const {
    setValue,
    clearErrors,
    formState: { errors },
    watch,
    register,
  } = useFormContext<ICategoryTag>();
  const inputValue = watch(`${namePrefix}.id`);
  const [inputStringValue, setInputStringValue] = React.useState<string>();
  const value = list?.find(item => item.id === inputValue) || null;
  const errorProps = hasErrorFormProps({ error: errors, name: `${namePrefix}.id` });
  register(`${namePrefix}.id`, { required: true });

  return (
    <Autocomplete
      options={list?.slice().sort((a, b) => a.name.localeCompare(b.name)) || []}
      autoComplete
      autoHighlight
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => {
        return `${option.name}` || `(이름 없음) (${option.id})`;
      }}
      filterOptions={options => {
        if (!inputStringValue?.length) {
          return options;
        }
        return options.filter(
          option => `${option.name}(${option.id})`.replace(/\s/g, '').indexOf(inputStringValue.replace(/\s/g, '')) > -1
        );
      }}
      onChange={(e, value) => {
        clearErrors(`${namePrefix}.id`);
        setValue(`${namePrefix}.id`, value?.id);
        setValue(`${namePrefix}.name`, value?.name);
      }}
      onInputChange={(e, value) => {
        setInputStringValue(value);
      }}
      value={value}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          name={namePrefix}
          fullWidth
          placeholder={placeholder}
          size="small"
          variant="outlined"
          {...errorProps}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: styles.backgroundWhite,
            },
          }}
        />
      )}
    />
  );
};
