import { useQuery } from '@apollo/client';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { getBoostImportantQuery } from 'api/boostImportantGql';
import { ChaiTextForm } from 'common';
import ChaiDivider from 'common/component/ChaiDivider';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const defaultImportant = {
  type: '',
  title: '',
  content: '',
  priority: 0,
};

export const BoostRequiredImportantDialog: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory();
  const location = useLocation();

  const [boostImportant, setBoostImportant] = useState(defaultImportant);

  const { data } = useQuery(getBoostImportantQuery, {
    variables: {
      sortKey: id + decodeURI(location.hash),
      table: 'importantRequired',
    },
  });

  useEffect(() => {
    if (data) {
      setBoostImportant(data.boostImportant);
    }
  }, [data]);

  const handleClose = () => {
    history.push('/important/required/');
  };

  return (
    <>
      <DialogTitle>필수 유의사항</DialogTitle>
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <ChaiTextForm label="구분자" value={boostImportant?.type} readOnly />
          </Grid>
          <Grid item xs={4}>
            <ChaiTextForm label="유의사항 모듈 타이틀" value={boostImportant?.title} readOnly />
          </Grid>
          <Grid item xs={4}>
            <ChaiTextForm label="우선순위" value={boostImportant?.priority} readOnly type="number" />
          </Grid>
        </Grid>
        <Box m={2} />
        <Typography variant="caption" color="textSecondary">
          유의사항
        </Typography>
        <ChaiDivider />
        <Box style={{ height: '300px' }}>
          <TextField fullWidth multiline minRows="12" value={boostImportant?.content || ''} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button onClick={() => handleClose()}>닫기</Button>
        </Grid>
      </DialogActions>
    </>
  );
};
