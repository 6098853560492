import React from 'react';
import { useParams } from 'react-router-dom';
import { BoostCalendarListContainer } from './BoostCalendarListContainer';

export const BoostCalendarDayContainer: React.FC<{}> = props => {
  const { date } = useParams<{ date: string }>();

  return (
    <>
      <BoostCalendarListContainer dateType="day" date={date} />
    </>
  );
};
