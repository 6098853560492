import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BoostUpListContainer } from './BoostUpListContainer';

export const BoostUp: React.FC<{}> = () => {
  return (
    <>
      <Switch>
        <Route path="/boost/up/common-description" render={() => <BoostUpListContainer />} />
        <Route path="/boost/up/important" render={() => <BoostUpListContainer />} />
        <Route path="/boost/up/:upId" render={() => <BoostUpListContainer />} />
        <Route path="/" render={() => <BoostUpListContainer />} />
      </Switch>
    </>
  );
};
