import gql from 'graphql-tag';

export const boostListQuery = gql`
  query boostPromotionList($skip: Int, $pageSize: Int, $filter: String) {
    boostPromotionList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        name
        title
        subTitle
        promotion {
          createdAt
          updatedAt
          discount
          priceMin
          maxDiscountAmount
        }
        type
        boltPrice
        status
      }
      total
    }
  }
`;

export const boostQuery = gql`
  query boostPromotion($id: Int!) {
    boostPromotion(id: $id) {
      id
      name
      title
      subTitle
      description
      countCap
      subPromotions {
        discount
        maxDiscountAmount
        boltPrice
        promotionId
      }
      promotion {
        createdAt
        updatedAt
        discount
        priceMin
        priceMax
        maxDiscountAmount
      }
      type
      boltPrice
      status
      benefitDescription
      important
      boostTargetConditionId
      landingUrl
      fallbackUrl
      iosAppId
      androidPackage
      logoImageUrl
      typoImageUrl
      onOffLineStatus
    }
  }
`;

export const boostAddMutation = gql`
  mutation boostPromotionAdd(
    $type: String!
    $title: String!
    $name: String!
    $subTitle: String!
    $description: String!
    $important: String
    $usableLocationDescription: String
    $status: String!
    $taglines: [String]
    $brandId: Int!
    $buyableFrom: Date
    $buyableTo: Date
    $usableFrom: Date
    $usableTo: Date
    $visibleFrom: Date
    $visibleTo: Date
    $boltPrice: Int!
    $amountCap: Int
    $countCap: Int
    $landingUrl: String
    $fallbackUrl: String
    $iosAppId: String
    $androidPackage: String
    $benefitType: String
    $discount: String
    $maxDiscountAmount: Int
    $priceMin: Int
    $priceMax: Int
    $targetMerchantIds: JSON
    $existingPolicyId: String
    $promotionType: String
    $discountAmountMax: String
    $discountAmountMin: String
    $countCapPerUser: Int
    $subPromotions: [SubPromotionInput]
    $benefitDescription: String
    $tutorialType: String
    $boostMerchantIds: [String]
    $boostCategoryIds: [Int]
    $logoImageUrl: String
    $typoImageUrl: String
    $onOffLineStatus: String
    $boostTargetConditionId: Int
  ) {
    boostPromotionAdd(
      type: $type
      title: $title
      name: $name
      subTitle: $subTitle
      description: $description
      important: $important
      usableLocationDescription: $usableLocationDescription
      status: $status
      taglines: $taglines
      brandId: $brandId
      buyableFrom: $buyableFrom
      buyableTo: $buyableTo
      usableFrom: $usableFrom
      usableTo: $usableTo
      visibleFrom: $visibleFrom
      visibleTo: $visibleTo
      boltPrice: $boltPrice
      amountCap: $amountCap
      countCap: $countCap
      landingUrl: $landingUrl
      fallbackUrl: $fallbackUrl
      iosAppId: $iosAppId
      androidPackage: $androidPackage
      benefitType: $benefitType
      discount: $discount
      maxDiscountAmount: $maxDiscountAmount
      priceMin: $priceMin
      priceMax: $priceMax
      targetMerchantIds: $targetMerchantIds
      existingPolicyId: $existingPolicyId
      promotionType: $promotionType
      discountAmountMax: $discountAmountMax
      discountAmountMin: $discountAmountMin
      countCapPerUser: $countCapPerUser
      subPromotions: $subPromotions
      benefitDescription: $benefitDescription
      tutorialType: $tutorialType
      boostMerchantIds: $boostMerchantIds
      boostCategoryIds: $boostCategoryIds
      logoImageUrl: $logoImageUrl
      typoImageUrl: $typoImageUrl
      onOffLineStatus: $onOffLineStatus
      boostTargetConditionId: $boostTargetConditionId
    )
  }
`;

export const boostUpdateMutation = gql`
  mutation boostPromotionUpdate(
    $id: Int!
    $type: String!
    $name: String
    $title: String!
    $subTitle: String!
    $description: String!
    $important: String
    $usableLocationDescription: String
    $status: String!
    $taglines: [String]
    $brandId: Int!
    $buyableFrom: Date
    $buyableTo: Date
    $usableFrom: Date
    $usableTo: Date
    $visibleFrom: Date
    $visibleTo: Date
    $boltPrice: Int!
    $amountCap: Int
    $countCap: Int
    $landingUrl: String
    $fallbackUrl: String
    $iosAppId: String
    $androidPackage: String
    $benefitType: String
    $discount: String
    $maxDiscountAmount: Int
    $priceMin: Int
    $priceMax: Int
    $targetMerchantIds: JSON
    $existingPolicyId: String
    $promotionType: String
    $discountAmountMax: String
    $discountAmountMin: String
    $countCapPerUser: Int
    $subPromotions: [SubPromotionInput]
    $benefitDescription: String
    $tutorialType: String
    $boostMerchantIds: [String]
    $boostCategoryIds: [Int]
    $logoImageUrl: String
    $typoImageUrl: String
    $onOffLineStatus: String
    $boostTargetConditionId: Int
    $grade: String
  ) {
    boostPromotionUpdate(
      id: $id
      type: $type
      name: $name
      title: $title
      subTitle: $subTitle
      description: $description
      important: $important
      usableLocationDescription: $usableLocationDescription
      status: $status
      taglines: $taglines
      brandId: $brandId
      buyableFrom: $buyableFrom
      buyableTo: $buyableTo
      usableFrom: $usableFrom
      usableTo: $usableTo
      visibleFrom: $visibleFrom
      visibleTo: $visibleTo
      boltPrice: $boltPrice
      amountCap: $amountCap
      countCap: $countCap
      landingUrl: $landingUrl
      fallbackUrl: $fallbackUrl
      iosAppId: $iosAppId
      androidPackage: $androidPackage
      benefitType: $benefitType
      discount: $discount
      maxDiscountAmount: $maxDiscountAmount
      priceMin: $priceMin
      priceMax: $priceMax
      targetMerchantIds: $targetMerchantIds
      existingPolicyId: $existingPolicyId
      promotionType: $promotionType
      discountAmountMax: $discountAmountMax
      discountAmountMin: $discountAmountMin
      countCapPerUser: $countCapPerUser
      subPromotions: $subPromotions
      benefitDescription: $benefitDescription
      tutorialType: $tutorialType
      boostMerchantIds: $boostMerchantIds
      boostCategoryIds: $boostCategoryIds
      logoImageUrl: $logoImageUrl
      typoImageUrl: $typoImageUrl
      onOffLineStatus: $onOffLineStatus
      boostTargetConditionId: $boostTargetConditionId
      grade: $grade
    )
  }
`;

export interface IBoostPromotion {
  id: number;
  boostName: string;
  title: string;
  subTitle: string;
  type: string;
  boltPrice: number;
  discountType: 'fixed' | 'percent';
  status: string;
  promotion: {
    createdAt: Date;
    updatedAt: Date;
    discount: number;
    priceMin: number;
    priceMax: number;
    maxDiscountAmount: number;
  };
  subPromotions?: IBoostSubPromotionInput[];
}

export interface IBoostSubPromotionInput {
  discount: string;
  maxDiscountAmount: number;
  boltPrice: number;
  promotionId: string;
  sequence: number;
}

export interface IBoostPromotionListItem {
  id: string;
  name: string;
  title: string;
  subTitle: string;
  type: string;
  boltPrice: number;
  status: string;
  promotion: {
    createdAt: Date;
    updatedAt: Date;
    discount: number;
    priceMin: number;
    maxDiscountAmount: number;
  };
}

export interface IBoostList {
  list: [IBoostPromotionListItem];
  total: number;
}
