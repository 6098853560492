import { useLazyQuery } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { boostUpAddMutation, boostUpUpdateMutation, getBoostUpQuery } from 'api/boostUpGql';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { store as reducers } from 'reducers';
import { addError, addSuccess } from 'state/notistackState';
import { BoostUpPolicyStatus, IBoostUpForm, IUpdateBoostUpInput } from 'type/boost/boostUp';
import apolloClient from 'util/apolloClient';
import { BoostUpDialogContent } from './BoostUpDialogContent';

const FORM_INITIAL: Partial<IBoostUpForm> = {
  title: '',
  status: BoostUpPolicyStatus.ENABLED,
  visibleDateSet: 'enabled',
  visibleFromDate: format(new Date(), 'yyyy-MM-dd'),
  visibleFromTime: '00:00',
  visibleToDate: format(new Date(), 'yyyy-MM-dd'),
  visibleToTime: '23:59',
  startAt: '00:00',
  endAt: '23:59',
  targetTypes: ['red', 'credit_card', 'e_wallet'],
  boostUpSchemePolicies: [
    {
      id: '0',
      probability: 0,
      cashbackAmountMin: 0,
      cashbackAmountMax: 0,
      cashbackAmountUnit: 0,
      discountRate: 0,
      resultType: 'jackpot',
      level: 1,
    },
    {
      id: '1',
      probability: 0,
      cashbackAmountMin: 0,
      cashbackAmountMax: 0,
      cashbackAmountUnit: 0,
      discountRate: 0,
      resultType: 'miss',
      level: 1,
    },
  ],
};

export const BoostUpDialog: React.FC<{ id: string; onClose: () => void }> = ({ id, onClose }) => {
  const form = useForm<IBoostUpForm>({ shouldUnregister: false });
  const { reset, handleSubmit } = form;
  const [getBoostUp, { data }] = useLazyQuery(getBoostUpQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { id: Number(id) },
    fetchPolicy: id === 'new' ? 'cache-only' : 'network-only',
  });

  const [title, setTitle] = React.useState('');

  useEffect(() => {
    if (id !== 'new') {
      getBoostUp();
    } else {
      setTitle('부스트 강화 추가');
      reset(FORM_INITIAL);
    }
  }, [id, setTitle, reset, getBoostUp]);

  useEffect(() => {
    const boostUpData = data?.boostUp;
    if (boostUpData) {
      const {
        possibleMoments,
        title,
        description,
        budget,
        dailyBudget,
        status,
        boltPrice,
        boostUpSchemePolicies,
        startAt,
        endAt,
        visibleFrom,
        visibleTo,
        targetTypes,
      } = boostUpData;
      setTitle(`${boostUpData.title} (${id})`);

      reset({
        possibleMoments,
        title,
        description,
        budget,
        dailyBudget,
        status,
        boltPrice,
        boostUpSchemePolicies: boostUpSchemePolicies.map((item: any, idx: number) => ({
          id: `${idx}`,
          ...item,
        })),
        visibleDateSet: visibleFrom && visibleTo ? 'enabled' : 'disabled',
        visibleFromDate: visibleFrom ? format(new Date(visibleFrom), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        visibleFromTime: visibleFrom ? format(new Date(visibleFrom), 'HH:mm') : '00:00',
        visibleToDate: visibleTo ? format(new Date(visibleTo), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        visibleToTime: visibleTo ? format(new Date(visibleTo), 'HH:mm') : '23:59',
        targetTypes,
        startAt,
        endAt,
      });
    }
  }, [data, id, reset]);

  const dispatchData = (submitResult: any, title: string) => {
    const result = submitResult?.boostUpAdd || submitResult?.boostUpUpdate;
    if (result?.success) {
      reducers.dispatch(addSuccess(`'${title}' 부스트 강화가 ${id === 'new' ? '추가' : '수정'}되었습니다.`));
      onClose();
    } else {
      reducers.dispatch(addError(JSON.stringify(result || { success: false })));
    }
  }

  const onSubmit = async (values: IBoostUpForm) => {
    const variables = createSubmitPayload(values);
    if (
      variables.visibleFrom &&
      variables.visibleTo &&
      variables.visibleFrom.getTime() > variables.visibleTo.getTime()
    ) {
      reducers.dispatch(addError('노출 시작시간이 노출 종료시간보다 이후로 설정되었습니다.'));
      return;
    }

    const { data: submitResult } = await apolloClient.mutate({
      mutation: id === 'new' ? boostUpAddMutation : boostUpUpdateMutation,
      variables,
    });

    dispatchData(submitResult, values.title);
  };

  const onBatchApplySubmit = async (values: IBoostUpForm) => {
    const variables = createSubmitPayload(values);
    if (
      variables.visibleFrom &&
      variables.visibleTo &&
      variables.visibleFrom.getTime() > variables.visibleTo.getTime()
    ) {
      reducers.dispatch(addError('노출 시작시간이 노출 종료시간보다 이후로 설정되었습니다.'));
      return;
    }

    const { data: submitResult } = await apolloClient.mutate({
      mutation: id === 'new' ? boostUpAddMutation : boostUpUpdateMutation,
      variables: {...variables, batchApply: true},
    });

    dispatchData(submitResult, values.title);
  };


  const createSubmitPayload = (values: IBoostUpForm) => {
    const {
      possibleMoments,
      title,
      description,
      budget,
      dailyBudget,
      status,
      boltPrice,
      boostUpSchemePolicies,
      startAt,
      endAt,
      visibleDateSet,
      visibleFromDate,
      visibleFromTime,
      visibleToDate,
      visibleToTime,
      targetTypes,
    } = values;
    const payload: Partial<IUpdateBoostUpInput> = {
      possibleMoments,
      title,
      description,
      budget,
      dailyBudget,
      status,
      boltPrice,
      startAt,
      endAt,
      boostUpSchemePolicies: boostUpSchemePolicies.map(({ id, ...rest }) => ({ ...rest })),
      targetTypes,
    };

    if (id !== 'new') {
      payload.id = +id;
    }

    if (visibleDateSet === 'enabled') {
      return {
        ...payload,
        visibleFrom: new Date(`${visibleFromDate} ${visibleFromTime}`),
        visibleTo: new Date(`${visibleToDate} ${visibleToTime}`),
      };
    }

    return payload;
  };

  return (
    <FormProvider {...form}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <BoostUpDialogContent id={id} />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button onClick={() => onClose()} color="inherit">
              취소
            </Button>
            <Button onClick={handleSubmit(onSubmit, e => console.error(e))} color="primary">
              저장
            </Button>
            <Button onClick={handleSubmit(onBatchApplySubmit, e => console.error(e))} color="primary">
              저장 후 일괄적용
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </FormProvider>
  );
};
