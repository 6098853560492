import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Step, StepButton, Stepper } from '@mui/material';
import {
  boostAddMutation,
  boostQuery,
  boostUpdateMutation,
  IBoostPromotion,
  IBoostSubPromotionInput,
} from 'api/boostPromotionGql';
import { IBrand } from 'api/brandGql';
import { useAlert } from 'common/context/alert';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { BoostParams } from '../container/BoostContentContainer';
import { BoostPromotionDialogContent } from './BoostPromotionDialogContent';
import { store as reducers } from 'reducers';
import { addError, addSuccess } from 'state/notistackState';

export interface BoostPromotionForm extends IBoostPromotion {
  boostName: string;
  boostTargetConditionId?: number;
  boostTargetConditionChanged?: boolean;
  onOffLineStatus: string;
  countCap: number;
  benefitDescription: string;
  boltPrice: number;
  important: string;
  description: string;
  editorContent: string;
}

const BOOST_PROMOTION_DIALOG_FORMS: (keyof BoostPromotionForm | 'promotion' | 'subPromotions')[][] = [
  ['boostName'],
  ['subTitle'],
  ['type', 'discountType', 'countCap', 'title', 'benefitDescription', 'boltPrice', 'promotion', 'subPromotions'],
  ['onOffLineStatus', 'important', 'description'],
];

const BOOST_PROMOTION_DIALOG_PAGES = ['부스트 정보', '브랜드 정보', '부스트 설정', '유의사항', '부스트 태그', '확인'];
// const VALIDATION_FIELDS: (keyof BoostPromotionForm)[][] = [['subTitle'], [], ['type', 'discountType', 'countCap']];

export const BoostPromotionDialog: React.FC<{ brandInformation?: IBrand; onDialogSubmit: () => void }> = ({
  brandInformation,
  onDialogSubmit,
}) => {
  const alert = useAlert();
  const history = useHistory();
  const { brandId, promotionId, action } = useParams<BoostParams>();
  const [page, setPage] = useState(0);

  const form = useForm<BoostPromotionForm>({
    shouldUnregister: false,
  });
  const { handleSubmit, reset, trigger, getValues, setError, clearErrors } = form;

  const [getBoost, { data }] = useLazyQuery(boostQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const title = action === 'copy' ? '부스트 복사' : '부스트 설정';

  useEffect(() => {
    if (promotionId !== undefined) {
      if (promotionId === 'new') {
        // 기본값
        reset({
          subTitle: brandInformation?.name,
          type: 'standard',
          discountType: 'percent',
          onOffLineStatus: 'online',
          promotion: {
            priceMax: 2000000,
          },
        });
      }

      if (promotionId !== 'new') {
        reset({});
        getBoost({
          variables: { id: Number(promotionId) },
        });
      }
    }
  }, [reset, getBoost, promotionId, brandInformation]);

  // 받아온 boost 데이터를 폼에 수화
  useEffect(() => {
    if (!data) {
      return;
    }
    // 퍼센트 타입이 아닐 경우 fixed 타입으로 변경
    const newData = data?.boostPromotion;
    const discountType = newData?.promotion?.discount?.indexOf('%') === -1 ? 'fixed' : 'percent';
    const discount = newData?.promotion?.discount?.replace(/%/g, '');
    const newFormData = {
      ...newData,
      discount,
      discountType,
      boostName: newData?.name,
    };
    newFormData.promotion = { ...newFormData.promotion } ?? {};

    if (newData?.subPromotions?.length) {
      reset({
        ...newFormData,
        subPromotions: newData.subPromotions.map((subPromotion: IBoostSubPromotionInput, idx: number) => ({
          ...subPromotion,
          discount: +subPromotion?.discount?.replace(/%/g, '') || undefined,
          maxDiscountAmount: +subPromotion.maxDiscountAmount || undefined,
          boltPrice: +subPromotion.boltPrice,
          sequence: idx + 1,
        })),
        initialData: newFormData,
      });
    } else {
      reset(newFormData);
    }
  }, [reset, data]);

  const handleClose = () => {
    history.push(`/boost/${brandId}`);
  };

  const handleBack = () => {
    page > 0 && onSetPage(page - 1);
  };

  const handleNext = () => {
    onSetPage(page + 1);
  };

  const onSetPage = (idx: number) => {
    if (page > idx) {
      clearErrors();
      setPage(idx);
      return;
    }
    if (page === 4) {
      // 부스트 태그 Autocomplete 수동 validation
      const conditionId = getValues('boostTargetConditionId');
      if (!conditionId) {
        setError('boostTargetConditionId', {
          message: `필수값입니다`,
        });
      } else {
        if (getValues('boostTargetConditionChanged')) {
          setError('boostTargetConditionId', {
            message: `값이 변경되었습니다. 수정한 태그를 저장 후 진행해주세요`,
          });
          return;
        }
        setPage(idx);
      }
    } else {
      trigger(BOOST_PROMOTION_DIALOG_FORMS[page]).then(result => result && setPage(idx));
    }
  };

  const [addOrUpdateBoostPromotion, { loading }] = useMutation(
    promotionId === 'new' || action === 'copy' ? boostAddMutation : boostUpdateMutation,
    {
      onCompleted: () => {
        reducers.dispatch(addSuccess(`부스트 프로모션이 업데이트 되었습니다.`));
        onDialogSubmit();
      },
      onError: err => {
        reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
        onDialogSubmit();
      },
    }
  );

  const onSubmit = async (values: BoostPromotionForm) => {
    const payload = createSubmitPayload(values);
    if (!payload) {
      return;
    }
    addOrUpdateBoostPromotion({ variables: payload });
    handleClose();
  };

  const createSubmitPayload = (values: BoostPromotionForm) => {
    const targetPayload: any = {};
    if (!values) {
      alert.open('오류', `값을 불러올 수 없습니다.`);
      return undefined;
    }

    const keys = Object.keys(values);
    for (let i = 0; i <= keys.length; i += 1) {
      targetPayload[keys[i]] = values[keys[i] as keyof BoostPromotionForm] || null;
    }

    const makeDiscountString = (discount: number | undefined, type: 'fixed' | 'percent') => {
      if (typeof discount !== 'undefined' && Number.isNaN(+discount)) {
        return -1;
      }
      if (type === 'fixed') {
        return `${discount}`;
      }
      if (discount) {
        return `${discount}%`;
      }
      return -1;
    };

    const discount =
      typeof values.promotion?.discount === 'undefined'
        ? undefined
        : makeDiscountString(+values.promotion?.discount, values.discountType);

    if (discount && discount < 0) {
      alert.open('오류', `할인 입력값에 오류가 있습니다. (${values.promotion?.discount})`);
      return;
    }

    return {
      ...targetPayload,
      id: promotionId === 'new' || action === 'copy' ? undefined : Number(promotionId),
      brandId: Number(brandId),
      status: 'enabled',
      name: values.boostName,
      discount,
      description: values.editorContent || values.description,
      priceMin: values.promotion?.priceMin || null,
      priceMax: values.promotion?.priceMax || null,
      maxDiscountAmount:
        values.discountType === 'percent' && values.promotion?.maxDiscountAmount
          ? values.promotion?.maxDiscountAmount
          : undefined,
      // 정기결제 한정
      promotionType: values.type === 'recurring' ? 'sequence' : 'fixed',
      subPromotions:
        values.type === 'recurring'
          ? values.subPromotions?.map((item: IBoostSubPromotionInput, idx) => ({
              ...item,
              sequence: idx + 1,
              discount: makeDiscountString(+item.discount, values.discountType),
            }))
          : null,
    };
  };

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container direction="row">
          <Grid item xs={2}>
            {/* 구조에 안좋음 나중에 제거 */}
            {action !== 'copy' && (
              <Stepper activeStep={page} orientation="vertical" style={{ paddingLeft: 0 }}>
                {BOOST_PROMOTION_DIALOG_PAGES.map((label, index) => (
                  <Step
                    key={`boost-promotion-dialog-step-${index}`}
                    onClick={() => onSetPage(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <StepButton onClick={() => onSetPage(index)}>{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
            )}
          </Grid>
          <Grid item xs={10}>
            <FormProvider {...form}>
              <BoostPromotionDialogContent page={page} brandInformation={brandInformation} />
            </FormProvider>
          </Grid>
        </Grid>
        <Box m={4} />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            {page > 0 && (
              <Button onClick={() => handleBack()} color="inherit">
                뒤로
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={() => handleClose()} color="inherit">
              취소
            </Button>
            {action !== 'copy' && page + 1 < BOOST_PROMOTION_DIALOG_PAGES.length ? (
              <Button onClick={() => handleNext()} color="primary">
                다음
              </Button>
            ) : (
              <Button disabled={loading} onClick={handleSubmit(onSubmit)} color="primary">
                저장
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
