import { useLazyQuery } from '@apollo/client';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { Alert } from '@mui/lab';
import { getNotificationsQuery } from 'api/notificationGql';
import React, { useEffect, useState } from 'react';
import { INotificationData, NotificationTypeEnum } from 'type/api/notification';
import NotificationListItem from './component/ListItem';
import NotificationItemDialogContainer from './container/NotificationEditDialogContainer';
import styles from './notification.module.scss';

export function makeNotificationTargetString(item: INotificationData): string {
  return [item.isFcm && '푸시알림', item.isKakao && '카카오', item.isSms && '문자'].filter(Boolean).join(', ');
}

export const makeNotificationTypeString = (type: NotificationTypeEnum): string => {
  switch (type) {
    case NotificationTypeEnum.COMMON:
      return '공통 메세지';
    case NotificationTypeEnum.FCM:
      return '푸시알림';
    case NotificationTypeEnum.KAKAO:
      return '카카오';
    case NotificationTypeEnum.SMS:
      return '문자';
  }
};

const NotificationContainer: React.FC = props => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogId, setDialogId] = useState<string | undefined>();

  // Notification 목록 갱신
  const [loadNotificationList, { loading, error, data, refetch }] = useLazyQuery(getNotificationsQuery, {
    notifyOnNetworkStatusChange: true,
  });
  const notificationList: INotificationData[] = data?.getNotifications ?? [];

  // 페이지 변경 후 리스트 로드
  useEffect(() => {
    loadNotificationList();
  }, [loadNotificationList]);

  const handleOpenDialog = (id: string) => {
    return () => {
      setDialogOpen(true);
      setDialogId(id);
    };
  };

  const notificationItemRender = (notificationList: INotificationData[]) => {
    if (!notificationList) {
      return null;
    }

    const reducedData: { category: string; rows: INotificationData[] }[] = [];
    return notificationList
      .slice(0, notificationList.length)
      .sort((a, b) => {
        if (a.category.localeCompare(b.category) === 0) {
          return a.code.localeCompare(b.code);
        }
        return a.category.localeCompare(b.category);
      })
      .reduce((prev, curr) => {
        const idx = prev.findIndex(item => {
          return item.category === curr.category;
        });
        if (idx === -1) {
          prev.push({
            category: curr.category,
            rows: [curr],
          });
        } else {
          prev[idx].rows.push(curr);
        }
        return prev;
      }, reducedData)
      .map(reduced => (
        <Box key={`notification-section-${reduced.category}`}>
          <h1 className={styles.sectionHead}>{reduced.category}</h1>
          <Grid container spacing={2}>
            {reduced.rows.map(row => (
              <NotificationListItem
                key={`${row.id}-notification-list-item`}
                row={row}
                onOpen={handleOpenDialog(row.id)}
              />
            ))}
          </Grid>
          <div className={styles.padding4} />
          <div className={styles.padding4} />
        </Box>
      ));
  };

  return (
    <Paper
      style={{
        padding: '24px',
        display: 'flex',
        flex: 1,
      }}
    >
      <Grid container direction="column">
        <Grid item>
          {loading && <LinearProgress />}
          {error && <Alert severity="error">{error.message}</Alert>}
          {notificationItemRender(notificationList)}
        </Grid>
        <NotificationItemDialogContainer
          id={dialogId}
          open={dialogOpen}
          handleClose={() => {
            setDialogOpen(false);
            refetch && refetch();
          }}
        />
      </Grid>
    </Paper>
  );
};

export default NotificationContainer;
