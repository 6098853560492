import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { getBoostMissionPolicyListQuery } from 'api/missionPolicyGql';
import { ChaiListPage, ChaiListTableColumn } from 'common';
import { BoostMissionDialog } from './BoostMissionDialog';

interface BoostMissionColumn extends ChaiListTableColumn {
  id: 'id' | 'title' | 'status' | 'createdAt' | 'updatedAt' | 'startAt' | 'endAt';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any, row: any) => string | JSX.Element;
}

const columns: BoostMissionColumn[] = [
  {
    id: 'id',
    label: '미션 폴리시 ID',
    format: value => (
      <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
        {value}
      </Typography>
    ),
  },
  {
    id: 'title',
    label: '타이틀',
    format: value => (
      <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
        {value}
      </Typography>
    ),
  },
  {
    id: 'createdAt',
    label: '생성 날짜',
    format: (value: any) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    id: 'startAt',
    label: '시작 날짜',
    format: (value: any) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    id: 'endAt',
    label: '종료 날짜',
    format: (value: any) => format(new Date(value), 'yyyy-MM-dd'),
  },
];

export const BoostMissionListContainer: React.FC<{}> = props => {
  const { missionId } = useParams<{ missionId?: string }>();

  return (
    <ChaiListPage
      path="/boost/mission/"
      query={getBoostMissionPolicyListQuery}
      childId={missionId}
      columns={columns}
      dialog={BoostMissionDialog}
    />
  );
};
