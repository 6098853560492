import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Operation, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import config from 'config';
import errorHandleLink from './links/errorHandleLink';

const GRAPHQL_ENDPOINT = `${config.apiScheme}://${config.graphUrl}`;
const GRAPHQL_SUBSCRIPTIONS_ENDPOINT = `${config.graphqlWSScheme}://${config.graphUrl}`;
const CARD_GRAPHQL_ENDPOINT = `${config.apiScheme}://${config.cardGraphUrl}`;

// Create a http link:
const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const cardLink = new HttpLink({
  uri: CARD_GRAPHQL_ENDPOINT,
  credentials: 'include',
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: GRAPHQL_SUBSCRIPTIONS_ENDPOINT,
  options: {
    reconnect: true,
  },
});

const isCardGraph = (op: Operation) => op.getContext().cardGraph === true;
// Create an upload link
const isFile = (value: any) =>
  (typeof File !== 'undefined' && value instanceof File) || (typeof Blob !== 'undefined' && value instanceof Blob);
const isUpload = ({ variables }: { variables: any }) => Object.values(variables).some(isFile);
const uploadLink = createUploadLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
}) as unknown as ApolloLink; // 타입오류 수정

const terminatingLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition?.operation === 'subscription';
  },
  wsLink,
  // if variable contains file or blob, use uploadLink instead, will send as multipart form data.
  split(isCardGraph, cardLink, split(isUpload, uploadLink, httpLink))
);

const link = ApolloLink.from([errorHandleLink, terminatingLink]);

const apolloClient = new ApolloClient({
  link: link as any,
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
