import gql from 'graphql-tag';

export const getBoostMissionPolicyQuery = gql`
  query boostMissionPolicy($id: Int!) {
    boostMissionPolicy(id: $id) {
      id
      title
      description
      startAt
      endAt
      status
      type
      conditionType
      code
      criteria
      visibleToOffsetHour
      usableToOffsetHour
      buyableToOffsetHour
      boostCampaignId
      boltPolicyId
      missionUrl
    }
  }
`;

export const getBoostMissionPolicyListQuery = gql`
  query boostMissionPolicyList($skip: Int, $pageSize: Int, $filter: String) {
    boostMissionPolicyList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        createdAt
        updatedAt
        title
        description
        startAt
        endAt
        status
      }
      total
    }
  }
`;

export const boostMissionPolicyAddMutation = gql`
  mutation boostMissionPolicyAdd(
    $title: String!
    $description: String
    $startAt: Date!
    $endAt: Date!
    $status: String!
    $type: String!
    $conditionType: String!
    $code: String
    $criteria: Int
    $visibleToOffsetHour: Int
    $usableToOffsetHour: Int
    $buyableToOffsetHour: Int
    $boostCampaignId: Int
    $boltPolicyId: Int
    $missionUrl: String
  ) {
    boostMissionPolicyAdd(
      title: $title
      description: $description
      startAt: $startAt
      endAt: $endAt
      status: $status
      type: $type
      conditionType: $conditionType
      code: $code
      criteria: $criteria
      visibleToOffsetHour: $visibleToOffsetHour
      usableToOffsetHour: $usableToOffsetHour
      buyableToOffsetHour: $buyableToOffsetHour
      boostCampaignId: $boostCampaignId
      boltPolicyId: $boltPolicyId
      missionUrl: $missionUrl
    )
  }
`;

export const boostMissionPolicyUpdateMutation = gql`
  mutation boostMissionPolicyUpdate(
    $id: Int!
    $title: String!
    $description: String
    $startAt: Date!
    $endAt: Date!
    $status: String!
    $type: String!
    $conditionType: String!
    $code: String
    $criteria: Int
    $visibleToOffsetHour: Int
    $usableToOffsetHour: Int
    $buyableToOffsetHour: Int
    $boostCampaignId: Int
    $boltPolicyId: Int
    $missionUrl: String
  ) {
    boostMissionPolicyUpdate(
      id: $id
      title: $title
      description: $description
      startAt: $startAt
      endAt: $endAt
      status: $status
      type: $type
      conditionType: $conditionType
      code: $code
      criteria: $criteria
      visibleToOffsetHour: $visibleToOffsetHour
      usableToOffsetHour: $usableToOffsetHour
      buyableToOffsetHour: $buyableToOffsetHour
      boostCampaignId: $boostCampaignId
      boltPolicyId: $boltPolicyId
      missionUrl: $missionUrl
    )
  }
`;

// 볼트 폴리시 쿼리 (임시로 여기 저장, bolt gql 파일 생성되면 이동)
export const getBoltPolicyListQuery = gql`
  query boltPolicyList($skip: Int, $pageSize: Int, $filter: String) {
    boltPolicyList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        title
      }
      total
    }
  }
`;

export interface BoostBoltPolicyListItem {
  id: string;
  title: string;
}

export interface IAddBoostMissionPolicyInput {
  createdAt: Date;
  updatedAt: Date;
  title: string;
  description: string;
  startAt: Date;
  endAt: Date;
  status: BoostMissionPolicyStatusEnum;
  type: BoostMissionPolicyTypeEnum;
  conditionType: BoostMissionPolicyConditionTypeEnum;
  code: string | null;
  criteria: number | null;
  visibleToOffsetHour: number;
  usableToOffsetHour: number;
  buyableToOffsetHour: number;
  boostCampaignId: number | null;
  boltPolicyId: number | null;
  missionUrl: string;
}

export interface IUpdateBoostMissionPolicyInput extends IAddBoostMissionPolicyInput {
  id: number;
}

// from: chai-common boost mission
export enum BoostMissionPolicyStatusEnum {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}
export enum BoostMissionPolicyHistoryStatusEnum {
  START = 'start',
  DONE = 'done',
}
export enum BoostMissionPolicyTypeEnum {
  WELCOME_TUTORIAL = 'welcome_tutorial',
  WELCOME_RED_CARD = 'welcome_red_card',
  WELCOME_CREDIT_CARD = 'welcome_credit_card',
  CREDIT_CARD_APPLY_CONFIRM = 'credit_card_apply_confirm',
}
export enum BoostMissionPolicyConditionTypeEnum {
  CODE = 'code',
  CRITERIA = 'criteria',
}
export enum MissonRewardBoostCampaignStatusEnum {
  AVAILABLE = 'available',
  USED = 'used',
}

// export interface IBoostBudgetList {
//   list: [IBoostBudget];
//   total: number;
// }
