import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ChaiSelectForm, ChaiTextForm } from 'common';
import { useAlert } from 'common/context/alert';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ISensSendKakaoButton, KakaoButtonType } from 'type/api/notification';
import { hasErrorFormProps } from 'util/formFieldProps';

const MessageKakaoButtonContainer: React.FC<{
  buttons: ISensSendKakaoButton[] | undefined;
  setValue: any;
}> = ({ buttons, setValue }) => {
  const alert = useAlert();

  const [openModal, setOpenModal] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [selectedButtonIdx, setSelectedButtonIdx] = useState<number | undefined>();
  const {
    register,
    control,
    watch: formWatch,
    formState: { errors: formErrors },
    reset,
    handleSubmit,
    getValues,
  } = useForm<ISensSendKakaoButton>();

  const handleAdd = () => {
    const targetIdx = buttons?.length ?? 0;
    setFormVisible(true);
    setOpenModal(true);
    setSelectedButtonIdx(targetIdx);
    reset({
      type: KakaoButtonType.APP_LINK,
    });
  };

  const handleOpen = (buttonIdx: number) => {
    if (!buttons?.[buttonIdx]) {
      return;
    }

    setFormVisible(true);
    setOpenModal(true);
    setSelectedButtonIdx(buttonIdx);
    reset(buttons[buttonIdx]);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = () => {
    const newButtons = buttons ? [...buttons] : [];
    setValue(
      `buttons`,
      newButtons.filter((button, idx) => idx !== selectedButtonIdx)
    );
    setOpenModal(false);
  };

  const handleSave = () => {
    const values = getValues();
    const newButtons = buttons ? [...buttons] : [];
    if (typeof selectedButtonIdx === 'number') {
      newButtons[selectedButtonIdx] = values;
    } else {
      newButtons.push(values);
    }
    setValue(`buttons`, newButtons);
    setOpenModal(false);
  };

  const handleExit = () => {
    setFormVisible(false);
  };

  const kakaoButtonType = formWatch('type');

  return (
    <>
      <Box m={2} />
      <Typography variant="subtitle2">카카오 버튼</Typography>
      <Box m={1} />
      <Grid container>
        <Grid item xs={6}>
          <Card>
            <List>
              {buttons &&
                buttons.map((button, idx) => (
                  <ListItem button onClick={() => handleOpen(idx)} key={idx}>
                    <ListItemText primary={button.name} secondary={button.type} />
                  </ListItem>
                ))}
              <ListItem button onClick={() => handleAdd()}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary={'버튼 추가'} />
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleClose}
        TransitionProps={{
          onExited: handleExit,
        }}
      >
        <DialogTitle id="alert-dialog-title">카카오 버튼 설정</DialogTitle>
        <DialogContent>
          {formVisible && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ChaiTextForm required register={register} errors={formErrors} name="name" label="카카오 버튼 텍스트" />
              </Grid>
              <Grid item xs={6}>
                <ChaiSelectForm control={control} name="type" label="카카오 버튼 타입">
                  <MenuItem value="WL">WL (웹 링크)</MenuItem>
                  <MenuItem value="AL">AL (앱 링크)</MenuItem>
                  {/* 현재 사용하지 않는 버튼형식 */}
                  {/* <MenuItem value="DS">DS (배송조회)</MenuItem> */}
                  {/* <MenuItem value="BK">BK (봇 키워드)</MenuItem> */}
                  {/* <MenuItem value="MD">MD (메세지 전달)</MenuItem> */}
                </ChaiSelectForm>
              </Grid>
              {kakaoButtonType === 'WL' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      {...register('linkPc', { required: true })}
                      label="PC 링크"
                      required
                      variant="standard"
                      fullWidth
                      {...hasErrorFormProps({ name: 'linkPc', error: formErrors })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...register('linkMobile', { required: true })}
                      label="모바일 링크"
                      required
                      variant="standard"
                      fullWidth
                      {...hasErrorFormProps({ name: 'linkMobile', error: formErrors })}
                    />
                  </Grid>
                </>
              )}
              {kakaoButtonType === 'AL' && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      {...register('schemeAndroid', {required: true})}
                      label="안드로이드 스킴"
                      required
                      variant="standard"
                      fullWidth
                      {...hasErrorFormProps({ name: 'schemeAndroid', error: formErrors })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...register('schemeIos', {required: true})}
                      label="iOS 스킴"
                      required
                      variant="standard"
                      fullWidth
                      {...hasErrorFormProps({ name: 'schemeIos', error: formErrors })}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => alert.confirm('삭제', '버튼을 삭제합니까?', () => handleDelete())} color="warning">
            삭제
          </Button>
          <Button onClick={handleClose} color="inherit">
            취소
          </Button>
          <Button onClick={handleSubmit(handleSave)} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MessageKakaoButtonContainer;
