import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  createUserTagMutate,
  deleteUserTagMutate,
  getTagTargetListQuery,
  getUserTagQuery,
  updateUserTagMutate,
} from 'api/userTagGql';
import { ChaiListTable, ChaiSelectForm, ChaiSimpleListTable, ChaiTextForm } from 'common';
import React, { useEffect } from 'react';
import { get, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { store as reducers } from 'reducers';
import { addSuccess, addError } from 'state/notistackState';
import apolloClient from 'util/apolloClient';

export interface IUserTag {
  id?: number;
  name?: string;
  status: 'enabled' | 'disabled';
  file: any;
}

export interface IUserTagPaymentTag {
  id: number;
  name: string;
}

interface UploadedUserIdList {
  id: string;
}

export const UserTagDialog: React.FC<{ id: string }> = ({ id }) => {
  // const alert = useAlert();
  const history = useHistory();
  const [title, setTitle] = React.useState('');
  const [userIdList, setUserIdList] = React.useState<UploadedUserIdList[]>([]);
  const methods = useForm<IUserTag>({
    shouldUnregister: false,
  });
  const {
    register,
    reset,
    watch,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    // setError,
  } = methods;
  // const formValues = watch();
  const [getUserTag, { data }] = useLazyQuery(getUserTagQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { id: Number(id) },
    fetchPolicy: id === 'new' ? 'cache-only' : 'network-only',
  });
  const [getTagTargetList, { data: userListData, refetch: refetchUserListData }] = useLazyQuery(getTagTargetListQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: id === 'new' ? 'cache-only' : 'network-only',
  });

  const [addOrUpdateUserTag, { loading }] = useMutation(id === 'new' ? createUserTagMutate : updateUserTagMutate, {
    onCompleted: () => {
      reducers.dispatch(addSuccess(`유저 태그가 업데이트 되었습니다.`));
      handleClose();
    },
    onError: err => {
      reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
      handleClose();
    },
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tagName = watch('name');
  const file: FileList = watch('file');
  useEffect(() => {
    // 파일 업로드
    const targetFile = file?.[0];
    if (targetFile) {
      if (id === 'new') {
        var fileReader = new FileReader();
        fileReader.onload = function (e) {
          const raw = e?.target?.result;
          if (raw) {
            const lines = raw.toString().split('\n');
            const arr = lines.reduce((acc: UploadedUserIdList[], line: string) => {
              return acc.concat(line.split(',').map(item => ({ id: item.trim() })));
            }, []);
            setUserIdList(arr);
          }
        };
        fileReader.readAsText(targetFile);
      } else {
        apolloClient
          .mutate({
            mutation: createUserTagMutate,
            variables: {
              tagName,
              action: 'add',
              targetIdFile: targetFile,
            },
          })
          .then(value => {
            getTagTargetList({ variables: { tagName, skip: page * rowsPerPage, pageSize: rowsPerPage } });
          })
          .catch(() => {});
        setValue('file', undefined);
      }
    }
  }, [id, file, tagName, getTagTargetList, setValue, page, rowsPerPage]);

  useEffect(() => {
    if (id !== 'new') {
      setUserIdList([]);
      getUserTag();
    } else {
      setUserIdList([]);
      setTitle('유저 태그 추가');
      reset({
        status: 'enabled',
      });
    }
  }, [id, setTitle, reset, getUserTag]);

  useEffect(() => {
    if (data?.getTagById) {
      const tagData = data?.getTagById;
      setTitle(`${tagData.name} (${id})`);
      reset({
        name: tagData.name,
        status: tagData.status,
      });
    }
  }, [data, id, reset]);

  useEffect(() => {
    if (id !== 'new' && tagName) {
      getTagTargetList({ variables: { tagName, skip: page * rowsPerPage, pageSize: rowsPerPage } });
    }
  }, [getTagTargetList, id, page, rowsPerPage, tagName]);

  const handleClose = () => {
    history.push('/tag/userTag/');
  };

  const onSubmit = () => {
    const { name: tagName, file, status } = getValues();
    const targetIdFile = file?.[0];
    const variables = id === 'new' ? { action: 'new', tagName, targetIdFile } : { tagName, status };

    addOrUpdateUserTag({ variables });
  };

  const onRemoveItem = (row: any) => {
    apolloClient
      .mutate({
        mutation: deleteUserTagMutate,
        variables: {
          tagName,
          userIds: [row.targetId],
        },
      })
      .then(() => {
        refetchUserListData && refetchUserListData();
      });
  };

  const getColumn = (onRemoveItem: (row: any) => void) => {
    const columns = [
      {
        id: 'targetId',
        label: '유저 ID',
        searchTarget: true,
      },
      {
        id: 'delete',
        label: '삭제',
        width: 60,
        format: (_: any, row: any) => (
          <IconButton size="small" onClick={(event: React.MouseEvent<HTMLButtonElement>) => onRemoveItem(row)}>
            <Icon>highlight_off</Icon>
          </IconButton>
        ),
      },
    ];
    return columns;
  };

  const columns = getColumn(onRemoveItem);

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ChaiTextForm
          register={register}
          errors={errors}
          label="유저태그 이름"
          name="name"
          shrink
          required
          disabled={id !== 'new'}
        />
        <ChaiSelectForm control={control} name="status" label="유저태그 상태" defaultValue="enabled">
          <MenuItem value="enabled">활성</MenuItem>
          <MenuItem value="disabled">비활성</MenuItem>
        </ChaiSelectForm>
        <Box m={2} />
        {id === 'new' && (
          <ChaiSimpleListTable
            name="userTagUserList"
            headerName="유저ID 목록"
            columns={[
              {
                id: 'id',
                label: '유저 ID',
                searchTarget: true,
              },
            ]}
            list={userIdList || []}
            size="small"
            rowsPerPageOptions={[10, 20, 50]}
          />
        )}
        {id !== 'new' && (
          <ChaiListTable
            size="small"
            hideHeader
            total={userListData?.getTagTargetList?.total}
            columns={columns}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 50, 100]}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            {(userListData?.getTagTargetList?.list || []).map((row: any) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={`user-tag-user-list-item-${row.id}`}>
                  {columns.map(column => {
                    const value = get(row, column.id);
                    return <TableCell key={column.id}>{column.format ? column.format(value, row) : value}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </ChaiListTable>
        )}
        <Box m={2} />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button color="primary">
              <input id="user-tag-userlist-file" type="file" hidden {...register('file')} accept=".csv" />
              <label htmlFor="user-tag-userlist-file" style={{ lineHeight: 1 }}>
                <Icon fontSize="small">cloud_upload</Icon>
              </label>
              <label htmlFor="user-tag-userlist-file">&nbsp;유저ID 목록 업로드</label>
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => handleClose()} color="inherit">
              취소
            </Button>
            <Button disabled={loading} onClick={handleSubmit(onSubmit)} color="primary">
              저장
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
