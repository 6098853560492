import { ChaiListPage, ChaiListTableColumn } from 'common';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BoostRequiredImportantDialog } from './BoostRequiredImportantDialog';
import { getBoostImportantListQuery } from 'api/boostImportantGql';
import { format } from 'date-fns';

interface BoostImportantListColumn extends ChaiListTableColumn {
  id: 'type' | 'title' | 'priority' | 'createdAt' | 'updatedAt' | 'content';
  label: string;
  width?: string;
  format?: (value: any) => string | JSX.Element;
}

const columns: BoostImportantListColumn[] = [
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'content',
    label: '내용',
    width: '45%',
    format: (value: string) => (value.length > 80 ? value.slice(0, 80).toString() + '...' : value),
  },
  {
    id: 'createdAt',
    label: '생성 날짜',
    format: (value: string) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    id: 'updatedAt',
    label: '수정 날짜',
    format: (value: string) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    id: 'priority',
    label: '순서',
    width: '8%',
  },
];

export const BoostRequiredImportantListContainer: React.FC<{}> = () => {
  const { boostRequiredImportantId } = useParams<{ boostRequiredImportantId?: string }>();

  return (
    <ChaiListPage
      path="/important/required/"
      table="importantRequired"
      query={getBoostImportantListQuery}
      hideAddButton
      getQueryFilter={keyword => (keyword ? JSON.stringify({ title: keyword }) : '')}
      childId={boostRequiredImportantId}
      columns={columns}
      dialog={BoostRequiredImportantDialog}
    />
  );
};
