import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React from 'react';
import { INotificationData } from 'type/api/notification';
import styles from '../notification.module.scss';
import { makeNotificationTargetString } from '../NotificationContainer';
import MessageCard from './MessageCard';

const NotificationListItem: React.FC<{
  row: INotificationData;
  onOpen: any;
}> = ({ row, onOpen }) => {
  const title = row.code.replace(/_/g, ' ').toLocaleLowerCase();
  const subtitle = makeNotificationTargetString(row);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} className={styles.listItemWrapper}>
      <Card key={row.id} variant="outlined">
        <CardHeader
          classes={{
            root: styles.overflowHidden,
          }}
          title={
            <Typography noWrap gutterBottom variant="subtitle1" className={styles.cardTitle}>
              {title}
            </Typography>
          }
          subheader={subtitle}
          subheaderTypographyProps={{
            variant: 'caption',
          }}
        />
        <CardContent>
          <Grid container>
            <Grid item onClick={onOpen} className={styles.cursorPointer}>
              <MessageCard msg={row.messages?.[0]} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default NotificationListItem;
