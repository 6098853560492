import { useQuery } from '@apollo/client';
import { Box, Button, Dialog, Grid, Icon, MenuItem, TablePagination, Typography } from '@mui/material';
import { boostCampaignListQuery, IBoostCampaignListItem } from 'api/boostCampaignGql';
import { ChaiRadioForm, ChaiSelectForm, ChaiTextContextForm } from 'common';
import ChaiDivider from 'common/component/ChaiDivider';
import { addDays, format } from 'date-fns';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { BoostContentTitle } from '../component/BoostContentTitle';
import { BoostCampaignDialog } from '../container/BoostCampaignDialog';
import { BOOST_TARGET_TYPES } from '../container/BoostCampaignDialogContent';
import { BoostCalendarListCardComponent } from './BoostCalendarListCardComponent';

export const BoostCalendarListContainer: React.FC<{ dateType: 'day'; date: string }> = ({ date, dateType }) => {
  const history = useHistory();
  const { campaignId } = useParams<{ campaignId: string }>();
  const form = useForm<{ date: string; targetType: string[]; statusLabel: string[] }>({
    shouldUnregister: false,
  });
  const { control: formControl, watch, setValue } = form;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(63);
  const targetTypes = watch('targetType');
  const statusLabel = watch('statusLabel');
  const { data, previousData, refetch } = useQuery(boostCampaignListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      skip: page * rowsPerPage,
      pageSize: rowsPerPage,
      filter: JSON.stringify({
        visibleAt: date,
        targetTypes: targetTypes?.length > 0 ? targetTypes : undefined,
        statusLabel: statusLabel?.length > 0 ? statusLabel : undefined,
      }),
    },
  });
  const campaignList: IBoostCampaignListItem[] =
    data?.boostCampaignList?.list || previousData?.boostCampaignList?.list || [];
  const campaignListTotal: number = data?.boostCampaignList?.total;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const formDate = watch('date');
  useEffect(() => {
    if (formDate && formDate !== date) {
      history.push(`/boost/calendar/${dateType}/${formDate}`);
    }
  }, [history, formDate, date, dateType]);

  useEffect(() => {
    refetch({
      filter: JSON.stringify({
        visibleAt: date,
        targetTypes: targetTypes?.length > 0 ? targetTypes : undefined,
        statusLabel: statusLabel?.length > 0 ? statusLabel : undefined,
      }),
    });
  }, [date, refetch, targetTypes, statusLabel]);

  useEffect(() => {
    refetch({ filter: JSON.stringify({ visibleAt: date, targetTypes: targetTypes }) });
  }, [date, refetch, targetTypes]);

  const addDay = () => {
    const newDate = format(addDays(new Date(formDate), 1), 'yyyy-MM-dd');
    setValue('date', newDate);
    history.push(`/boost/calendar/${dateType}/${newDate}`);
  };

  const subDay = () => {
    const newDate = format(addDays(new Date(formDate), -1), 'yyyy-MM-dd');
    setValue('date', newDate);
    history.push(`/boost/calendar/${dateType}/${newDate}`);
  };

  return (
    <FormProvider {...form}>
      <Grid item style={{ width: '100%' }}>
        <BoostContentTitle title="캠페인"></BoostContentTitle>
        <Box sx={{ display: 'flex' }}>
          <Button onClick={subDay}>
            <Icon>arrow_left</Icon>
          </Button>
          <Box>
            <ChaiTextContextForm name="date" type="date" label="날짜" defaultValue={date} />
          </Box>
          <Button onClick={addDay}>
            <Icon>arrow_right</Icon>
          </Button>
          <Box style={{ minWidth: '166px', paddingRight: '24px' }}>
            <ChaiRadioForm
              context
              name="targetType"
              label="캠페인 대상"
              labelMargin={4}
              multiple
              values={BOOST_TARGET_TYPES}
            />
          </Box>
          <Box style={{ minWidth: '166px', paddingRight: '24px' }}>
            <ChaiRadioForm
              context
              name="statusLabel"
              label="캠페인 상태"
              labelMargin={4}
              multiple
              values={[
                { label: '집행예정', value: '집행예정' },
                { label: '집행중', value: '집행중' },
                { label: '집행중지', value: '집행중지' },
                { label: '집행기간만료', value: '집행기간만료' },
              ]}
            />
          </Box>
          {/* <Box style={{ minWidth: '266px', paddingRight: '16px' }}>
            <ChaiTextForm name="userId" label="유저ID 입력" />
          </Box> */}
          <Box flexGrow={1} />
          <Box style={{ minWidth: '166px', paddingRight: '16px' }}>
            <ChaiSelectForm name="dateType" label="캘린더 종류" defaultValue="day" control={formControl}>
              <MenuItem value={'day'}>일</MenuItem>
              <MenuItem disabled value={'week'}>
                주
              </MenuItem>
              <MenuItem disabled value={'week'}>
                월
              </MenuItem>
            </ChaiSelectForm>
          </Box>
        </Box>
        <ChaiDivider />
        <Box m={2} />
        <Grid container direction="column" justifyContent="center">
          <Typography variant="subtitle2" align="right" color="textSecondary">
            전체: {campaignListTotal || 0}
          </Typography>
          <Box m={1} />
          <Grid container spacing={2}>
            {campaignList.map(row => {
              return (
                <Grid item key={row.id} style={{ minWidth: 324 }}>
                  <BoostCalendarListCardComponent
                    item={row}
                    onClick={() => history.push(`/boost/calendar/${dateType}/${date}/${row.id}`)}
                  />
                </Grid>
              );
            })}
          </Grid>
          <TablePagination
            component="div"
            rowsPerPageOptions={[8, 12, 24, 36, 48, 63]}
            count={campaignListTotal || rowsPerPage * page + 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <Box m={8} />
      </Grid>
      <Dialog
        maxWidth="lg"
        open={!!campaignId}
        fullWidth
        onClose={(_, reason) => reason !== 'backdropClick' && history.push(`/boost/calendar/${dateType}/${date}`)}
      >
        <BoostCampaignDialog onDialogSubmit={() => refetch()} basePath={`/boost/calendar/${dateType}/${date}`} />
      </Dialog>
    </FormProvider>
  );
};
