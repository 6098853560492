import gql from 'graphql-tag';

export const getBoltDrawQuery = gql`
  query boltDrawPromotion($id: Int!) {
    boltDrawPromotion(id: $id) {
      id
      status
      title
      visibleFrom
      visibleTo
      enterableFrom
      enterableTo
      winnerCount
      totalBoltCount
      totalUserCount
      boltDraws(status: "won") {
        userId
        boltCount
        status
        user {
          id
          phone
          fullname
          koreaSpec {
            DI
          }
        }
      }
    }
  }
`;

export const getBoltDrawListQuery = gql`
  query boltDrawPromotionList($skip: Int, $pageSize: Int, $filter: String) {
    boltDrawPromotionList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        status
        title
        visibleFrom
        visibleTo
        enterableFrom
        enterableTo
        winnerCount
      }
      total
    }
  }
`;

export interface IBoltDrawUser {
  userId: string;
  boltCount: number;
  user: {
    id: string;
    phone: string;
    fullname: string;
    koreaSpec: {
      DI: string;
    };
  };
}

export interface IBoltListDraw {
  id: number;
  status: string;
  title: string;
  visibleFrom: string;
  visibleTo: string;
  enterableFrom: string;
  enterableTo: string;
  winnerCount: number;
  totalBoltCount: number;
  boltDraws: IBoltDrawUser;
}
