import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Alert } from '@mui/lab';
import {
  getNotificationQuery,
  modifyNotificationMutation,
  saveNotificationMessage,
  sendTestNotification,
} from 'api/notificationGql';
import { useAuth } from 'auth';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { INotificationData, INotificationMessage, NotificationTypeEnum } from 'type/api/notification';
import TestMessageDialog from '../component/TestMessageDialog';
import { makeNotificationTypeString } from '../NotificationContainer';
import NotificationEditDialogForm from './NotificationEditDialogForm';

const NotificationItemDialogContainer: React.FC<{ id?: string; open: boolean; handleClose: () => void }> = ({
  id,
  open,
  handleClose,
}) => {
  const auth = useAuth();
  const {
    register: formRegister,
    formState: { errors: formErrors },
    watch: formWatch,
    handleSubmit,
    reset,
    getValues,
  } = useForm<INotificationData>();
  const [testMessageDialogOpen, setTestMessageDialogOpen] = useState(false);

  // GQL: 개별 Notification 갱신
  const [loadNotification, { loading, error }] = useLazyQuery<{ getNotification: INotificationData }>(
    getNotificationQuery,
    {
      variables: { notificationId: id },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: res => {
        if (!res?.getNotification) {
          return;
        }

        const notification: INotificationData = Object.assign(res.getNotification);
        const messages =
          notification.messages.concat().sort((a, b) => {
            // 타입 소트
            return a.type.localeCompare(b.type);
          }) ?? [];

        reset({
          delay: notification.delay,
          isKakao: notification.isKakao,
          isSms: notification.isSms,
          isFcm: notification.isFcm,
          messages,
        });
        setCurrentNotification({
          ...notification,
          messages,
        });
        setIsNotificationFormReady(true);
      },
    }
  );
  const [isNotificationFormReady, setIsNotificationFormReady] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<INotificationData>();
  const [messageValidateError, setMessageValidateError] = useState<string | undefined>();

  // GQL: 테스트 문자 보내기
  const [sendTest, { loading: sendTestLoading }] = useMutation<boolean>(sendTestNotification);
  // GQL: Notification 수정
  const [setNotification] = useMutation<boolean>(modifyNotificationMutation);
  // GQL: Notification Message 수정
  const [setMessages] = useMutation<boolean>(saveNotificationMessage);

  // 모달 오픈할 때 데이터 리로드
  const handleOpen = async () => {
    setCurrentNotification(undefined);
    setIsNotificationFormReady(false);
    setMessageValidateError(undefined);
    loadNotification();
  };

  // 테스트 문자 전송
  const handleSendTest = () => {
    if (sendTestLoading) {
      return false;
    }
    const { id, messages, ...data }: { id: string; messages: INotificationMessage[] } = {
      ...currentNotification,
      ...getValues(),
      ...(currentNotification?.messages ? { messages: currentNotification.messages } : {}),
    };

    sendTest({
      variables: {
        to: auth.user?.phone,
        notification: {
          ...data,
          messages: messages.map(message => {
            const { id, ...rest } = message;
            return rest;
          }),
        },
      },
    })
      .then(res => {
        setTestMessageDialogOpen(true);
      })
      .catch(e => {});
  };

  const onUpdateMessage = (idx: number, values?: INotificationMessage) => {
    if (!currentNotification) {
      return;
    }

    if (!values) {
      if (!currentNotification?.messages) {
        return;
      }

      // 메세지 삭제
      setCurrentNotification({
        ...currentNotification,
        messages: currentNotification?.messages.filter((d, cidx) => cidx !== idx),
      });
      return;
    }

    let newMessage = currentNotification?.messages ?? [];
    newMessage[idx] = values;

    setCurrentNotification({
      ...currentNotification,
      messages: newMessage,
    });
  };

  // Submit
  const onSubmit = () => {
    // TODO: getValues 에서 messages 업데이트 되지 않음
    const { id, messages, ...data }: { id: string; messages: INotificationMessage[] } = {
      ...currentNotification,
      ...getValues(),
      ...(currentNotification?.messages ? { messages: currentNotification.messages } : {}),
    };
    const messagesError = validateMessages(messages);

    if (messagesError) {
      setMessageValidateError(messagesError);
      return;
    }

    setMessageValidateError(undefined);
    setNotification({
      variables: {
        notificationId: id,
        data,
      },
    }).then(() => {
      setMessages({
        variables: {
          notificationId: id,
          data: messages.map(message => {
            const { id, ...data } = message;
            return data;
          }),
        },
      }).then(() => {
        handleClose();
      });
    });
  };

  const validateMessages = (messages: INotificationMessage[]) => {
    if (messages.length === 0) {
      return '메세지가 없습니다';
    }

    const dupCheck: NotificationTypeEnum[] = [];
    const notification = {
      ...currentNotification,
      ...getValues(),
    };
    for (const msg of messages) {
      if (dupCheck.find(elem => elem === msg.type)) {
        return `중복되는 타입의 메세지가 있습니다 (${makeNotificationTypeString(msg.type)})`;
      }
      if (notification?.isCommon && msg.type !== NotificationTypeEnum.COMMON) {
        return '공통 메세지를 사용하는 알림에는 공통 메세지 외 다른 타입의 메세지를 설정할 수 없습니다.';
      }
      if (!notification?.isCommon && msg.type === NotificationTypeEnum.COMMON) {
        return '공통 메세지를 사용하지 않는 알림에는 공통 메세지 타입의 메세지를 설정할 수 없습니다.';
      }
      dupCheck.push(msg.type);
    }

    return;
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        TransitionProps={{
          onEnter: handleOpen,
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>알림 수정</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {(loading || !isNotificationFormReady) && <LinearProgress />}
            {error && <Alert severity="error">{error.message}</Alert>}
            {!loading && isNotificationFormReady && currentNotification && (
              <NotificationEditDialogForm
                formRegister={formRegister}
                formErrors={formErrors}
                formWatch={formWatch}
                onUpdateMessage={onUpdateMessage}
                messageValidateError={messageValidateError}
                data={currentNotification}
              />
            )}
          </form>
          <TestMessageDialog open={testMessageDialogOpen} handleClose={() => setTestMessageDialogOpen(false)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSendTest()} color="inherit" startIcon={<FeedbackIcon />}>
            테스트메세지 전송
          </Button>
          <div style={{ flex: '1 0 0' }} />
          <Button onClick={() => handleClose()} color="inherit">
            취소
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            적용
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationItemDialogContainer;
