import { Switch, Typography } from '@mui/material';
import { ChaiListPage, ChaiListTableColumn } from 'common';
import { format } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';
import { UserTagDialog } from './UserTagDialog';
import { getUserTagListQuery, updateUserTagMutate } from 'api/userTagGql';
import { useMutation } from '@apollo/client';
import { store as reducers } from 'reducers';
import { addError, addSuccess } from 'state/notistackState';
interface UserTagListColumn extends ChaiListTableColumn {
  id: 'id' | 'name' | 'status' | 'dateHistory.createdAt' | 'dateHistory.updatedAt';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any, row: any) => string | JSX.Element;
}

const getColumns: (handleSwitchStatus: any) => UserTagListColumn[] = handleSwitchStatus => {
  return [
    {
      id: 'id',
      label: '태그ID',
      format: value => (
        <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
          {value}
        </Typography>
      ),
    },
    {
      id: 'name',
      label: '태그 이름',
      format: (value: any, row) => (
        <>
          <Typography component="span">{value}</Typography>
          {row.category === 'amplitude' ? (
            <Typography variant="caption" sx={{ color: 'red' }}>
              &nbsp;*amplitude
            </Typography>
          ) : undefined}
        </>
      ),
    },
    {
      id: 'dateHistory.createdAt',
      label: '생성 날짜',
      format: (value: any) => format(new Date(value), 'yyyy-MM-dd'),
    },
    {
      id: 'dateHistory.updatedAt',
      label: '수정 날짜',
      format: (value: any) => format(new Date(value), 'yyyy-MM-dd'),
    },
    {
      id: 'status',
      label: '태그 상태',
      format: (value: any, row) => (
        <Switch defaultChecked={value === 'enabled'} color="primary" onClick={() => handleSwitchStatus(row)} />
      ),
    },
  ];
};

export const UserTagListContainer: React.FC<{}> = props => {
  const { userTagId } = useParams<{ userTagId?: string }>();

  const [updateUserTagStatus] = useMutation(updateUserTagMutate, {
    onCompleted: () => {
      reducers.dispatch(addSuccess(`부스트 태그 상태가 업데이트 되었습니다.`));
    },
    onError: err => {
      reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
    },
  });

  const handleSwitchStatus = (row: any) => {
    updateUserTagStatus({
      variables: {
        tagName: row.name,
        status: row.status === 'enabled' ? 'disabled' : 'enabled',
      },
    });
  };

  const columns = getColumns(handleSwitchStatus);

  return (
    <ChaiListPage
      path="/tag/userTag/"
      query={getUserTagListQuery}
      getQueryFilter={keyword => (keyword ? JSON.stringify({ tagName: keyword }) : '')}
      childId={userTagId}
      columns={columns}
      dialog={UserTagDialog}
    />
  );
};
