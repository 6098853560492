import { Button, Switch, Dialog, Typography, Chip } from '@mui/material';
import { getBoostUpListQuery } from 'api/boostUpGql';
import { ChaiListPage } from 'common';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BoostUpColumn } from 'type/boost/boostUp';
import { BoostUpDialog } from './BoostUpDialog';
import { BoostUpDescriptionDialog } from './BoostUpDescriptionDialog';
import { format } from 'date-fns';

const getColumns: (handleChangeStatus: any) => BoostUpColumn[] = (handleChangeStatus: any) => {
  return [
    {
      id: 'id',
      label: '폴리시 ID',
      format: value => (
        <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
          {value}
        </Typography>
      ),
    },
    {
      id: 'title',
      label: '타이틀',
      format: value => (
        <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
          {value}
        </Typography>
      ),
    },
    {
      id: 'startAt',
      label: '구매가능 시간',
      format: (_, row) => `${row.startAt} ~ ${row.endAt}`,
    },
    {
      id: 'range',
      label: '폴리시 적용 기간',
      format: (_, row) =>
        row.visibleFrom && row.visibleTo
          ? `${format(new Date(row.visibleFrom), 'yyyy-MM-dd')} ~ ${format(new Date(row.visibleTo), 'yyyy-MM-dd')}`
          : '',
    },
    {
      id: 'status',
      label: '상태',
      format: (value, row) => {
        const isEnabled = value === 'enabled';
        return <Chip size="small" label={isEnabled ? '활성' : '비활성'} color={isEnabled ? 'primary' : 'default'} />;
      },
    },
  ];
};

export const BoostUpListContainer: React.FC<{}> = props => {
  const { upId } = useParams<{ upId?: string }>();
  const history = useHistory();
  const [commonDescriptionDialogOpen, setCommonDescriptionDialogOpen] = React.useState(false);

  const handleChangeStatus = async (id: number, targetStatus: string) => {
    // await apolloClient.query({
    //   mutation: brandChangeContentVisible,
    //   variables: {
    //     contentId: row.id,
    //     status: visible ? 'visible' : 'invisible',
    //   },
    // });
    // await apolloClient.mutate({
    //   mutation: brandChangeContentVisible,
    //   variables: {
    //     contentId: row.id,
    //     status: visible ? 'visible' : 'invisible',
    //   },
    // });
  };

  const handleEditDescription = () => {
    setCommonDescriptionDialogOpen(true);
    history.push(`/boost/up/common-description`);
  };

  return (
    <>
      <ChaiListPage
        path="/boost/up/"
        query={getBoostUpListQuery}
        getQueryFilter={keyword => keyword || ''}
        childId={upId}
        columns={getColumns(handleChangeStatus)}
        dialog={BoostUpDialog}
        bottomButton={
          <Button variant="outlined" onClick={handleEditDescription}>
            공통 유의사항 편집
          </Button>
        }
      />
      <Dialog maxWidth="md" open={commonDescriptionDialogOpen} fullWidth>
        <BoostUpDescriptionDialog setDialogStatus={setCommonDescriptionDialogOpen} />
      </Dialog>
    </>
  );
};
