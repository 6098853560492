import { Grid, Box, Paper, Typography, Chip } from '@mui/material';
import React from 'react';
import { contrastTextColor } from 'util/contrastTextColor';
// import boltIcon from 'asset/bolt.png';

const previewWidth = 215;
const previewMinHeight = 450;

const BoostPreviewComponent: React.FC<{
  name: string;
  logoImg?: string;
  subText?: string;
  smallText?: string;
  bolt?: number | string;
  center?: boolean;
  color?: string;
  type?: string;
}> = ({ name, logoImg, subText, smallText, bolt, center, color, type }) => {
  return (
    <Grid
      container
      direction="column"
      {...(center ? { alignItems: 'center', justify: 'center' } : {})}
      style={{ minHeight: previewMinHeight }}
    >
      <Grid item>
        <Paper style={{ ...styles.container, backgroundColor: color || 'silver', color: contrastTextColor(color) }}>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item style={{ ...styles.logo, backgroundImage: logoImg && `url(${logoImg})` }} />
            {type && (
              <>
                <Box m={1} />
                <Chip
                  size="small"
                  variant="outlined"
                  sx={{ backgroundColor: '#ebebeb' }}
                  label={typeLabel[type] || '타입 오류'}
                />
              </>
            )}
            <Box m={2} />
            <Typography variant="subtitle2">{name}</Typography>
            {subText && (
              <>
                <Box style={{ height: 4 }} />
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {subText}
                </Typography>
              </>
            )}
            <Box style={{ height: 4 }} />
            {smallText && <Typography variant="subtitle2">{smallText}</Typography>}
            {bolt && <Box m={1} />}
            {bolt && (
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Box style={{ color: 'transparent', textShadow: '0 0 0 ' + contrastTextColor(color) }}>⚡ {bolt}</Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const typeLabel: { [Key: string]: string } = {
  standard: '일반 부스트',
  fcfs: '선착순 부스트',
  time_attack: '타임어택 부스트',
  recurring: '정기결제 부스트',
  mission: '미션',
};

interface StylesDictionary {
  [Key: string]: React.CSSProperties;
}

const styles: StylesDictionary = {
  container: {
    padding: 36,
    borderRadius: 8,
    maxWidth: previewWidth,
    color: 'white',
  },
  logo: {
    width: 128,
    height: 128,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};

export default BoostPreviewComponent;
