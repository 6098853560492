import { useQuery } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, Switch } from '@mui/material';
import {
  brandChangeContentVisible,
  brandContentListQuery,
  brandDeleteContent,
  brandSwapContentPriority,
  brandUploadContent,
} from 'api/brandGql';
import { ChaiListTableColumn, ChaiSimpleListTable } from 'common';
import { useAlert } from 'common/context/alert';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import apolloClient from 'util/apolloClient';
import { BoostParams } from './BoostContentContainer';

const STATUS_STRINGS: { [key: string]: string } = {
  publish: '처리 완료',
  canceled: '취소됨',
  error: '오류',
  deleted: '삭제됨',
  default: '처리 중',
};

const getColumn = (
  handleDelete: (row: BrandMediaContent) => void,
  handleChangeVisibility: (row: BrandMediaContent, visible: boolean) => void,
  handleSwap: (row: BrandMediaContent, direction: 'up' | 'down') => void
) => {
  const columns: ChaiListTableColumn[] = [
    {
      id: 'id',
      label: 'ID',
    },
    {
      id: 'sourceFileName',
      label: '원본 파일명',
    },
    {
      id: 'bucketFileName',
      label: '파일명',
      format: (value, row) => {
        if (row.urls.length) {
          return (
            <>
              {row.urls.map(({ url }: { url: string }) => {
                return (
                  <>
                    <a href={url} rel="noopener noreferrer" target="_blank">
                      {value}
                    </a>
                    <br />
                  </>
                );
              })}
            </>
          );
        }
        return <>{value}</>;
      },
    },
    {
      id: 'priority',
      label: '우선순위 변경',
      format: (value, row) => {
        return (
          <>
            <IconButton size="small" onClick={() => handleSwap(row, 'up')}>
              <Icon>expand_less</Icon>
            </IconButton>
            <IconButton size="small" onClick={() => handleSwap(row, 'down')}>
              <Icon>expand_more</Icon>
            </IconButton>
          </>
        );
      },
    },
    {
      id: 'visible',
      label: '공개',
      format: (value, row) => {
        return (
          <Switch
            checked={row.visible === 'visible'}
            onChange={(event, visible) => {
              handleChangeVisibility(row, visible);
            }}
            color="primary"
          />
        );
      },
    },
    {
      id: 'status',
      label: '상태',
      format: status => (status in STATUS_STRINGS ? STATUS_STRINGS[status] : STATUS_STRINGS.default),
    },
    {
      id: 'delete',
      label: '',
      format: (value, row) => (
        <IconButton
          size="small"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            handleDelete(row);
          }}
        >
          <Icon>highlight_off</Icon>
        </IconButton>
      ),
    },
  ];
  return columns;
};

export interface BrandMediaContent {
  id: number;
  priority: number;
  visible: 'vieible' | 'invisible';
  sourceFileName: string;
  bucketFileName: string;
}

export const BoostInformationDialogMediaContent: React.FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const alert = useAlert();
  const { brandId } = useParams<BoostParams>();
  const { data, refetch: refetchBrandInfo } = useQuery(brandContentListQuery, {
    variables: { id: Number(brandId) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: brandId === 'new' ? 'cache-only' : 'network-only', // apollo-client 버그 walk-around
  });
  const contentList: BrandMediaContent[] = data?.brandContentList?.list || [];
  const sortedContentList: BrandMediaContent[] = contentList
    .slice(0, contentList.length)
    .sort((a, b) => a.priority - b.priority);
  const { register, watch } = useForm<{ mediaFile: FileList }>({
    shouldUnregister: false,
  });

  const file: FileList = watch('mediaFile');
  useEffect(() => {
    const uploadFile = async (file: File) => {
      try {
        await apolloClient.mutate({
          mutation: brandUploadContent,
          variables: {
            brandId: Number(brandId),
            file,
            sourceFileName: file.name,
            visible: 'invisible', // 'visible'
          },
        });
        refetchBrandInfo();
      } catch {}
      return;
    };

    if (file?.[0]) {
      uploadFile(file?.[0]);
    }
  }, [brandId, file, refetchBrandInfo]);

  useEffect(() => {
    refetchBrandInfo();
  }, [refetchBrandInfo]);

  const handleDelete = (row: BrandMediaContent) => {
    alert.confirm('삭제', `${row.sourceFileName} 파일을 삭제하겠습니까?`, async () => {
      await apolloClient.mutate({
        mutation: brandDeleteContent,
        variables: {
          contentId: row.id,
        },
      });
      refetchBrandInfo();
    });
  };

  const handleChangeVisibility = async (row: BrandMediaContent, visible: boolean) => {
    await apolloClient.mutate({
      mutation: brandChangeContentVisible,
      variables: {
        contentId: row.id,
        visible: visible ? 'visible' : 'invisible',
      },
    });
    refetchBrandInfo();
  };

  const handleSwap = async (row: BrandMediaContent, direction: 'up' | 'down') => {
    let targetRowId: number = -1;
    const currentRowIdx = sortedContentList?.findIndex(item => item.id === row.id);
    if (direction === 'up') {
      if (currentRowIdx < 1) {
        return;
      }
      targetRowId = sortedContentList[currentRowIdx - 1]?.id || -1;
    }
    if (direction === 'down') {
      if (currentRowIdx + 1 === sortedContentList.length) {
        return;
      }
      targetRowId = sortedContentList[currentRowIdx + 1]?.id || -1;
    }

    if (targetRowId !== -1) {
      await apolloClient.mutate({
        mutation: brandSwapContentPriority,
        variables: {
          contentId: row.id,
          contentIdToSwap: targetRowId,
        },
      });
    }
    refetchBrandInfo();
  };

  return (
    <>
      <DialogTitle>브랜드 미디어 콘텐츠</DialogTitle>
      <DialogContent>
        <ChaiSimpleListTable
          hideHeader={true}
          name="brandMediaContentList"
          columns={getColumn(handleDelete, handleChangeVisibility, handleSwap)}
          list={sortedContentList}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button color="primary" variant="outlined" component="label">
              컨텐츠 추가
              <input {...register('mediaFile')} type="file" hidden />
            </Button>
          </Grid>
          <Grid>
            <Button onClick={handleClose} color="primary">
              닫기
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
