import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Alert } from '@mui/lab';
import { ChaiCheckboxForm, ChaiFormGroup, ChaiTextForm } from 'common';
import { useAlert } from 'common/context/alert';
import React, { useState } from 'react';
import { INotificationData, INotificationMessage, NotificationTypeEnum } from 'type/api/notification';
import MessageCard from '../component/MessageCard';
import styles from '../notification.module.scss';
import { makeNotificationTypeString } from '../NotificationContainer';
import MessageEditDialogContainer from './MessageEditDialogContainer';

const NotificationEditDialogForm: React.FC<{
  data?: INotificationData;
  formRegister: any;
  formErrors?: any;
  formWatch: any;
  onUpdateMessage: (idx: number, values?: INotificationMessage) => void;
  messageValidateError: string | undefined;
}> = ({ data, formRegister, formErrors, formWatch, messageValidateError, onUpdateMessage }) => {
  const alert = useAlert();
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [editDialogTargetMessage, setEditDialogTargetMessage] = useState<INotificationMessage>();
  const [editDialogTargetIdx, setEditDialogTargetIdx] = useState<number>(0);
  const [newMessageCounter, setNewMessageCounter] = useState<number>(0);

  const openEditDialog = (idx: number, message: INotificationMessage) => {
    setEditDialogTargetMessage(message);
    setEditDialogTargetIdx(idx);
    setEditDialogOpen(true);
  };

  const handleUpdateMessage = (values: INotificationMessage) => {
    onUpdateMessage(editDialogTargetIdx, { ...editDialogTargetMessage, ...values });
    setEditDialogOpen(false);
  };

  const addMessage = () => {
    const newMessage = {
      id: `new-${newMessageCounter}`,
      type: NotificationTypeEnum.COMMON,
    };
    const newIdx = data?.messages.length ?? 0;
    onUpdateMessage(newIdx, newMessage);
    openEditDialog(newIdx, newMessage);
    setNewMessageCounter(newMessageCounter + 1);
  };

  const copyMessage = (msg: INotificationMessage) => {
    if (!data?.messages) {
      return;
    }
    const newMessage = {
      ...msg,
      id: `new-${newMessageCounter}`,
    };
    const newIdx = data.messages.length;
    onUpdateMessage(newIdx, newMessage);
    setNewMessageCounter(newMessageCounter + 1);
  };

  const deleteMessage = (idx: number) => {
    // 메세지 삭제
    onUpdateMessage(idx, undefined);
  };

  const isFcm = formWatch('isFcm');
  const isKakao = formWatch('isKakao');
  const isSms = formWatch('isSms');
  const defaultMessageTargets: string[] = [];

  isFcm &&
    !data?.messages.find(msg => msg.type === NotificationTypeEnum.FCM) &&
    defaultMessageTargets.push(makeNotificationTypeString(NotificationTypeEnum.FCM));
  isKakao &&
    !data?.messages.find(msg => msg.type === NotificationTypeEnum.KAKAO) &&
    defaultMessageTargets.push(makeNotificationTypeString(NotificationTypeEnum.KAKAO));
  isSms &&
    !data?.messages.find(msg => msg.type === NotificationTypeEnum.SMS) &&
    defaultMessageTargets.push(makeNotificationTypeString(NotificationTypeEnum.SMS));

  const makeCardTitle = (msg: INotificationMessage) => {
    return `${makeNotificationTypeString(msg.type)}${
      msg.type === NotificationTypeEnum.COMMON
        ? ` (전송: ${defaultMessageTargets.length ? defaultMessageTargets.join(', ') : '대상 없음'})`
        : ''
    }`;
  };

  const handleCloseMessageDialog = () => {
    // 업데이트 없이 새로운 메세지를 만든 후 모달을 닫은 경우 대상 메세지를 삭제
    if (!(editDialogTargetMessage?.subject && editDialogTargetMessage?.content)) {
      onUpdateMessage(editDialogTargetIdx, undefined);
    }
    setEditDialogOpen(false);
  };

  return (
    <>
      <ChaiTextForm name="notification-category" label="카테고리" defaultValue={data?.category} disabled />
      <ChaiTextForm name="notification-code" label="구분코드" defaultValue={data?.code} disabled />
      <ChaiTextForm
        name="notification-variables"
        label="변수"
        defaultValue={['fullname', ...(data?.variables || [])].join(', ')}
        disabled
        multiline
      />
      <ChaiTextForm
        type="number"
        name="delay"
        label="딜레이 (단위: 분)"
        defaultValue={data?.delay}
        register={formRegister}
        errors={formErrors}
        min={0}
        max={60}
      />
      <Box m={1} />
      <Typography variant="caption" color="textSecondary">
        전송 방법 (좌측부터 시도 후 전송 실패 시 오른쪽 방법으로 시도)
      </Typography>
      <Box />
      <ChaiFormGroup>
        <ChaiCheckboxForm register={formRegister} name="isFcm" label="푸시알림 전송" defaultValue={data?.isFcm} />
        <ChaiCheckboxForm register={formRegister} name="isKakao" label="카카오 전송" defaultValue={data?.isKakao} />
        <ChaiCheckboxForm register={formRegister} name="isSms" label="문자 전송" defaultValue={data?.isSms} />
      </ChaiFormGroup>
      <Box />
      <Typography variant="caption" color="textSecondary">
        *공통 메세지를 사용할 경우 타입별 메세지를 지정할 수 없습니다.
      </Typography>
      <Box />
      <ChaiCheckboxForm
        register={formRegister}
        name="isCommon"
        label="공통 메세지 사용"
        defaultValue={data?.isCommon}
      />
      {messageValidateError && (
        <>
          <Box m={2} />
          <Alert severity="error">{messageValidateError}</Alert>
        </>
      )}

      <Box m={2} />
      <Grid container direction="row" spacing={2}>
        {/* 메세지 카드들 */}
        {data?.messages.map((msg, idx) => (
          <Grid item xs={12} sm={6} className={styles.listItemWrapper} key={`notification-message-${msg.id}`}>
            <Card>
              <CardHeader
                subheader={makeCardTitle(msg)}
                action={
                  <>
                    <IconButton aria-label="복사" size="small" onClick={() => copyMessage(msg)}>
                      <FileCopyIcon />
                    </IconButton>
                    <IconButton
                      aria-label="삭제"
                      size="small"
                      onClick={() => alert.confirm('삭제', '메세지를 삭제합니까?', () => deleteMessage(idx))}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              />
              <CardContent onClick={() => openEditDialog(idx, msg)} className={styles.cursorPointer}>
                <MessageCard msg={msg} />
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} className={styles.listItemWrapper} key={`notification-message-add`}>
          <Card>
            <CardHeader subheader={'메세지 추가'} />
            <CardContent onClick={() => addMessage()} className={styles.cursorPointer}>
              <Button fullWidth size="large">
                <AddBoxIcon color="primary" />
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* 메세지 수정 팝업 */}
      <MessageEditDialogContainer
        open={editDialogOpen}
        data={data}
        handleUpdateMessage={handleUpdateMessage}
        handleClose={handleCloseMessageDialog}
        editDialogTargetMessage={editDialogTargetMessage}
      />
    </>
  );
};

export default NotificationEditDialogForm;
