import { ChaiListTableColumn } from 'common';

export interface IBoostUpForm {
  id: number;
  possibleMoments: ('after_buy_boost' | 'before_buy_boost' | 'manual_use_boost')[];
  title: string;
  description: string;
  budget: number;
  dailyBudget: number;
  status: BoostUpPolicyStatus.ENABLED | BoostUpPolicyStatus.DISABLED;
  boltPrice: number;
  boostUpSchemePolicies: BoostUpSchemePolicy[];
  startAt: string;
  endAt: string;
  visibleDateSet: 'enabled' | 'disabled';
  visibleFromDate?: string;
  visibleFromTime?: string;
  visibleToDate?: string;
  visibleToTime?: string;
  targetTypes: ('red' | 'credit_card' | 'e_wallet')[];
}

export interface IAddBoostUpInput extends IBoostUp {}

export interface IBoostUp {
  id: number;
  title: string;
  description: string;
  budget: number;
  dailyBudget: number;
  status: BoostUpPolicyStatus.ENABLED | BoostUpPolicyStatus.DISABLED;
  boltPrice: number;
  boostUpSchemePolicies: BoostUpSchemePolicy[];
  startAt: string;
  endAt: string;
  possibleMoments: ('after_buy_boost' | 'before_buy_boost' | 'manual_use_boost')[];
  targetTypes: ('red' | 'credit_card' | 'e_wallet')[];
  visibleFrom?: Date;
  visibleTo?: Date;
}

export interface IUpdateBoostUpInput extends IAddBoostUpInput {
  id: number;
}

export enum BoostUpPolicyStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export interface BoostUpSchemePolicy {
  id?: string; // DataGrid에서 사용하는 id값
  probability: number;
  cashbackAmountMin: number;
  cashbackAmountMax: number;
  cashbackAmountUnit: number;
  discountRate: number;
  resultType: 'win' | 'miss' | 'jackpot';
  level: number;
}

export interface BoostUpColumn extends ChaiListTableColumn {
  id:
    | 'id'
    | 'title'
    | 'status'
    | 'description'
    | 'possibleMoments'
    | 'budget'
    | 'dailyBudget'
    | 'startAt'
    | 'range'
    | 'boltPrice'
    | 'boostUpSchemePolicies';
}

export interface BoostUpSchemeColumn extends ChaiListTableColumn {
  id: 'result' | 'probability' | 'cashbackAmountMin' | 'cashbackAmountMax' | 'cashbackAmountUnit' | 'discountRate';
}
