import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Link,
  Typography,
} from '@mui/material';
import { getBoltDrawQuery, IBoltDrawUser } from 'api/boltDrawGql';
import { ChaiTextForm, ChaiSimpleListTable, ChaiListTableColumn } from 'common';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';

const headers = [
  { label: '순서', key: 'index' },
  { label: 'User Id', key: 'id' },
  { label: 'User DI', key: 'DI' },
  { label: '이름', key: 'fullname' },
  { label: '연락처', key: 'phone' },
  { label: '응모 번개 수', key: 'boltCount' },
];

const columns: ChaiListTableColumn[] = [
  {
    id: 'id',
    label: '당첨자 Id',
    format: value => (
      <Link
        href={`https://admin.chai.finance/user/CS-${value}`}
        underline="hover"
        style={{ maxWidth: 180, wordWrap: 'break-word' }}
      >
        {value}
      </Link>
    ),
  },
  {
    id: 'DI',
    label: '당첨자 DI',
    format: value => (
      <Typography style={{ maxWidth: 180, wordWrap: 'break-word', fontSize: '12px' }}>{value}</Typography>
    ),
  },
  {
    id: 'fullname',
    label: '이름',
  },
  {
    id: 'phone',
    label: '연락처',
  },
  {
    id: 'boltCount',
    label: '응모 번개 수',
  },
];

export const BoltDrawDialog: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory();

  const { loading, error, data } = useQuery(getBoltDrawQuery, {
    variables: { id: +id },
  });

  const boltDrawPromotion = data?.boltDrawPromotion;
  const userList = boltDrawPromotion?.boltDraws;
  const targetUserList = userList?.map((info: IBoltDrawUser, index: number) => ({
    index: index + 1,
    ...info,
    ...info.user,
    ...info.user.koreaSpec,
  }));

  const handleClose = () => {
    history.push('/bolt-draw/');
  };

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <strong>에러가 발생했습니다. 다시 시도해 주세요.</strong>
      </Alert>
    );
  }

  if (loading) {
    return <Box mx="auto" my={5} component={CircularProgress} />;
  }

  return (
    <>
      <DialogTitle>{boltDrawPromotion.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={3} item>
            <ChaiTextForm label="번개 드로우 id" value={boltDrawPromotion.id} readOnly />
          </Grid>
          <Grid xs={3} item>
            <ChaiTextForm label="상태" value={boltDrawPromotion.status} readOnly />
          </Grid>
          <Grid xs={2} item>
            <ChaiTextForm label="총 응모자 수" value={boltDrawPromotion.totalUserCount} readOnly />
          </Grid>
          <Grid xs={2} item>
            <ChaiTextForm label="당첨자 수" value={boltDrawPromotion.winnerCount} readOnly />
          </Grid>
          <Grid xs={2} item>
            <ChaiTextForm label="총 응모 번개 수" value={boltDrawPromotion.totalBoltCount} readOnly />
          </Grid>
        </Grid>
        <Box m={1} />
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <ChaiTextForm
              label="노출 기간"
              value={
                format(new Date(boltDrawPromotion.visibleFrom), 'yyyy/MM/dd hh:mm:ss') +
                ' - ' +
                format(new Date(boltDrawPromotion.visibleTo), 'yyyy/MM/dd hh:mm:ss')
              }
              readOnly
            />
          </Grid>
          <Grid xs={6} item>
            <ChaiTextForm
              label="응모 기간"
              value={
                format(new Date(boltDrawPromotion.enterableFrom), 'yyyy/MM/dd hh:mm:ss') +
                ' - ' +
                format(new Date(boltDrawPromotion.enterableTo), 'yyyy/MM/dd hh:mm:ss')
              }
              readOnly
            />
          </Grid>
        </Grid>
        <Box m={3} />
        <Grid container spacing={1} style={{ height: '300px', overflow: 'auto' }}>
          <Grid xs={12} item>
            <ChaiSimpleListTable
              name="userTagUserList"
              headerName="유저ID 목록"
              hideHeader
              columns={columns}
              list={targetUserList || []}
              size="small"
              rowsPerPageOptions={[10, 20, 50]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: '0px 30px 15px' }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <CSVLink headers={headers} data={targetUserList} filename={`bolt-draw-${boltDrawPromotion.title}.csv`}>
              <Button variant="outlined" startIcon={<Icon>cloud_download</Icon>}>
                CSV 다운로드
              </Button>
            </CSVLink>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => handleClose()}>
              닫기
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
