import { DocumentNode, useLazyQuery } from '@apollo/client';
import { Dialog, Grid, TableCell, TableRow } from '@mui/material';
import { ChaiListTable, ChaiListTableColumn } from 'common';
import React, { useEffect, ReactNode } from 'react';
import { get } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { IGraphListData } from 'type/common';

interface IComponentProps {
  path: string;
  query: DocumentNode;
  getQueryFilter?: (keyword: string | undefined) => string;
  childId?: string | undefined;
  columns: ChaiListTableColumn[];
  dialog?: React.FC<{ id: string; onClose: () => void }>;
  dialogSize?: 'md' | 'lg';
  bottomButton?: JSX.Element;
  hideHeader?: boolean;
  hideAddButton?: boolean;
  table?: string;
}

export const ChaiListPage = <T extends {}>({
  path,
  table = '',
  query,
  childId,
  columns,
  dialog: ChildDialog,
  dialogSize = 'md',
  bottomButton,
  hideHeader,
  hideAddButton,
  getQueryFilter = keyword => (keyword ? JSON.stringify({ title: keyword }) : ''),
}: IComponentProps & { children?: ReactNode }) => {
  const dialogOpen = typeof childId !== 'undefined';
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchKeyword, setSearchKeyword] = React.useState<string | undefined>();

  const [loadListQuery, { data, refetch, called }] = useLazyQuery<IGraphListData<T>>(query, {
    notifyOnNetworkStatusChange: true,
    variables: {
      skip: page * rowsPerPage,
      pageSize: rowsPerPage,
      filter: getQueryFilter(searchKeyword),
      table,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const getGqlData: (data?: IGraphListData<T>) => { list: T[]; total: number } = data => {
    if (!data) {
      return { list: [], total: 0 };
    }
    const list = Object.entries(data)?.[0]?.[1]?.list || [];
    const total = Object.entries(data)?.[0]?.[1]?.total || 0;
    return { list, total };
  };

  const { list, total: listTotal } = getGqlData(data);

  const handleAdd = () => {
    history.push(path + 'new');
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handleClickTableRow = (event: React.MouseEvent, id: number) => {
    const target = event.target as HTMLElement;

    if (target.getAttribute('type') === 'checkbox') {
      return;
    }

    history.push(`${path}${id}`);
  };

  // 리스트 경로에 들어올 때 리스트 갱신
  useEffect(() => {
    if (!childId) {
      called ? refetch() : loadListQuery();
    }
  }, [childId, loadListQuery, called, refetch]);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <ChaiListTable
            total={listTotal}
            columns={columns}
            rowsPerPage={rowsPerPage}
            page={page}
            handleAdd={handleAdd}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleOnChangeSearch={handleOnChangeSearch}
            bottomButton={bottomButton}
            hideHeader={hideHeader}
            hideAddButton={hideAddButton}
          >
            {list.map((row: any) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`list-item-${row.id ? row.id : row.sortKey}`}
                  onClick={e => handleClickTableRow(e, row.id || row.sortKey)}
                  sx={{ cursor: 'pointer' }}
                >
                  {columns.map(column => {
                    const value = get(row, column.id);
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value, row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </ChaiListTable>
        </Grid>
      </Grid>
      {typeof childId === 'string' && (
        <Dialog
          maxWidth={dialogSize}
          open={dialogOpen}
          fullWidth
          scroll="paper"
          onClose={(_, reason) => reason !== 'backdropClick' && history.push(path)}
        >
          {ChildDialog && <ChildDialog id={childId} onClose={() => history.push(path)} />}
        </Dialog>
      )}
    </>
  );
};
