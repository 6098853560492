import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { brandUploadImageMutation } from 'api/brandGql';
import { ChaiTextContextForm } from 'common';
import ChaiDivider from 'common/component/ChaiDivider';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import apolloClient from 'util/apolloClient';

// 2페이지: 브랜드 정보 확인/변경
// 브랜드 정보
export const BoostPromotionBrandInformation: React.FC<{}> = () => {
  const { register, setValue, watch } = useFormContext<any>();
  const logoFile: FileList = watch('logoFile');

  useEffect(() => {
    const uploadImageFile = async (file: File) => {
      try {
        const result = await apolloClient.mutate({
          mutation: brandUploadImageMutation,
          variables: {
            imageFile: file,
          },
        });
        const { brandUploadImage: uploadedFile } = result.data;
        const newFilePath = `https://static.chai.finance/${uploadedFile.path}/${uploadedFile.filename}`;
        setValue('logoImageUrl', newFilePath);
        return newFilePath;
      } catch {}
      return;
    };

    if (logoFile?.[0]) {
      uploadImageFile(logoFile?.[0]);
    }
  }, [logoFile, setValue]);

  return (
    <>
      <ChaiTextContextForm label="부스트 이름" name="subTitle" required shrink />
      <ChaiTextContextForm
        label="라운드 로고 이미지"
        name="logoImageUrl"
        endAdornment={
          <InputAdornment position="end">
            <input {...register('logoFile')} id="brand-info-logo-file" type="file" accept="image/*" hidden />
            <label htmlFor="brand-info-logo-file">
              <IconButton component="span">
                <CloudUploadIcon fontSize="small" />
              </IconButton>
            </label>
          </InputAdornment>
        }
      />
      <Box m={6} />
      <Typography variant="subtitle2" color="textSecondary">
        브랜드 URL
      </Typography>
      <ChaiDivider />
      <ChaiTextContextForm name="landingUrl" label="Landing URL" />
      <ChaiTextContextForm name="fallbackUrl" label="Fallback URL" />
      <ChaiTextContextForm name="iosAppId" label="iOS 앱 아이디" />
      <ChaiTextContextForm name="androidPackage" label="안드로이드 패키지" />
      <Box m={2} />
    </>
  );
};
