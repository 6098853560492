import gql from 'graphql-tag';

export const getBoostImportantQuery = gql`
  query boostImportant($table: String!, $sortKey: String!) {
    boostImportant(table: $table, sortKey: $sortKey)
  }
`;

export const getBoostImportantListQuery = gql`
  query boostImportantList($table: String!, $filter: String) {
    boostImportantList(table: $table, filter: $filter) {
      list
      total
    }
  }
`;

export const addBoostImportantMutation = gql`
  mutation boostImportantAdd($table: String!, $type: String, $title: String!, $priority: Int!, $content: String!) {
    boostImportantAdd(table: $table, type: $type, title: $title, priority: $priority, content: $content)
  }
`;

export const updateBoostImportantMutation = gql`
  mutation boostImportantUpdate(
    $table: String!
    $prevSortKey: String!
    $sortKey: String!
    $type: String
    $title: String!
    $priority: Int!
    $content: String!
  ) {
    boostImportantUpdate(
      table: $table
      prevSortKey: $prevSortKey
      sortKey: $sortKey
      type: $type
      title: $title
      priority: $priority
      content: $content
    )
  }
`;

export interface IBoostImportant {
  content: string;
  priority: number;
  sortKey: string;
  type?: string;
  title: string;
  createdAt?: Date;
  updatedAt?: Date;
}
