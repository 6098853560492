import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface IMenuItem {
  path: string;
  label: string;
  icon: JSX.Element;
  isSub?: boolean;
  isOn?: boolean;
  isOpen?: boolean;
  placement?:
    | 'right'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export const MenuItem: React.FC<IMenuItem> = ({
  path,
  label,
  icon,
  isSub,
  isOn,
  isOpen = true,
  placement,
}: IMenuItem) => {
  const history = useHistory();

  function navigateTo(targetPath: string) {
    return () => history.push(targetPath);
  }

  return (
    <Tooltip title={!isOpen ? label : ''} placement={placement || 'right'}>
      <ListItem selected={isOn || false} button onClick={path ? navigateTo(path) : undefined}>
        <ListItemIcon style={{ minWidth: '42px', marginLeft: isSub ? '7px' : 0 }}>{icon}</ListItemIcon>
        {isOpen && <ListItemText primary={label} />}
      </ListItem>
    </Tooltip>
  );
};
