import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  TextField,
  Grid,
  Paper,
  Box,
  Hidden,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert } from '@mui/lab';
import { unwrapResult } from '@reduxjs/toolkit';
import { RootState, useAppDispatch } from 'reducers';

import logo from 'asset/logo.png';
import { requestOTP, login, getAuth } from 'state/loginState';
import { LoginAuthData } from 'api/loginApi';
import { useAuth } from 'auth';

type LoginForm = {
  id: string;
  password: string;
  code: string;
};

const LoginContainer = () => {
  const dispatch = useAppDispatch();
  const otpState = useSelector((state: RootState) => state.login.otp);
  const loginState = useSelector((state: RootState) => state.login.login);

  const auth = useAuth();
  const history = useHistory();

  const idRef: React.RefObject<HTMLInputElement> = React.createRef();
  const passRef: React.RefObject<HTMLInputElement> = React.createRef();
  const codeRef: React.RefObject<HTMLInputElement> = React.createRef();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [form, setForm] = useState<LoginForm>({
    id: '',
    password: '',
    code: '',
  });

  function handleFormChange(prop: keyof LoginForm) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [prop]: event.target.value });
    };
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleEnter(ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) {
    if (ev.key === 'Enter') {
      const { id, password, code } = form;

      if (!id) {
        return idRef?.current?.focus();
      }
      if (!password) {
        return passRef?.current?.focus();
      }
      if (!code) {
        handleOTP();
        return codeRef?.current?.focus();
      }
      handleLogin();
    }
  }

  function handleOTP() {
    const { id, password } = form;
    const { loading } = otpState;
    if (loading) {
      return false;
    }
    dispatch(requestOTP({ id, password }))
      .then(unwrapResult)
      .then(code => {
        setForm({
          ...form,
          code,
        });
      });
  }

  async function handleLogin() {
    const { id, password, code } = form;
    const { loading } = loginState;
    if (loading) {
      return false;
    }
    dispatch(login({ id, password, code }))
      .then(unwrapResult)
      .then(result => {
        if (result === 'ok') {
          dispatch(getAuth())
            .then(unwrapResult)
            .then((authResult: LoginAuthData) => {
              auth.updateAuth(authResult);
              history.push('/');
            });
        }
      });
  }

  return (
    <>
      {/* top paddings */}
      <Hidden xsDown>
        <Box height={1 / 6} />
      </Hidden>
      <Hidden smUp>
        <Box height={32} />
      </Hidden>
      {/* login form */}
      <Grid container justifyContent="center">
        <Box width={368}>
          <Paper>
            <Box padding={'24px'}>
              {/* Logo */}
              <Box height={36} />
              <Link to="/">
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <img alt="logo" src={logo} />
                  <span style={styles.title}>차이 어드민</span>
                </Grid>
              </Link>
              <Box height={24} />
              {/* Input: ID */}
              <FormControl fullWidth margin="dense">
                <InputLabel variant="standard" htmlFor="id">
                  아이디
                </InputLabel>
                <Input
                  id="id"
                  value={form.id}
                  inputRef={idRef}
                  onChange={handleFormChange('id')}
                  onKeyUp={handleEnter}
                />
              </FormControl>
              {/* Input: Password */}
              <FormControl fullWidth margin="dense">
                <InputLabel variant="standard" htmlFor="password">
                  비밀번호
                </InputLabel>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={form.password}
                  inputRef={passRef}
                  onChange={handleFormChange('password')}
                  onKeyUp={handleEnter}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {/* Input: OTP */}
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!(form.id && form.password)}
                      onClick={handleOTP}
                    >
                      PIN 요청
                    </Button>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      id="code"
                      label="PIN 입력 (6자리)"
                      variant="standard"
                      value={form.code}
                      onKeyUp={handleEnter}
                      onChange={handleFormChange('code')}
                      inputRef={codeRef}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box height={24} />
              {(otpState.error || loginState.error) && (
                <>
                  <Alert severity="error">{otpState.error || loginState.error}</Alert>
                  <Box height={24} />
                </>
              )}
              {/* Button: Submit */}
              <FormControl fullWidth margin="dense">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!(form.id && form.password && form.code)}
                  onClick={handleLogin}
                >
                  로그인
                </Button>
              </FormControl>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </>
  );
};

const styles = {
  logo: {
    height: '40px',
  },
  title: {
    fontSize: '30px',
    paddingLeft: '12px',
    color: '#000',
    fontFamily: "'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif",
    fontWeight: 600,
  },
};

export default LoginContainer;
