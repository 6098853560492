import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { categoryTagAddMutate, categoryTagQuery, categoryTagUpdateMutate } from 'api/boostTagGql';
import { ChaiTextForm } from 'common';
import { useAlert } from 'common/context/alert';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { store as reducers } from 'reducers';
import { addSuccess, addError } from 'state/notistackState';
import { CategoryTagContent } from './CategoryTagDialogContent';

export interface ICategoryTag {
  id?: number;
  name?: string;
  paymentTagIds: (ICategoryTagPaymentTag | undefined)[];
}

export interface ICategoryTagPaymentTag {
  id: number;
  name: string;
}

export const CategoryTagDialog: React.FC<{ id: string }> = ({ id }) => {
  const alert = useAlert();
  const history = useHistory();
  const [title, setTitle] = React.useState('');
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const methods = useForm<ICategoryTag>({
    shouldUnregister: false,
  });
  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
    setError,
  } = methods;
  const formValues = watch();
  const [getCategoryTag, { data }] = useLazyQuery(categoryTagQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { id: Number(id) },
    fetchPolicy: id === 'new' ? 'cache-only' : 'network-only',
  });

  useEffect(() => {
    if (id !== 'new') {
      setTitle('카테고리 태그 확인');
      getCategoryTag();
    } else {
      setTitle('카테고리 태그 추가');
      setIsEdit(true);
      reset({
        paymentTagIds: [undefined],
      });
    }
  }, [id, setTitle, reset, getCategoryTag]);

  useEffect(() => {
    if (data?.paymentCategoryTag) {
      const conditionData = data?.paymentCategoryTag;

      reset({
        name: conditionData.name,
        paymentTagIds:
          conditionData.paymentTags?.map((item: { id: number; name: string }) => ({ id: item.id, name: item.name })) ||
          [],
      });
    }
  }, [data, reset]);

  const handleClose = () => {
    history.push('/boost/categoryTag/');
  };

  const handleEdit = () => {
    setTitle('카테고리 태그 수정');
    setIsEdit(true);
  };

  const handleCancel = () => {
    if (id === 'new') {
      handleClose();
      return;
    }
    setTitle('카테고리 태그 확인');
    setIsEdit(false);

    // 폼 리셋
    const conditionData = data?.paymentCategoryTag;

    reset({
      name: conditionData.name,
      paymentTagIds:
        conditionData.paymentTags?.map((item: { id: number; name: string }) => ({ id: item.id, name: item.name })) ||
        [],
    });
  };

  const [addOrUpdateCategoryTag, { loading }] = useMutation(
    id === 'new' ? categoryTagAddMutate : categoryTagUpdateMutate,
    {
      onCompleted: () => {
        reducers.dispatch(addSuccess(`카테고리 태그가 업데이트 되었습니다.`));
        handleClose();
      },
      onError: err => {
        reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
        handleClose();
      },
    }
  );

  const onSubmit = async () => {
    const values = getValues();
    const dups: number[] = [];
    for (let i = 0; i < values.paymentTagIds.length; i += 1) {
      const targetId = values.paymentTagIds[i]?.id;
      if (!targetId) {
        return setError(`paymentTagIds.${i}.id`, { message: '필수값입니다.' });
      }
      if (dups.includes(targetId)) {
        return setError(`paymentTagIds.${i}.id`, { message: '중복된 값이 있습니다.' });
      }
      dups.push(targetId);
    }

    alert.confirm(
      '카테고리 태그',
      (id === 'new' ? '다음 카테고리 태그를 추가하시겠습니까?' : '다음 카테고리 태그를 수정하시겠습니까?') +
        `\n
        이름: ${values.name}\n
        결제태그: ${values.paymentTagIds.map(item => item?.name)}\n\n${
          id !== 'new' ? '*저장한 내용이 이전 결제 데이터에도 반영됩니다. 저장하시겠습니까?' : ''
        }`,
      () => {
        addOrUpdateCategoryTag({
          variables: {
            id: id === 'new' ? undefined : Number(id),
            name: values.name,
            paymentTagIds: values.paymentTagIds.map(item => item?.id),
          },
        });
      }
    );
  };

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ChaiTextForm register={register} errors={errors} label="이름" name="name" readOnly={!isEdit} shrink required />
        <Box m={2} />
        <Typography variant="subtitle2" color="textSecondary">
          조건
        </Typography>
        <Box m={2} />
        <FormProvider {...methods}>
          <CategoryTagContent content={formValues} isEdit={isEdit} />
        </FormProvider>
        <Box m={2} />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            {isEdit && (
              <>
                <Button onClick={() => handleCancel()} color="inherit">
                  취소
                </Button>
                <Button disabled={loading} onClick={handleSubmit(onSubmit)} color="primary">
                  저장
                </Button>
              </>
            )}
            {!isEdit && (
              <>
                <Button onClick={() => handleEdit()} color="primary">
                  수정
                </Button>
                <Button onClick={() => handleClose()} color="inherit">
                  닫기
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
