import { useLazyQuery, useMutation } from '@apollo/client';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { boostTagAddMutate, boostTargetConditionListQuery } from 'api/boostTagGql';
import { ChaiTextContextForm } from 'common';
import { useAlert } from 'common/context/alert';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import styles from '../boost.module.scss';
import { BoostTagCondition, BoostTagForm, BoostTagLogicalType, BoostTagType } from '../../Tag/BoostTag/BoostTagDialog';
import { BoostTagContent } from '../../Tag/BoostTag/BoostTagDialogContent';
import { BoostTagData } from '../../Tag/BoostTag/BoostTagListContainer';
import { BoostPromotionForm } from './BoostPromotionDialog';
import { store as reducers } from 'reducers';
import { addError } from 'state/notistackState';

// 5페이지: 부스트 태그
export const BoostPromotionBoostTag = () => {
  const alert = useAlert();
  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useFormContext<BoostPromotionForm>();
  const methods = useForm<BoostTagForm>({ shouldUnregister: false });
  const { reset, watch: watchBoostTagForm, handleSubmit, getValues } = methods;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const formValues = watchBoostTagForm();
  const [getBoostTagList, { data }] = useLazyQuery(boostTargetConditionListQuery, { variables: { pageSize: 99999 } });
  const boostTagList: BoostTagData[] = data?.boostTargetConditionList?.list || [];
  const inputValue = watch('boostTargetConditionId');
  const value = boostTagList?.find((item: any) => item.id === inputValue) || null;
  const [originalValue, setOriginalValue] = useState<BoostTagForm>();

  useEffect(() => {
    if (value) {
      const conditionData = value;
      const rootOperator = Object.keys(conditionData.condition)[0] as BoostTagLogicalType;
      const conditions: BoostTagCondition[] =
        conditionData.condition[rootOperator]?.map((item: any) => {
          if (item && typeof item === 'object') {
            const subOperator = Object.keys(item)[0] as BoostTagLogicalType;
            const subConditions = item[subOperator].map((subItem: number) => ({
              type: BoostTagType.SINGLE,
              value: subItem,
            })) as BoostTagCondition[];

            // group item
            return {
              type: BoostTagType.GROUP,
              subOperator,
              subConditions,
            };
          }
          return { type: BoostTagType.SINGLE, value: item };
        }) || [];

      const values: BoostTagForm = {
        rootOperator,
        conditions,
      };
      setOriginalValue(values);
      reset(values);
    }
  }, [value, reset]);

  useEffect(() => {
    if (JSON.stringify(originalValue) === JSON.stringify(formValues)) {
      setValue('boostTargetConditionChanged', false);
    } else {
      setValue('boostTargetConditionChanged', true);
    }
  }, [originalValue, formValues, setValue]);

  useEffect(() => {
    setValue('boostTargetConditionChanged', false);
    setOriginalValue(getValues());
    clearErrors();
  }, [inputValue, clearErrors, setValue, setOriginalValue, getValues]);

  useEffect(() => {
    getBoostTagList();
  }, [register, getBoostTagList]);

  const [addBoostTag, { loading }] = useMutation(boostTagAddMutate, {
    onCompleted: ({ boostTargetConditionAdd }) => {
      clearErrors();
      setOriginalValue(getValues());
      setValue('boostTargetConditionChanged', false);
      getBoostTagList();
      setValue('boostTargetConditionId', boostTargetConditionAdd);
      setIsEdit(false);
    },
    onError: err => reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.')),
  });

  const onSubmit = () => {
    const { rootOperator, conditions, title, description } = getValues();
    const payload = {
      [rootOperator]: conditions?.map(condition => {
        if (condition.subOperator && condition.subConditions) {
          return {
            [condition.subOperator]: condition.subConditions.map(subCondition => {
              return subCondition.value;
            }),
          };
        }
        return condition.value;
      }),
    };

    addBoostTag({ variables: { title, description, condition: payload } });
  };

  const handleAddBoostTag = () => {
    setValue('boostTargetConditionId', undefined);
    setIsEdit(true);
    reset({
      rootOperator: BoostTagLogicalType.AND,
      conditions: [{ type: BoostTagType.SINGLE }],
    });
  };

  const handleCancelBoostTagEdit = () => {
    setIsEdit(false);
    setValue('boostTargetConditionId', undefined);
  };

  return (
    <>
      <Autocomplete
        autoComplete
        autoHighlight
        options={boostTagList}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.title}
        onChange={(e, value) => {
          if (isEdit) {
            alert.confirm('태그 편집', '편집중인 태그의 변경 내용이 모두 사라집니다. 진행하시겠습니까?', () => {
              setIsEdit(false);
              setValue('boostTargetConditionId', value?.id);
            });
          } else {
            setValue('boostTargetConditionId', value?.id);
          }
        }}
        value={value}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="부스트 태그 찾기"
            variant="standard"
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: styles.backgroundWhite,
              },
            }}
            error={!!errors?.boostTargetConditionId}
            helperText={errors?.boostTargetConditionId?.message}
          />
        )}
      />
      <Box m={2} />
      <Box style={{ textAlign: 'right' }}>
        {!isEdit && (
          <>
            <Button color="primary" onClick={() => handleAddBoostTag()} variant="outlined">
              부스트 태그 추가
            </Button>
          </>
        )}
      </Box>
      <Box m={2} />
      <FormProvider {...methods}>
        {(value || isEdit) && (
          <>
            {isEdit && (
              <>
                <ChaiTextContextForm label="부스트 태그 이름" name="title" shrink required />
                <ChaiTextContextForm label="부스트 태그 설명" name="description" shrink required multiline />
                <Box m={2} />
              </>
            )}
            <BoostTagContent content={formValues} isEdit={isEdit} />
          </>
        )}
        {isEdit && (
          <Box style={{ textAlign: 'right' }}>
            <Box m={2} />
            <Button onClick={() => handleCancelBoostTagEdit()} variant="outlined" style={{ marginRight: 8 }}>
              취소
            </Button>
            <Button disabled={loading} color="primary" onClick={handleSubmit(onSubmit)} variant="outlined">
              부스트 태그 저장
            </Button>
          </Box>
        )}
      </FormProvider>
    </>
  );
};
