import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BoostMissionListContainer } from './BoostMissionListContainer';

export const BoostMission: React.FC<{}> = () => {
  return (
    <>
      <Switch>
        <Route path="/boost/mission/:missionId" render={() => <BoostMissionListContainer />} />
        <Route path="/" render={() => <BoostMissionListContainer />} />
      </Switch>
    </>
  );
};
