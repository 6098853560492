import { Button, Grid, Icon, Tooltip } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import ClassIcon from '@mui/icons-material/Class';
import PaymentIcon from '@mui/icons-material/Payment';
import ViewListIcon from '@mui/icons-material/ViewList';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import OfflineBoltSharpIcon from '@mui/icons-material/OfflineBoltSharp';

import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from 'asset/logo.png';
import { useAuth } from 'auth';
import { MenuItem } from './MenuItem';

import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export const drawerWidth = 240;
export const appBarHeight = 64;

const menus = [
  {
    path: '/notification',
    label: '알림 메세지/알림톡',
    icon: <ChatIcon />,
  },
  { path: '', label: '부스트 설정', icon: <OfflineBoltSharpIcon /> },
  { path: '/boost', label: '브랜드별 부스트 설정', icon: <ViewListIcon />, isSub: true },
  { path: '/boost/mission', label: '부스트 미션', icon: <Icon>assignment_late</Icon>, isSub: true },
  { path: '/boost/calendar', label: '부스트 캘린더', icon: <Icon>date_range</Icon>, isSub: true },
  { path: '/boost/up', label: '부스트 강화', icon: <Icon>plus_one</Icon>, isSub: true },
  { path: '', label: '부스트・결제 태그 관리', icon: <LocalOfferIcon /> },
  { path: '/boost/paymentTag', label: '결제 태그', icon: <PaymentIcon />, isSub: true },
  { path: '/boost/boostTag', label: '부스트 태그', icon: <StorefrontIcon />, isSub: true },
  { path: '/boost/categoryTag', label: '카테고리 태그', icon: <ClassIcon />, isSub: true },
  { path: '/tag/userTag', label: '유저 태그', icon: <Icon>assignment_ind</Icon>, isSub: true },
  { path: '', label: '유의사항 관리', icon: <Icon>menu_book</Icon> },
  { path: '/important/required', label: '필수 유의사항', icon: <Icon>description</Icon>, isSub: true },
  { path: '/important/optional', label: '선택 유의사항', icon: <Icon>description</Icon>, isSub: true },
  { path: '/bolt-draw', label: '번개 드로우 조회', icon: <Icon>bolt</Icon> },
  { path: '', label: '재무', icon: <Icon>savings</Icon> },
  { path: '/settlement', label: '정산 확정', icon: <Icon>check</Icon>, isSub: true },
  { path: '/settlementHistory', label: '정산 내역', icon: <ViewListIcon />, isSub: true },
];

function Menu() {
  const auth = useAuth();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);

  const doLogout = () => {
    auth.signOut();
  };

  const switchDrawerStatus = () => {
    setIsOpen(prev => !prev);
  };

  const handleKeyboardEvent = useCallback((event: any) => {
    if (event.code === 'Backslash' && event.ctrlKey) {
      switchDrawerStatus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvent);

    return () => {
      document.removeEventListener('keydown', handleKeyboardEvent);
    };
  }, [handleKeyboardEvent]);

  // hidemenu when login
  if (location.pathname === '/login') {
    return null;
  }

  const title = menus.reduce((acc, menu) => {
    return menu.path && location.pathname.indexOf(menu.path) === 0 ? menu.label : acc;
  }, '');

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={isOpen}>
        <Toolbar>
          <Tooltip
            title={
              <span>
                사이드바 열기 <br /> ctrl + \
              </span>
            }
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={switchDrawerStatus}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(isOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Box flexGrow="1">
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
          </Box>
          <Button color="inherit" onClick={doLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={isOpen}>
        <DrawerHeader>
          {isOpen && (
            <>
              <Link to="/">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ fontSize: '21px', color: '#000', fontWeight: 600 }}
                >
                  <img alt="logo" src={logo} />
                  <span style={{ margin: '12px' }}>차이 어드민</span>
                </Grid>
              </Link>
              <Box height={24} />
            </>
          )}
          <Tooltip
            title={
              <span>
                사이드바 닫기 <br /> ctrl + \
              </span>
            }
          >
            <IconButton onClick={switchDrawerStatus}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </DrawerHeader>
        <Divider />
        <List sx={{ padding: 0, '& div': { display: 'flex', alignItems: 'center', height: '48px' } }}>
          {menus.map((menu, idx) => (
            <MenuItem key={`menu-${idx}-${menu.path}`} {...menu} isOn={title === menu.label} isOpen={isOpen} />
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  maxHeight: appBarHeight,
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.primary.dark,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default Menu;
