import { Grid, Typography } from '@mui/material';
import { brandListQuery } from 'api/brandGql';
import { ChaiListPage } from 'common';
import React from 'react';

interface BoostListColumn {
  id: 'id' | 'name' | 'status';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: string | number, row: Data) => string | JSX.Element;
}

const columns: BoostListColumn[] = [
  {
    id: 'id',
    label: '브랜드 ID',
    format: value => (
      <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
        {value}
      </Typography>
    ),
  },
  {
    id: 'name',
    label: '브랜드 이름',
  },
  { id: 'status', label: '상태', minWidth: 100, format: (value, row) => '-' },
];

interface Data {
  id: number;
  name: string;
  status: string;
}

export const BoostListContainer: React.FC<{}> = props => {
  return (
    <Grid container alignItems="center" justifyContent="center" style={{ paddingTop: 48 }}>
      <ChaiListPage path="/boost/" query={brandListQuery} columns={columns} hideHeader />
    </Grid>
  );
};
