import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

const TestMessageDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">테스트용 메세지를 전송했습니다</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          메세지가 전송될때까지 시간이 조금 걸릴 수 있습니다.
          <br />
          이모지를 사용한 메세지의 경우 문자 전송이 실패할 수 있습니다.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestMessageDialog;
