import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/browser';
import { AlertProvider } from 'common/context/alert';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import apolloClient from 'util/apolloClient';
import { AuthProvider } from './auth';
import './index.css';
import SnackbarNotifier from './notifier';
import { store } from './reducers';
import Routes from './routes';

const env = window.location.host.replace(/\..*/, '');
const theme = createTheme();

Sentry.init({
  dsn: 'https://c12e4211332a49b284fbf454bee5368e@sentry.chai.finance/23',
  environment: env,
});

// window 객체
declare global {
  interface Window {
    hj: any; // hotjar
  }
}

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <AlertProvider>
            <Provider store={store}>
              <SnackbarProvider maxSnack={5}>
                <SnackbarNotifier />
                <Routes />
              </SnackbarProvider>
            </Provider>
          </AlertProvider>
        </AuthProvider>
      </ApolloProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);

// import reportWebVitals from "./reportWebVitals";
// reportWebVitals(console.info);
