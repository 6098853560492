import { BoostTagComparisonType, BoostTagLogicalType } from './common';

export enum BoostSimplePaymentTagTargetType {
  MERCHANT_ID = 'merchantId',
  SUB_MERCHANT_NAME = 'sub_merchant_name',
  PRODUCT_ID = 'productId',
}

export enum BoostCardPaymentTagTargetType {
  GROUP_MERCHANT = 'cardGroupMerchantId',
  BUSINESS_CODE = 'businessCode',
  MERCHANT_NAME = 'cardMerchantName',
}

export interface IPaymentTagConditionSingleItem {
  target: BoostSimplePaymentTagTargetType | BoostCardPaymentTagTargetType;
  value?: string;
  comparison: BoostTagComparisonType;
  not?: boolean;
}

export interface IPaymentTagConditionGroupItem {
  operator: BoostTagLogicalType;
  items: IPaymentTagConditionSingleItem[];
}

export type IPaymentTagCondition = {
  [key in BoostTagLogicalType]?: (IPaymentTagConditionSingleItem | IPaymentTagCondition)[];
};

export interface BoostPaymentTagForm {
  name: string;
  addButton: any;
  paymentConditionRootOperator: BoostTagLogicalType;
  paymentCondition: (IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem)[];
  cardPaymentConditionRootOperator: BoostTagLogicalType;
  cardPaymentCondition: (IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem)[];
}

// PaymentTagTargetType
// CardPaymentTargetType
