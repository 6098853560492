import { Box, Typography } from '@mui/material';
import { paymentTagListQuery } from 'api/boostTagGql';
import { ChaiListPage, ChaiListTableColumn } from 'common';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BoostPaymentTagDialog } from './BoostPaymentTagDialog';

interface TargetMerchantConditionListColumn extends ChaiListTableColumn {
  id: 'id' | 'name' | 'condition';
  format?: (
    value: string | number | { cardPaymentCondition: object; paymentCondition: object },
    row: TargetMerchantConditionData
  ) => string | JSX.Element;
}

const columns: TargetMerchantConditionListColumn[] = [
  {
    id: 'name',
    label: '타이틀',
    width: 300,
    format: value => (
      <Typography sx={{ color: '#1664C0' }} variant="subtitle2">
        {value}
      </Typography>
    ),
  },
  {
    id: 'condition',
    label: '조건',
    format: (value: any, row) => (
      <>
        <Box m={1}>{value?.cardPaymentCondition && `카드: ${JSON.stringify(value?.cardPaymentCondition)}`}</Box>
        <Box m={1}>{value?.paymentCondition && `간편결제: ${JSON.stringify(value?.paymentCondition)}`}</Box>
      </>
    ),
  },
];

interface TargetMerchantConditionData {
  id: number;
  name: string;
  condition: string;
}

export const BoostPaymentTagListContainer: React.FC<{}> = props => {
  const { tagId } = useParams<{ tagId?: string }>();

  return (
    <ChaiListPage
      path="/boost/paymentTag/"
      query={paymentTagListQuery}
      getQueryFilter={keyword => (keyword ? JSON.stringify({ name: keyword }) : '')}
      childId={tagId}
      columns={columns}
      dialog={BoostPaymentTagDialog}
    />
  );
};
