import gql from 'graphql-tag';
import { addError, addWarning } from 'state/notistackState';
import { store as reducers } from 'reducers';
import store from 'store';
import apolloClient from '../apolloClient';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';

const logoutMutation = gql`
  mutation logout {
    logout
  }
`;

const showError = (msg: string) => {
  reducers.dispatch(addError(msg));
};

const showWarning = (msg: string) => {
  reducers.dispatch(addWarning(msg));
};

class GQLError extends Error {
  constructor(message = '', errorName: string) {
    super(message);
    this.name = errorName || 'GQLError';
  }
}

interface ISentryPayload {
  path?: readonly (string | number)[];
  name?: string;
  msg?: string;
  gqlErrorName?: string;
  sqlQuery?: string;
}

const sendSentry = (payload: ISentryPayload) => {
  const { msg } = payload;
  if (!msg) {
    return;
  }

  Sentry.withScope(scope => {
    scope.setExtras({
      ...payload,
    });
    if (payload.gqlErrorName) {
      Sentry.captureException(new GQLError(msg, payload.gqlErrorName));
    } else {
      Sentry.captureException(new Error(msg));
    }
  });
};

const errorHandleLink = onError(({ networkError, graphQLErrors }) => {
  let sentryPayload: ISentryPayload = {};

  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i += 1) {
      const { extensions, message: msg, path } = graphQLErrors[i];

      if (extensions?.exception) {
        const { exception } = extensions;
        showError(`${msg || exception.message} (${exception.name}: ${exception.type} ${exception.code})`);
        sentryPayload = {
          path,
          msg,
          gqlErrorName: exception.name,
          sqlQuery: exception.query,
        };
      } else {
        showError(msg);
        sendSentry({ path, msg });
        return;
      }
    }
  } else if (networkError) {
    if (networkError instanceof Error) {
      showError(networkError.message);
      sendSentry({ msg: networkError.message });
      return;
    }
    console.error(networkError);
    sentryPayload = {
      msg: networkError,
    };
  }

  // Force to logout on unauthorized request
  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i += 1) {
      const code = graphQLErrors?.[i].extensions?.exception?.code;

      if (
        store.get('admin') &&
        (code === ClientErrorCodes.INACITVE_ADMIN || code === ClientErrorCodes.SESSION_EXPIRED)
      ) {
        const msg =
          code === ClientErrorCodes.INACITVE_ADMIN
            ? 'You were logged out because your account is blocked.'
            : "You were logged out because you don't have any recent activity.";
        store.remove('admin');

        apolloClient
          .mutate({ mutation: logoutMutation })
          .then(() => {
            // push('/login');
            showWarning(msg);
          })
          .catch(err => console.error('Failed to logout', err));
        return;
      }
    }
  }

  if (sentryPayload.msg) {
    sendSentry(sentryPayload);
  }
});

// 4xx Client Error
export const ClientErrorCodes = {
  // INVALID
  DATA_NOT_PROVIDED: 'A001',
  INVALID_ID_OR_PASSWORD: 'A002',
  INVALID_OTP: 'A003',
  REQUEST_OTP_TOO_FAST: 'A004',

  // NOT FOUND
  ADMIN_NOT_FOUND: 'N001',
  GROUP_NOT_FOUND: 'N002',
  MERCHANT_NOT_FOUND: 'N003',

  // OTHERS
  INACITVE_ADMIN: 'S001',

  // EXCEEDED
  REQUEST_TIMEOUT: 'T001',
  OTP_REQUEST_RATE_LIMITED: 'T002',
  SESSION_EXPIRED: 'T003',

  // Permissions
  NOT_HAVE_ACTION: 'P001',
  NOT_IN_GROUP: 'P002',
};

export default errorHandleLink;
