import { gql } from '@apollo/client';

export const getNotificationsQuery = gql`
  query getNotifications {
    getNotifications {
      id
      category
      code
      isCommon
      isFcm
      isKakao
      isSms
      delay
      variables
      messages {
        id
        type
        subject
        content
        isAd
        templateCode
        buttons
        scheme
        data
      }
    }
  }
`;

export const getNotificationQuery = gql`
  query getNotification($notificationId: ID!) {
    getNotification(notificationId: $notificationId) {
      id
      category
      code
      isCommon
      isFcm
      isKakao
      isSms
      delay
      variables
      messages {
        id
        type
        subject
        content
        isAd
        kakaoTitle
        templateCode
        buttons
        scheme
        data
      }
    }
  }
`;

export const modifyNotificationMutation = gql`
  mutation modifyNotification($notificationId: ID!, $data: NotificationInput!) {
    modifyNotification(notificationId: $notificationId, data: $data)
  }
`;

export const sendTestNotification = gql`
  mutation sendTestNotification($to: String!, $notification: NotificationWithMessageInput) {
    sendTestNotification(to: $to, notification: $notification)
  }
`;

export const saveNotificationMessage = gql`
  mutation saveNotificationMessage($notificationId: ID!, $data: [NotificationMessageInput!]!) {
    saveNotificationMessage(notificationId: $notificationId, data: $data)
  }
`;
