import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ChaiDivider from 'common/component/ChaiDivider';
import { default as React } from 'react';

export interface ChaiListTableColumn {
  id: string;
  label: string;
  width?: number | string;
  minWidth?: number | string;
  align?: 'right' | 'left' | 'center';
  format?: (value: string | number, row: any) => string | JSX.Element;
  searchTarget?: boolean; // 검색 기능에 사용되는 컬럼
}

export const ChaiListTable: React.FC<{
  columns: ChaiListTableColumn[];
  total?: number;
  hideTotal?: boolean;
  hidePagenation?: boolean;
  handleAdd?: React.MouseEventHandler<HTMLButtonElement>;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions?: (
    | number
    | {
        value: number;
        label: string;
      }
  )[];
  headerName?: string;
  hideHeader?: boolean;
  hideAddButton?: boolean;
  size?: 'small' | 'medium' | undefined;
  handleClearSearch?: () => void;
  handleOnChangeSearch?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  bottomButton?: JSX.Element;
}> = ({
  total = 0,
  hideTotal = false,
  hidePagenation = false,
  page,
  rowsPerPage,
  rowsPerPageOptions = [20, 50, 100],
  handleAdd,
  columns,
  children,
  headerName,
  hideHeader,
  hideAddButton = false,
  size,
  handleClearSearch,
  handleOnChangeSearch,
  handleChangePage,
  handleChangeRowsPerPage,
  bottomButton,
}) => {
  return (
    <>
      {hideHeader ? undefined : (
        <>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item style={{ paddingTop: 19 }}>
              <Typography variant="subtitle2" color="textSecondary">
                {headerName}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                type="search"
                size="small"
                variant="outlined"
                onChange={handleOnChangeSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {handleAdd && !hideAddButton && (
                <Button variant="outlined" color="primary" style={{ marginLeft: 16, height: 40 }} onClick={handleAdd}>
                  추가
                </Button>
              )}
            </Grid>
          </Grid>
          <ChaiDivider />
          <Box m={1} />
        </>
      )}
      {hideTotal || (
        <>
          <Typography variant="subtitle2" align="right" color="textSecondary">
            전체: {total || 0}
          </Typography>
          <Box m={1} />
        </>
      )}
      <TableContainer style={{ backgroundColor: 'white', minHeight: size === 'small' ? undefined : 887 }}>
        <Table stickyHeader aria-label="sticky table" size={size}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, width: column.width }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {hidePagenation ||
        (bottomButton ? (
          <Grid container>
            <Grid item xs={4} style={{ lineHeight: '52px' }}>
              {bottomButton}
            </Grid>
            <Grid item xs={8} style={{ lineHeight: '52px' }}>
              <TablePagination
                component="div"
                rowsPerPageOptions={rowsPerPageOptions}
                count={total || rowsPerPage * page + 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        ) : (
          <TablePagination
            component="div"
            rowsPerPageOptions={rowsPerPageOptions}
            count={total || rowsPerPage * page + 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ))}
    </>
  );
};
