import { Grid, Typography } from '@mui/material';

export const BoostContentTitle: React.FC<{ title: string }> = ({ children, title }) => (
  <Grid container justifyContent="space-between">
    <Grid item>
      <Typography variant="h5">{title}</Typography>
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);
