import React from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { ChaiListPage, ChaiListTableColumn } from 'common';
import { BoostOptionalImportantDialog } from './BoostOptionalImportantDialog';
import { getBoostImportantListQuery } from 'api/boostImportantGql';

interface ImportantListColumn extends ChaiListTableColumn {
  id: 'type' | 'title' | 'priority' | 'createdAt' | 'updatedAt' | 'content';
  format?: (value: any) => string | JSX.Element;
}

const columns: ImportantListColumn[] = [
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'content',
    label: '내용',
    width: '45%',
    format: (value: string) => (value.length > 80 ? value.slice(0, 80).toString() + '...' : value),
  },
  {
    id: 'createdAt',
    label: '생성 날짜',
    format: (value: string) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    id: 'updatedAt',
    label: '수정 날짜',
    format: (value: string) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    id: 'priority',
    label: '순서',
    width: '8%',
  },
];

export const BoostOptionalImportantListContainer: React.FC<{}> = props => {
  const { boostOptionalImportantId } = useParams<{ boostOptionalImportantId?: string }>();

  return (
    <ChaiListPage
      path="/important/optional/"
      table="importantOptional"
      query={getBoostImportantListQuery}
      getQueryFilter={keyword => (keyword ? JSON.stringify({ title: keyword }) : '')}
      childId={boostOptionalImportantId}
      columns={columns}
      dialog={BoostOptionalImportantDialog}
    />
  );
};
