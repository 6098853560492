import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { brandContentListQuery, brandQuery, IBrand } from 'api/brandGql';
import ChaiDivider from 'common/component/ChaiDivider';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import store from 'store';
import BoostPreviewComponent from '../component/BoostPreviewComponent';
import { BoostParams } from './BoostContentContainer';
import BoostInfoDialogContainer from './BoostInformationDialogContainer';
import { BrandMediaContent } from './BoostInformationDialogMediaContent';

const InfoTableRow = (props: { title: string; text: string }) => {
  const { title, text } = props;
  return (
    <TableRow>
      <TableCell style={{ borderBottom: 'none', paddingLeft: 0 }}>{title}</TableCell>
      <TableCell style={{ borderBottom: 'none' }}>{text}</TableCell>
    </TableRow>
  );
};

const MediaTableRow = (props: { id: number; filename: string; detail: string }) => {
  const { id, filename, detail } = props;
  return (
    <TableRow>
      <TableCell style={{ borderBottom: 'none', paddingLeft: 0 }}>{id}</TableCell>
      <TableCell style={{ borderBottom: 'none' }}>{filename}</TableCell>
      <TableCell style={{ borderBottom: 'none' }}>{detail}</TableCell>
    </TableRow>
  );
};

const BoostInformationContainer: React.FC<{ id: string; updateBrandInformation: (brand: IBrand) => void }> = props => {
  const { id, updateBrandInformation } = props;
  const history = useHistory();
  const { brandId } = useParams<BoostParams>();

  const { data: mediaContentData } = useQuery(brandContentListQuery, {
    variables: { id: Number(brandId) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: brandId === 'new' ? 'cache-only' : 'network-only', // apollo-client 버그 walk-around
  });
  const contentList: BrandMediaContent[] = mediaContentData?.brandContentList?.list || [];
  const sortedContentList: BrandMediaContent[] = contentList
    .slice(0, contentList.length)
    .sort((a, b) => a.priority - b.priority);

  const infoDialogOpen = history.location.pathname.includes('/info');

  const { data, refetch } = useQuery(brandQuery, {
    variables: { id: Number(id) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: id === 'new' ? 'cache-only' : 'network-only', // apollo-client 버그 walk-around
    onCompleted: data => {
      const brandInfo = data?.brand;
      updateBrandInformation(brandInfo);
    },
  });
  const brandInfo = data?.brand;
  const logoImageUploaded = Number(brandInfo?.logoImageUrl?.match(/\/([0-9]+)/)?.[1]);
  let logoImageUploadedDate = new Date(logoImageUploaded);

  const [open, setOpen] = React.useState<boolean>(store.get('brandInformationCollapse') && true);

  const handleExpand = () => {
    store.set('brandInformationCollapse', !open);
    setOpen(!open);
  };

  const handleEditInfo = () => {
    history.push(`/boost/${brandId}/info`);
  };

  useEffect(() => {
    if (id === 'new') {
      history.push(`/boost/new/info`);
    }
  }, [id, history]);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Grid item>
              <IconButton size="small" style={{ marginRight: 4 }} onClick={handleExpand}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h5">브랜드 정보</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button variant="contained" size="small" onClick={handleEditInfo}>
            수정
          </Button>
        </Grid>
      </Grid>
      <ChaiDivider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box m={2} />
        <Paper style={styles.container}>
          <Grid container spacing={6}>
            <Grid item xs={8}>
              브랜드 정보
              <ChaiDivider />
              <Table size="small">
                <TableBody>
                  <InfoTableRow title={'브랜드 ID'} text={id} />
                  <InfoTableRow title={'브랜드 이름'} text={brandInfo?.name} />
                  <InfoTableRow
                    title={'라운드 로고 이미지'}
                    text={
                      Number(logoImageUploaded) > 0 ? `(${format(logoImageUploadedDate, 'yyyy-MM-dd')} 업로드)` : '-'
                    }
                  />
                  <InfoTableRow title={'Landing URL'} text={brandInfo?.landingUrl} />
                  <InfoTableRow title={'Fallback URL'} text={brandInfo?.fallbackUrl} />
                  <InfoTableRow title={'iOS 앱 아이디'} text={brandInfo?.iosAppId} />
                  <InfoTableRow title={'안드로이드 패키지'} text={brandInfo?.androidPackage} />
                </TableBody>
              </Table>
              <ChaiDivider />
              <Box m={4} />
              브랜드 미디어 콘텐츠
              <ChaiDivider />
              <Table size="small">
                <TableBody>
                  {sortedContentList.slice(0, sortedContentList.length).map(row => {
                    return (
                      <MediaTableRow
                        id={row.id}
                        key={`boost-information-content-row-${row.id}`}
                        filename={row.bucketFileName}
                        detail={row.visible}
                      />
                    );
                  })}
                  {sortedContentList.length === 0 && <MediaTableRow id={1} filename={'내용이 없습니다'} detail={''} />}
                </TableBody>
              </Table>
              <ChaiDivider />
            </Grid>
            <Grid item xs={4}>
              <Grid item>미리보기</Grid>
              <ChaiDivider />
              <Box m={1} />
              <BoostPreviewComponent
                subText="NN% 캐시백"
                smallText="최대 MMMM원"
                bolt={'N'}
                name={brandInfo?.name}
                color={brandInfo?.color}
                logoImg={brandInfo?.logoImageUrl}
              />
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
      <Dialog maxWidth="md" open={infoDialogOpen} fullWidth onClose={() => history.push(`/boost/${brandId}`)}>
        <BoostInfoDialogContainer id={id} data={brandInfo} onDialogSubmit={refetch} />
      </Dialog>
    </>
  );
};

const styles = {
  container: {
    padding: 24,
  },
};

export default BoostInformationContainer;
