import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Step, StepButton, Stepper } from '@mui/material';
import { boostCampaignAddMutation, boostCampaignQuery, boostCampaignUpdateMutation } from 'api/boostCampaignGql';
import { useAlert } from 'common/context/alert';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { BoostParams } from '../container/BoostContentContainer';
import { BoostCampaignDialogContent } from './BoostCampaignDialogContent';
import { store as reducers } from 'reducers';
import { addError } from 'state/notistackState';
import { getBoostUpDefaultQuery } from 'api/boostUpGql';

export interface BoostCampaignForm {
  title: string;
  description: string;
  targetTypes: string[];
  boostPromotionId: number;
  targetUserTags: string[];
  boostBudgetId: number;
  amountCap: number;
  dailyAmountCap: number;
  dailyCountCapPerUser: number;
  totalCountCapPerUser: number;
  adSpendCalculationType: string;
  startAtDate: string;
  startAtTime: string;
  endAtDate: string;
  endAtTime: string;
  buyableFrom: string;
  buyableTo: string;
  usableFrom: string;
  usableTo: string;
  animationType: string;
  hasMediaContent: 'enabled' | 'disabled';
  hasBoostUp: 'enabled' | 'disabled';
  mediaContentInput: BoostCampaignMediaContentInput[];
  adSpendUnitPrice: number;
  adSpendRatio: number;
  boostUpPolicy: number;
}

export interface BoostCampaignMediaContentInput {
  brandContentId?: number;
  visibleFromDate?: string;
  visibleFromTime?: string;
  visibleToDate?: string;
  visibleToTime?: string;
}

const BOOST_CAMPAIGN_DIALOG_PAGES = [
  '캠페인 설정',
  '부스트 프로모션 설정',
  '캠페인 상세',
  '미디어 콘텐츠 설정',
  '확인',
];

// 페이지 별 validation 체크용 폼 리스트
const BOOST_CAMPAIGN_DIALOG_FORMS: (keyof BoostCampaignForm)[][] = [
  ['description', 'title'],
  ['targetTypes', 'boostPromotionId'],
  [
    'targetUserTags',
    'boostBudgetId',
    'amountCap',
    'dailyAmountCap',
    'dailyCountCapPerUser',
    'totalCountCapPerUser',
    'adSpendCalculationType',
    'startAtDate',
    'startAtTime',
    'endAtDate',
    'endAtTime',
    'buyableFrom',
    'buyableTo',
    'usableFrom',
    'usableTo',
    'adSpendUnitPrice',
    'adSpendRatio',
  ],
  ['animationType', 'hasMediaContent', 'mediaContentInput'],
];

export const BoostCampaignDialog: React.FC<{
  onDialogSubmit: () => void;
  basePath: string;
}> = ({ onDialogSubmit, basePath }) => {
  const alert = useAlert();
  const history = useHistory();
  const { brandId, campaignId, action } = useParams<BoostParams>();
  const [page, setPage] = useState(0);

  const form = useForm<BoostCampaignForm>({
    shouldUnregister: false,
  });
  const { handleSubmit, watch, reset, trigger, clearErrors, setValue } = form;

  const [getCampaign, { data }] = useLazyQuery(boostCampaignQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [getBoostUpDefault] = useLazyQuery(getBoostUpDefaultQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (defaultBoostUp: any) => {
      if (campaignId !== 'new') {
        return;
      }
      if (defaultBoostUp?.boostUpDefault?.id) {
        setValue('boostUpPolicy', defaultBoostUp?.boostUpDefault?.id);
      }
    },
  });

  const brandInformation = data?.boostCampaign?.boostPromotion?.brand;

  const title = action === 'copy' ? '캠페인 복사' : '캠페인 설정';

  const targetTypes = watch('targetTypes');
  useEffect(() => {
    getBoostUpDefault({ variables: { targetTypes } });
  }, [getBoostUpDefault, targetTypes]);

  useEffect(() => {
    if (campaignId !== undefined && campaignId !== null) {
      if (campaignId === 'new') {
        // 기본값
        reset({
          buyableFrom: '00:00',
          buyableTo: '23:59',
          usableFrom: '00:00',
          usableTo: '169:00',
          startAtTime: '00:00',
          endAtTime: '23:59',
          animationType: 'normal',
          hasMediaContent: 'disabled',
          hasBoostUp: 'enabled',
        });
      }
      if (campaignId !== 'new') {
        reset({});
        getCampaign({
          variables: { id: Number(campaignId) },
        });
      }
    }
  }, [reset, getCampaign, campaignId]);

  useEffect(() => {
    const newData = data?.boostCampaign;
    const {
      location: { pathname },
    } = history;
    // 캘린더의 경우 보정하지 않음
    if (pathname.indexOf('/boost/calendar/') >= 0) {
      return;
    }
    if (newData?.brandId && newData?.brandId !== +brandId && campaignId) {
      // 브랜드 id 보정
      history.push(`/boost/${newData?.brandId}/campaign/${campaignId}`);
      return;
    }
  }, [brandId, campaignId, data, history]);

  // 받아온 boost 데이터를 폼에 수화
  useEffect(() => {
    if (!data) {
      return;
    }
    const newData = data?.boostCampaign;
    const hasMediaContent = newData?.boostCampaignContents?.length > 0 ? 'enabled' : 'disabled';
    const newFormData = {
      ...newData,
      adSpendCalculationType: newData.adSpendCalculationType ?? '',
      adSpendWeight: newData.adSpendWeight * 100 ?? 50,
      adSpendRatio: newData.adSpendRatio * 100 ?? 0,
      targetTypes: newData?.targetTypes || [],
      startAtDate: format(new Date(newData.start), 'yyyy-MM-dd'),
      startAtTime: format(new Date(newData.start), 'HH:mm'),
      endAtDate: format(new Date(newData.end), 'yyyy-MM-dd'),
      endAtTime: format(new Date(newData.end), 'HH:mm'),
      hasMediaContent,
      mediaContentInput: hasMediaContent
        ? newData?.boostCampaignContents?.map((item: any) => ({
            brandContentId: item.brandContentId,
            visibleFromDate: format(new Date(item.visibleFrom), 'yyyy-MM-dd'),
            visibleFromTime: format(new Date(item.visibleFrom), 'HH:mm'),
            visibleToDate: format(new Date(item.visibleTo), 'yyyy-MM-dd'),
            visibleToTime: format(new Date(item.visibleTo), 'HH:mm'),
          }))
        : [],
      hasBoostUp: newData.boostUpPolicy ? 'enabled' : 'disabled',
    };

    reset(newFormData);
  }, [reset, data]);

  const handleClose = () => {
    history.push(basePath);
  };

  const handleBack = () => {
    page > 0 && onSetPage(page - 1);
  };

  const handleNext = () => {
    onSetPage(page + 1);
  };

  const onSetPage = (idx: number) => {
    // 뒤로 가는 경우 validation 무시
    if (page > idx) {
      clearErrors();
      setPage(idx);
      return;
    }

    trigger(BOOST_CAMPAIGN_DIALOG_FORMS[page]).then(result => result && setPage(idx));
  };

  const [addOrUpdateBoostCampaign] = useMutation(
    campaignId === 'new' || action === 'copy' ? boostCampaignAddMutation : boostCampaignUpdateMutation,
    {
      onCompleted: () => onDialogSubmit(),
      onError: err => {
        onDialogSubmit();
        reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
      },
    }
  );

  const onSubmit = (values: any) => {
    alert.confirm('캠페인 집행', '설정한 부스트 캠페인을\n집행하시겠습니까?', () => {
      addOrUpdateBoostCampaign({ variables: createSubmitPayload(values) });
      handleClose();
    });
  };

  const createSubmitPayload = (values: any) => {
    const mediaContentInput =
      values.hasMediaContent === 'enabled'
        ? values.mediaContentInput.map((item: any) => ({
            brandContentId: item.brandContentId,
            visibleFrom: new Date(`${item.visibleFromDate} ${item.visibleFromTime}`),
            visibleTo: new Date(`${item.visibleToDate} ${item.visibleToTime}`),
          }))
        : [];

    return {
      ...values,
      id: campaignId === 'new' || action === 'copy' ? undefined : Number(campaignId),
      brandId: Number(brandId ? brandId : brandInformation?.id),
      status: data?.boostCampaign?.status || 'enabled',
      boostBudgetId: +values?.boostBudgetId || undefined,
      boostPromotionId: +values?.boostPromotionId || undefined,
      start: new Date(`${values.startAtDate} ${values.startAtTime}`),
      end: new Date(`${values.endAtDate} ${values.endAtTime}`),
      boostCampaignContents: mediaContentInput,
      adSpendWeight: values.adSpendCalculationType === 'cpa' ? Number((values.adSpendWeight / 100).toFixed(4)) : 1,
      adSpendRatio: Number((values.adSpendRatio / 100).toFixed(4)),
      boostUpPolicy: values.hasBoostUp === 'enabled' && values.boostUpPolicy ? values.boostUpPolicy : null,
    };
  };

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container direction="row">
          <Grid item xs={2}>
            {action !== 'copy' && (
              <Stepper activeStep={page} orientation="vertical" style={{ paddingLeft: 0 }}>
                {BOOST_CAMPAIGN_DIALOG_PAGES.map((label, index) => (
                  <Step
                    key={`boost-promotion-dialog-step-${index}`}
                    onClick={() => onSetPage(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <StepButton onClick={() => onSetPage(index)}>{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
            )}
          </Grid>
          <Grid item xs={10}>
            <FormProvider {...form}>
              <BoostCampaignDialogContent page={page} brandInformation={brandInformation} />
            </FormProvider>
          </Grid>
        </Grid>
        <Box m={4} />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            {page > 0 && (
              <Button onClick={() => handleBack()} color="inherit">
                뒤로
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={() => handleClose()} color="inherit">
              취소
            </Button>
            {action !== 'copy' && page + 1 < BOOST_CAMPAIGN_DIALOG_PAGES.length ? (
              <Button onClick={() => handleNext()} color="primary">
                다음
              </Button>
            ) : (
              <Button onClick={handleSubmit(onSubmit)} color="primary">
                저장
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
