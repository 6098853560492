import { Box, Grid } from '@mui/material';
import { IBrand } from 'api/brandGql';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BoostBudgetContainer } from './BoostBudgetContainer';
import { BoostCampaignContainer } from './BoostCampaignContainer';
import BoostInformationContainer from './BoostInformationContainer';
import BoostPromotionContainer from '../BoostPromotion/BoostPromotionContainer';

export interface BoostParams {
  brandId: string;
  promotionId: string;
  campaignId: string;
  budgetId: string;
  action: string;
}

const BoostContentContainer: React.FC<{}> = props => {
  const [brandInformation, setBrandInformation] = React.useState<IBrand>();
  const { brandId } = useParams<BoostParams>();

  return (
    <Grid container direction="column" style={styles.content}>
      <Grid item>
        <BoostInformationContainer
          id={brandId}
          updateBrandInformation={info => {
            setBrandInformation(info);
          }}
        />
      </Grid>
      <Box m={6} />
      <Grid item>
        <BoostBudgetContainer />
      </Grid>
      <Box m={6} />
      <Grid item>
        <BoostPromotionContainer brandInformation={brandInformation} />
      </Grid>
      <Box m={6} />
      <Grid item>
        <BoostCampaignContainer brandInformation={brandInformation} />
      </Grid>
    </Grid>
  );
};

const styles = {
  content: {
    paddingTop: 48 + 24,
    paddingLeft: 48,
    paddingRight: 24,
  },
};

export default BoostContentContainer;
