import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BoostCalendarDayContainer } from './BoostCalendarDayContainer';
import { format } from 'date-fns';

export const BoostCalendar: React.FC<{}> = () => {
  return (
    <>
      <Switch>
        <Route path="/boost/calendar/day/:date/:campaignId" render={() => <BoostCalendarDayContainer />} />
        <Route path="/boost/calendar/day/:date" render={() => <BoostCalendarDayContainer />} />
        <Route path="/boost/calendar/day/*">
          <Redirect to={`/boost/calendar/day/${format(new Date(), 'yyyy-MM-dd')}`} />
        </Route>
        <Route path="/boost/calendar*">
          <Redirect to="/boost/calendar/day/" />
        </Route>
      </Switch>
    </>
  );
};
