import { Box, Button, Icon, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';

export interface ChaiDropdownMenuList {
  action: React.MouseEventHandler<HTMLLIElement>;
  icon: string;
  title: string;
  subtitle?: string;
}

export const ChaiDropdownMenu: React.FC<{
  name: string;
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  buttonText?: string;
  startIcon?: string;
  menuList: ChaiDropdownMenuList[];
}> = ({ name, buttonColor = 'primary', buttonText, startIcon, menuList }) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box>
      {buttonText ? (
        <Button
          color={buttonColor}
          aria-controls={`${name}-dropdown-menu`}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setMenuAnchorEl(event.currentTarget);
          }}
          startIcon={startIcon && <Icon>{startIcon}</Icon>}
          endIcon={<Icon>arrow_drop_down</Icon>}
        >
          {buttonText}
        </Button>
      ) : (
        <IconButton
          size="small"
          color={buttonColor}
          aria-controls={`${name}-dropdown-menu`}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setMenuAnchorEl(event.currentTarget);
          }}
        >
          <Icon>arrow_drop_down</Icon>
        </IconButton>
      )}

      <Menu
        id={`${name}-dropdown-menu`}
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
      >
        {menuList.map((menu, idx) => (
          <MenuItem
            onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
              menu.action(event);
              handleClose();
            }}
            key={`${name}-dropdown-menu-item-${idx}`}
          >
            <Icon fontSize="small" color="action">
              {menu.icon}
            </Icon>
            <Box style={{ marginLeft: 24 }}>
              {menu.title}
              {menu.subtitle && (
                <Typography variant="subtitle2" color="textSecondary">
                  {menu.subtitle}
                </Typography>
              )}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
