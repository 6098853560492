import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BoostOptionalImportantListContainer } from './BoostOptionalImportantListContainer';

export const BoostOptionalImportant: React.FC = () => {
  return (
    <>
      <Switch>
        <Route
          path="/important/optional/:boostOptionalImportantId"
          render={() => <BoostOptionalImportantListContainer />}
        />
        <Route path="/" render={() => <BoostOptionalImportantListContainer />} />
      </Switch>
    </>
  );
};
