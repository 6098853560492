import { Box, Button, Grid, Icon, IconButton, InputAdornment, Typography } from '@mui/material';
import { ChaiRadioForm, ChaiTextContextForm, ChaiWonForm } from 'common';
import React, { useCallback, useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { BoostParams } from '../container/BoostContentContainer';

export const BOOST_TYPE_OPTIONS = [
  { label: '일반 부스트', value: 'standard' },
  { label: '선착순 부스트', value: 'fcfs' },
  { label: '타임어택 부스트', value: 'time_attack' },
  { label: '정기결제 부스트', value: 'recurring' },
  { label: '미션 부스트', value: 'mission' },
];

// 3페이지: 부스트 설정
export const BoostPromotionBoostSetting: React.FC<{}> = () => {
  const descriptionDiscountChanged = useRef(false);
  const descriptionMaxDiscountAmountChanged = useRef(false);
  const { promotionId } = useParams<BoostParams>();
  const { unregister, control, watch, getValues, setValue } = useFormContext<any>();
  const {
    fields: subPromotions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'subPromotions',
  });

  const type = watch('type');
  const discountType = watch('discountType');
  const discount = watch('promotion.discount');
  const priceMin = watch('promotion.priceMin');
  const maxDiscountAmount = watch('promotion.maxDiscountAmount');

  const handleAddSubPromotion = () => {
    append({});
  };

  const handleDelSubPromotion = (idx: number) => {
    remove(idx);
  };

  useEffect(() => {
    // 신규 등록 시
    if (type === 'recurring' && !subPromotions?.length) {
      append({ boltPrice: 0 });
    }

    if (type !== 'fcfs') {
      unregister('countCap');
    }
  }, [append, unregister, type, subPromotions]);

  const resetTitle = useCallback(() => {
    const initialDiscount = getValues('initialData.discount');
    if (!descriptionDiscountChanged.current && initialDiscount && discount === initialDiscount) {
      return;
    }

    descriptionDiscountChanged.current = true;

    if (discountType === 'percent' && discount) {
      setValue('title', `${discount}% 캐시백`);
    }
    if (discountType === 'fixed' && discount) {
      setValue('title', `${(+discount).toLocaleString()}원 캐시백`);
    }
  }, [discount, discountType, getValues, setValue]);

  const resetDescription = useCallback(() => {
    const initialMaxDiscountAmount = getValues('initialData.promotion.maxDiscountAmount');
    if (
      !descriptionMaxDiscountAmountChanged.current &&
      initialMaxDiscountAmount &&
      maxDiscountAmount === initialMaxDiscountAmount
    ) {
      return;
    }

    descriptionMaxDiscountAmountChanged.current = true;

    if (discountType === 'percent' && maxDiscountAmount) {
      setValue('benefitDescription', `최대 ${(+maxDiscountAmount).toLocaleString()}원`);
    }
    if (discountType === 'fixed' && priceMin) {
      setValue('benefitDescription', `최소 결제금액 ${(+priceMin).toLocaleString()}원`);
    }
  }, [discountType, getValues, maxDiscountAmount, priceMin, setValue]);

  const subPromotionFirstDiscount = watch(`subPromotions.0.discount`);
  useEffect(() => {
    if (subPromotionFirstDiscount) {
      setValue('promotion.discount', `${subPromotionFirstDiscount}`);
    }
  }, [subPromotionFirstDiscount, setValue]);

  const subPromotionFirstMaxDiscountAmount = watch(`subPromotions.0.maxDiscountAmount`);
  useEffect(() => {
    if (subPromotionFirstMaxDiscountAmount) {
      setValue('promotion.maxDiscountAmount', subPromotionFirstMaxDiscountAmount);
    }
  }, [subPromotionFirstMaxDiscountAmount, setValue]);

  useEffect(() => {
    if (discountType === 'percent') {
      resetTitle();
      resetDescription();
    }
    if (discountType === 'fixed') {
      resetTitle();
      resetDescription();
    }
    return;
  }, [discountType, resetDescription, resetTitle, setValue]);

  return (
    <>
      <ChaiRadioForm context name="type" label="부스트 타입" required values={BOOST_TYPE_OPTIONS} />
      <Box m={2} />
      <ChaiRadioForm
        context
        name="discountType"
        label="할인유형 선택"
        required
        values={[
          { label: '정률 할인 (%)', value: 'percent' },
          { label: '정액 할인 (원)', value: 'fixed' },
        ]}
      />
      <Box m={2} />
      <Grid container spacing={1}>
        {type === 'fcfs' && (
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <ChaiTextContextForm
                type="number"
                label="선착순 구매 가능 수"
                name="countCap"
                shrink
                required
                endAdornment={<InputAdornment position="end">개</InputAdornment>}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={6}>
          {type !== 'recurring' && (
            <Grid item xs={6}>
              {discountType === 'percent' && (
                <ChaiTextContextForm
                  label="할인률(%)"
                  name="promotion.discount"
                  shrink
                  required
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              )}
              {discountType === 'fixed' && <ChaiWonForm required label="캐시백 금액(원)" name="promotion.discount" />}
            </Grid>
          )}
          <Grid item xs={6}>
            <ChaiTextContextForm label="부스트 제목" name="title" required shrink />
          </Grid>
          {type === 'recurring' && (
            <Grid item xs={6}>
              <ChaiTextContextForm label="부스트 설명" name="benefitDescription" required shrink />
            </Grid>
          )}
        </Grid>
        {type !== 'recurring' && (
          <>
            <Grid container spacing={6}>
              {discountType === 'percent' && (
                <Grid item xs={6}>
                  <ChaiWonForm label="최대 할인금액" name="promotion.maxDiscountAmount" required />
                </Grid>
              )}
              {discountType === 'fixed' && <Grid item xs={6}></Grid>}
              <Grid item xs={6}>
                <ChaiTextContextForm label="부스트 설명" name="benefitDescription" required />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <ChaiTextContextForm
              type="number"
              label="필요 번개 수"
              name="boltPrice"
              required
              endAdornment={<InputAdornment position="end">개</InputAdornment>}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6} justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <ChaiWonForm label="최소 결제금액" name="promotion.priceMin" required />
          </Grid>
          <Grid item xs={6}>
            <ChaiWonForm label="최대 결제금액" name="promotion.priceMax" required />
          </Grid>
        </Grid>
      </Grid>
      {type === 'recurring' && (
        <>
          <Box m={4} />
          <Typography variant="subtitle2" color="textSecondary">
            회차별 캐시백 설정
          </Typography>
          <Box m={1} />
          {subPromotions?.map((subPromotion: any, idx: number) => (
            <Grid container spacing={1} alignItems="flex-end" key={`boost-promotion-dialog-subpromotion-${idx}`}>
              <Grid item xs={1}>
                <Box style={{ padding: 4, textAlign: 'right' }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {idx + 1}:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                {discountType === 'percent' ? (
                  <ChaiTextContextForm
                    type="number"
                    label="할인율 (%)"
                    name={`subPromotions.${idx}.discount`}
                    defaultValue={subPromotion?.discount || undefined}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    required
                  />
                ) : (
                  <ChaiWonForm label="캐시백 금액(원)" name={`subPromotions.${idx}.discount`} required />
                )}
              </Grid>
              <Grid item xs={3}>
                <ChaiWonForm label="최대 할인금액" name={`subPromotions.${idx}.maxDiscountAmount`} required />
              </Grid>
              <Grid item xs={3}>
                <ChaiTextContextForm
                  key={subPromotion.id}
                  type="number"
                  label="필요 번개 수"
                  defaultValue={idx === 0 ? 0 : subPromotion.boltPrice || undefined}
                  name={`subPromotions.${idx}.boltPrice`}
                  disabled={idx === 0}
                  endAdornment={<InputAdornment position="end">개</InputAdornment>}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                {promotionId === 'new' && idx !== 0 && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleDelSubPromotion(idx);
                    }}
                  >
                    <Icon>highlight_off</Icon>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
          <Box m={1} />
          {promotionId === 'new' && (
            <Button onClick={handleAddSubPromotion} fullWidth variant="outlined">
              회차 설정 추가
            </Button>
          )}
        </>
      )}
    </>
  );
};
