export enum NotificationTypeEnum {
  COMMON = 'COMMON',
  FCM = 'FCM',
  KAKAO = 'KAKAO',
  SMS = 'SMS',
}

export interface INotificationData {
  id: string;
  category: string;
  code: string;
  isCommon: boolean;
  isFcm: boolean;
  isKakao: boolean;
  isSms: boolean;
  delay: number;
  variables: string[];
  messages: INotificationMessage[];
}

export interface INotificationMessage {
  id: string;
  type: NotificationTypeEnum; // failover(fcm -> kakao -> sms)
  subject?: string; // 없는 경우 INotificationData.subject
  content?: string; // 없는 경우 INotificationData.content
  kakaoTitle?: string;
  templateCode?: string; // kakao
  isAd?: boolean; // sms => 없는 경우 광고 아님
  scheme?: string;
  buttons?: ISensSendKakaoButton[]; // 카카오톡 전용 버튼
}

export interface ISensSendKakaoButton {
  type: KakaoButtonType; // 버튼 타입
  name: string; // 버튼 이름
  linkMobile?: string; // 모바일 웹 링크
  linkPc?: string; // PC 웹 링크
  schemeIos?: string; // ios 앱 링크
  schemeAndroid?: string; // 안드로이드 앱 링크
}

export enum KakaoButtonType {
  DELIVERY_SERVICE = 'DS', // 배송조회
  WEB_LINK = 'WL', // 웹 링크
  APP_LINK = 'AL', // 앱 링크
  BOT_KEYWORD = 'BK', // 봇 키워드
  MESSAGE_DELIVERY = 'MD', // 메세지 전달
}
