import { Avatar, Box, Card, CardContent, CardHeader, Chip, Tooltip, Typography } from '@mui/material';
import { IBoostCampaignListItem } from 'api/boostCampaignGql';
import { contrastTextColor } from 'util/contrastTextColor';
import { wonString } from 'util/wonString';
import { BOOST_TYPE_OPTIONS } from '../BoostPromotion/BoostPromotionDialogBoostSetting';

export const BoostCalendarListCardComponent: React.FC<{
  item: IBoostCampaignListItem;
  onClick: any;
}> = ({ item, onClick }) => {
  const {
    id: campaignId,
    targetUserTags,
    boostPromotion: {
      brand: { name: brandName, logoImageUrl: brandIcon, color: brandColor },
      type: boostType,
      countCap,
      promotion: { discount, maxDiscountAmount, priceMin },
      boltPrice,
    },
    statusLabel: status,
    reasonLabels: statusReasons,
    amountCap,
    dailyAmountCap,
    usedCampaignBudget,
    reservedCampaignBudget,
    todayUsedCampaignBudget,
    todayReservedCampaignBudget,
  } = item;

  const brandTextColor = contrastTextColor(brandColor);

  return (
    <Card style={{ cursor: 'pointer' }} onClick={onClick}>
      <CardHeader
        style={{ backgroundColor: brandColor }}
        avatar={<Avatar alt={brandName} src={brandIcon}></Avatar>}
        title={
          <Box textAlign="right" style={{ color: brandTextColor, marginBottom: 4, marginRight: 4 }}>
            <Typography variant="button">
              <b>{campaignId}</b>
            </Typography>
          </Box>
        }
        subheader={
          <Box textAlign="right">
            <StatusChip status={status} />
            {statusReasons?.length > 0 &&
              statusReasons.slice(0, 1).map((reason, idx) => (
                <span key={`campaign-${campaignId}-reason-label-${idx}`}>
                  &nbsp;
                  <StatusChip status={reason} />
                </span>
              ))}
          </Box>
        }
      />
      <CardContent style={{ position: 'relative' }}>
        <Box style={{ position: 'absolute', right: '16px', textAlign: 'right' }}>
          <Box style={{ color: 'transparent', textShadow: '0 0 0 black' }}>⚡ {boltPrice}</Box>
          <Typography variant="subtitle2">
            <span style={{ color: 'transparent', textShadow: '0 0 0 black', fontSize: 8 }}>⚡</span> ={' '}
            {Math.floor((maxDiscountAmount || +discount) / boltPrice).toLocaleString()}원
          </Typography>
        </Box>
        <Typography
          variant="body1"
          style={{ maxWidth: '200px', whiteSpace: 'pre', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          <b>{brandName}</b>
        </Typography>
        <Typography variant="body2">
          {`${BOOST_TYPE_OPTIONS.find(item => item.value === boostType)?.label}${
            boostType === 'fcfs' ? ` (${(countCap || 0).toLocaleString()}개)` : ''
          }` || boostType}
        </Typography>
        <Typography variant="body2">
          {discount.indexOf('%') > 0 ? discount : `${Number(discount)?.toLocaleString() || 0}원`}
        </Typography>
        <br />
        <Typography variant="body2">{`최소 ${(priceMin || 0).toLocaleString()}원`}</Typography>
        <Typography variant="body2">{`최대 ${(maxDiscountAmount || 0).toLocaleString()}원`}</Typography>
        <br />
        <Typography variant="body2">
          {targetUserTags && targetUserTags?.length > 0 ? `태그: ${targetUserTags?.join(', ')}` : '태그: 전체 대상'}
        </Typography>
        <br />
        <Typography variant="body2">예산소진 현황</Typography>
        <Tooltip
          arrow
          title={
            <Box style={{ fontSize: 14, lineHeight: '20px' }}>
              전체 예산 ({Math.round(((usedCampaignBudget + reservedCampaignBudget) / amountCap) * 10000) / 100}% 소진)
              <br />
              {wonString(usedCampaignBudget)} / {wonString(reservedCampaignBudget + usedCampaignBudget)} /{' '}
              {wonString(amountCap)}
              <br />
              오늘 예산 (
              {!dailyAmountCap
                ? '없음)'
                : Math.round(((todayUsedCampaignBudget + todayReservedCampaignBudget) / dailyAmountCap) * 10000) / 100 +
                  '% 소진)'}
              <br />
              {wonString(todayUsedCampaignBudget)} / {wonString(todayReservedCampaignBudget + todayUsedCampaignBudget)}{' '}
              / {wonString(dailyAmountCap)}
            </Box>
          }
          placement="bottom"
        >
          <Box
            style={{
              position: 'relative',
              width: '100%',
            }}
          >
            <Box
              style={{
                position: 'relative',
                height: 6,
                width: '100%',
                marginTop: 4,
                marginBottom: 4,
                backgroundColor: '#bdbdbd',
              }}
            >
              <Box
                style={{
                  height: 6,
                  float: 'left',
                  backgroundColor: '#3f51b5',
                  width: `${Math.round((usedCampaignBudget / amountCap) * 10000) / 100}%`,
                }}
              />
              <Box
                style={{
                  height: 6,
                  float: 'left',
                  backgroundColor: '#9fa8da',
                  width: `${Math.round((reservedCampaignBudget / amountCap) * 10000) / 100}%`,
                }}
              />
            </Box>

            <Box
              style={{
                position: 'relative',
                height: 6,
                width: '100%',
                marginTop: 4,
                marginBottom: 4,
                backgroundColor: '#bdbdbd',
              }}
            >
              <Box
                style={{
                  height: 6,
                  float: 'left',
                  backgroundColor: '#3f51b5',
                  width: `${Math.round((todayUsedCampaignBudget / dailyAmountCap) * 10000) / 100}%`,
                }}
              />
              <Box
                style={{
                  height: 6,
                  float: 'left',
                  backgroundColor: '#9fa8da',
                  width: `${Math.round((todayReservedCampaignBudget / dailyAmountCap) * 10000) / 100}%`,
                }}
              />
            </Box>
          </Box>
        </Tooltip>
      </CardContent>
    </Card>
  );
};

const StatusChip = (props: { status: string; color?: string }) => {
  const { status, color } = props;
  let chipColor: string = color || '#E0E0E0';
  switch (status) {
    case '집행예정':
    case '집행중':
      chipColor = '#B9F6CA';
      break;

    case '집행중지':
    case '집행불가':
      chipColor = '#FFCDD3';
      break;

    case '집행기간만료':
      chipColor = '#EEEEEE';
      break;
  }

  return <Chip size="small" label={status} style={{ backgroundColor: chipColor }} />;
};
