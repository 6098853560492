import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarMessage } from 'notistack';

interface NotistackState {
  notistacks: NotiStacks[];
  idx: number;
}

interface NotiStacks {
  message: SnackbarMessage;
  key: string;
  dismissed?: boolean;
  options?: OptionsObject;
}

interface IEnqueueSnackbar {
  message: SnackbarMessage;
  key?: string;
  options?: OptionsObject;
}

const initialState = {
  notistacks: [],
  idx: 0,
} as NotistackState;

const notistackSlice = createSlice({
  name: 'notistack',
  initialState,
  reducers: {
    addSuccess: (state: NotistackState, action: PayloadAction<string>) => {
      const { payload } = action;
      const key = `${new Date().getTime()}${state.idx}`;

      return {
        ...state,
        idx: state.idx + 1,
        notistacks: [
          ...state.notistacks,
          {
            key,
            message: payload,
            options: {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            },
          } as NotiStacks,
        ],
      };
    },
    addError: (state: NotistackState, action: PayloadAction<string>) => {
      const { payload } = action;
      const key = `${new Date().getTime()}${state.idx}`;

      return {
        ...state,
        idx: state.idx + 1,
        notistacks: [
          ...state.notistacks,
          {
            key,
            message: payload,
            options: {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            },
          } as NotiStacks,
        ],
      };
    },
    addWarning: (state: NotistackState, action: PayloadAction<string>) => {
      const { payload } = action;
      const key = `${new Date().getTime()}${state.idx}`;

      return {
        ...state,
        idx: state.idx + 1,
        notistacks: [
          ...state.notistacks,
          {
            key,
            message: payload,
            options: {
              variant: 'warning',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            },
          } as NotiStacks,
        ],
      };
    },
    enqueueNotistack: (state: NotistackState, action: PayloadAction<IEnqueueSnackbar>) => {
      const { payload } = action;
      const key = payload.options?.key || `${new Date().getTime()}${state.idx}`;

      return {
        ...state,
        idx: state.idx + 1,
        notistacks: [
          ...state.notistacks,
          {
            key,
            ...payload,
          } as NotiStacks,
        ],
      };
    },
    removeSnackbar: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        notistacks: state.notistacks.filter(notification => notification.key !== payload),
      };
    },
  },
});

export const { enqueueNotistack, removeSnackbar, addError, addWarning, addSuccess } = notistackSlice.actions;
export default notistackSlice.reducer;
