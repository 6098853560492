import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import { boostBudgetAddMutation, boostBudgetQuery, boostBudgetUpdateMutation } from 'api/boostBudgetGql';
import { ChaiSelectForm, ChaiTextForm, ChaiWonForm } from 'common';
import { useAlert } from 'common/context/alert';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { BoostParams } from './BoostContentContainer';
import { store as reducers } from 'reducers';
import { addError, addSuccess } from 'state/notistackState';

interface IBudget {
  amount: number;
  status: string;
  type: 'ratio' | 'unit_price' | 'chai_only';
}

export const BoostBudgetDialogContainer: React.FC<{ onDialogSubmit: () => void }> = ({ onDialogSubmit }) => {
  const history = useHistory();
  const alert = useAlert();
  const { brandId, budgetId } = useParams<BoostParams>();
  const [title, setTitle] = React.useState('');
  const methods = useForm<IBudget>({
    shouldUnregister: false,
  });
  const {
    register,
    watch: formWatch,
    formState: { errors },
    handleSubmit,
    reset,
    control: formControl,
    getValues,
  } = methods;

  const status = formWatch('status');
  const type = formWatch('type');

  useEffect(() => {
    if (!budgetId) {
      return;
    }
    if (budgetId !== 'new') {
      setTitle(`예산 ${budgetId}`);
    } else {
      reset({
        status: 'enabled',
      });
      setTitle('예산 추가');
    }
  }, [budgetId, setTitle, reset]);

  useQuery(boostBudgetQuery, {
    variables: { id: Number(budgetId) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: budgetId === 'new' || !budgetId ? 'cache-only' : 'network-only', // apollo-client 버그 walk-around
    onCompleted: data => {
      const budgetInfo = {
        ...data?.boostBudget,
        type: data?.boostBudget.type ?? '',
      };

      reset(budgetInfo);
    },
  });

  const handleClose = () => {
    history.push(`/boost/${brandId}`);
    onDialogSubmit();
  };

  const [addOrUpdateBoostBudget] = useMutation(
    budgetId === 'new' ? boostBudgetAddMutation : boostBudgetUpdateMutation,
    {
      onCompleted: () => {
        reducers.dispatch(addSuccess(`부스트 예산이 업데이트 되었습니다.`));
        handleClose();
      },
      onError: err => {
        reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
        handleClose();
      },
    }
  );

  const onSubmit = (budgetInfo: IBudget) => {
    const values = getValues();
    const makePreview = () =>
      [
        ['title', '예산 타이틀'],
        ['status', '예산 상태'],
        ['type', '예산 타입'],
        ['amount', '예산 액수'],
        ['dailyCap', '하루 최대 예산'],
      ].reduce((acc, item) => `${acc}\n${item[1]}: ${(values[item[0] as keyof IBudget] || '-')?.toLocaleString()}`, '');

    alert.confirm(
      '예산',
      (budgetId === 'new' ? '다음 예산을 추가하시겠습니까?' : '다음 예산을 수정하시겠습니까?') + '\n' + makePreview(),
      () => {
        addOrUpdateBoostBudget({
          variables: { ...budgetInfo, brandId: Number(brandId), id: budgetId === 'new' ? undefined : Number(budgetId) },
        });
      }
    );
  };

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
              <ChaiTextForm register={register} errors={errors} label="예산 타이틀" name="title" required shrink />
            </Grid>
            <Grid item xs={12}>
              <ChaiSelectForm control={formControl} name="status" label="예산 상태" defaultValue={status || 'enabled'}>
                <MenuItem value="enabled">활성</MenuItem>
                <MenuItem value="disabled">비활성</MenuItem>
              </ChaiSelectForm>
            </Grid>
            <Grid item xs={12}>
              <ChaiSelectForm
                required
                name="type"
                defaultValue={type || ''}
                label="가맹점 청구 타입"
                disabled={budgetId !== 'new'}
              >
                <MenuItem value={'unit_price'}>단가(CPS/CPA)</MenuItem>
                <MenuItem value={'ratio'}>가맹점 분담</MenuItem>
                <MenuItem value={'chai_only'}>차이 부담</MenuItem>
              </ChaiSelectForm>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6} style={{ paddingRight: 8 }}>
                <ChaiWonForm label="전체 예산 (원)" name="amount" required readOnly={budgetId !== 'new'} />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 8 }}>
                <ChaiWonForm label="남은 예산 (원)" name="remainingTotal" readOnly />
              </Grid>
            </Grid>
          </Grid>
          <ChaiWonForm label="하루 최대 예산" name="dailyCap" readOnly={budgetId !== 'new'} />
          <Box m={4} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          취소
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="primary">
          저장
        </Button>
      </DialogActions>
    </>
  );
};
