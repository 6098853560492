import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { BoostSimplePaymentTagTargetType } from 'type/tag/paymentTag';
import { hasErrorFormProps } from 'util/formFieldProps';
import styles from '../../Boost/boost.module.scss';
import { BoostTagForm } from './BoostTagDialog';

export const BoostTagInput: React.FC<{
  paymentTagList: any;
  namePrefix: `conditions.${number}` | `conditions.${number}.subConditions.${number}`;
}> = ({ paymentTagList, namePrefix }) => (
  <BoostTagAutocomplete placeholder="부스트 태그 입력" list={paymentTagList} namePrefix={namePrefix} />
);

const BoostTagAutocomplete: React.FC<{
  list: any[];
  namePrefix: `conditions.${number}` | `conditions.${number}.subConditions.${number}`;
  placeholder: string;
}> = ({ list, namePrefix, placeholder }) => {
  const {
    setValue,
    clearErrors,
    formState: { errors },
    watch,
    register,
  } = useFormContext<BoostTagForm>();
  const inputValue = watch(`${namePrefix}.value`);
  const [inputStringValue, setInputStringValue] = React.useState<string>();
  const value = list?.find(item => item.id === inputValue) || null;
  const errorProps = hasErrorFormProps({ error: errors, name: `${namePrefix}.value` });
  register(`${namePrefix}.value`, { required: true });

  // productId 체크
  useEffect(() => {
    if (
      value?.condition?.paymentCondition &&
      value?.condition?.paymentCondition[Object.keys(value?.condition?.paymentCondition)[0]]
    ) {
      const hasProductId = value?.condition?.paymentCondition[Object.keys(value?.condition?.paymentCondition)[0]]
        .map(mapTargets)
        .flat()
        .includes(BoostSimplePaymentTagTargetType.PRODUCT_ID);
      setValue(`${namePrefix}.hasProductIdCondition`, hasProductId);
    } else {
      setValue(`${namePrefix}.hasProductIdCondition`, false);
    }
  }, [value, namePrefix, setValue]);

  return (
    <Autocomplete
      options={list?.slice().sort((a, b) => a.name.localeCompare(b.name)) || []}
      autoComplete
      autoHighlight
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => `${option.name}` || `(이름 없음) (${option.id})`}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      filterOptions={options => {
        if (!inputStringValue?.length) {
          return options;
        }
        return options.filter(
          option => `${option.name}(${option.id})`.replace(/\s/g, '').indexOf(inputStringValue.replace(/\s/g, '')) > -1
        );
      }}
      onChange={(e, value) => {
        clearErrors(`${namePrefix}.value`);
        setValue(`${namePrefix}.value`, value?.id);
      }}
      onInputChange={(e, value) => {
        setInputStringValue(value);
      }}
      value={value}
      renderInput={params => (
        <TextField
          {...params}
          name={`${namePrefix}.value`}
          fullWidth
          placeholder={placeholder}
          size="small"
          variant="outlined"
          {...errorProps}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: styles.backgroundWhite,
            },
          }}
        />
      )}
    />
  );
};

const mapTargets = (item: any) => {
  if (item.target) {
    return item.target;
  }
  if (item.and) {
    return item.and.map((subItem: { target: string }) => subItem.target);
  }
  if (item.or) {
    return item.or.map((subItem: { target: string }) => subItem.target);
  }
  return [];
};
