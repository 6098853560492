const ChaiDivider: React.FC<{}> = ({ children }) => {
  return (
    <>
      <div style={styles.dividerPadding} />
      <div style={styles.divider}></div>
      <div style={styles.dividerPadding} />
    </>
  );
};

const styles = {
  divider: {
    height: 2,
    backgroundColor: 'black',
  },
  dividerPadding: {
    height: 8,
  },
};

export default ChaiDivider;
