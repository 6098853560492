import { get } from 'react-hook-form';

const errorMessages: { [key: string]: string } = {
  min: '최소 값 미달 오류',
  max: '최대 값 초과 오류',
  pattern: '잘못 된 입력 오류',
  required: '필수값입니다',
  default: '오류',
};

export const makeErrorMessage = (error: any): string =>
  errorMessages?.[error.type] || error.message || errorMessages.default;

export const hasErrorFormProps = ({ error, name }: { error: any; name: string }) => ({
  error: !!get(error, name),
  helperText: get(error, name) && makeErrorMessage(get(error, name)),
});
