import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { createContext, useContext, useMemo, useState } from 'react';

const alertContext = createContext<AlertType>({
  open: () => {},
  confirm: () => {},
});

type AlertType = {
  open: (title: string, message: string) => void;
  confirm: (title: string, message: string, onConfirm: () => void) => void;
};

enum DialogType {
  alert = 'ALERT',
  confirm = 'CONFIRM',
}

export function AlertProvider({ children }: { children: any }) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.alert);
  const [callback, setCallback] = useState<() => () => void>();

  const alert: AlertType = useMemo(() => {
    const open = (title: string, message: string) => {
      if (dialogOpen) {
        return;
      }
      setTitle(title);
      setMessage(message);
      setDialogType(DialogType.alert);
      setDialogOpen(true);
      setIsLoading(false);
    };

    const confirm = (title: string, message: string, onConfirm: () => void) => {
      if (dialogOpen) {
        return;
      }
      setTitle(title);
      setMessage(message);
      setDialogType(DialogType.confirm);
      setCallback(() => onConfirm);
      setDialogOpen(true);
      setIsLoading(false);
    };

    return {
      open,
      confirm,
    };
  }, [dialogOpen, setDialogOpen]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    if (callback) {
      callback();
    }
    setIsLoading(true);
    setDialogOpen(false);
  };

  const renderAction = (type: DialogType) => {
    switch (type) {
      case DialogType.confirm:
        return (
          <>
            <Button onClick={handleClose} color="primary" autoFocus>
              취소
            </Button>
            <Button disabled={isLoading} onClick={handleConfirm} color="primary" autoFocus>
              확인
            </Button>
          </>
        );
      default:
        return (
          <Button onClick={handleClose} color="primary" autoFocus>
            닫기
          </Button>
        );
    }
  };

  return (
    <alertContext.Provider value={alert}>
      {children}
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: 'pre' }}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>{renderAction(dialogType)}</DialogActions>
      </Dialog>
    </alertContext.Provider>
  );
}

export function useAlert(): AlertType {
  return useContext<AlertType>(alertContext);
}
