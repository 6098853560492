import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { boostListQuery, IBoostPromotionListItem } from 'api/boostPromotionGql';
import { IBrand } from 'api/brandGql';
import ChaiDivider from 'common/component/ChaiDivider';
import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getValue } from 'util/get';
import { BoostParams } from '../container/BoostContentContainer';
import { BoostContentTitle } from '../component/BoostContentTitle';
import { BoostPromotionDialog } from './BoostPromotionDialog';
import { BOOST_TYPE_OPTIONS } from './BoostPromotionDialogBoostSetting';

const BoostPromotionContainer: React.FC<{ brandInformation?: IBrand }> = props => {
  const history = useHistory();
  const promotionDialogOpen = history.location.pathname.includes('/promotion/');
  const { brandId } = useParams<BoostParams>();

  const { brandInformation } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data, refetch: refetchBoostList } = useQuery(boostListQuery, {
    variables: { skip: page * rowsPerPage, pageSize: rowsPerPage, filter: JSON.stringify({ brandId }) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: brandId === 'new' ? 'cache-only' : 'network-only', // apollo-client 버그 walk-around
  });
  const boostList: IBoostPromotionListItem[] = data?.boostPromotionList?.list || [];
  const boostListTotal: number = data?.boostPromotionList?.total;

  const handleAddPromotion = () => {
    history.push(`/boost/${brandId}/promotion/new`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (brandId && brandId !== 'new') {
      refetchBoostList();
    }
  }, [brandId, refetchBoostList]);

  return (
    <>
      <BoostContentTitle title="부스트 프로모션">
        <Button variant="contained" size="small" onClick={handleAddPromotion}>
          추가
        </Button>
      </BoostContentTitle>
      <ChaiDivider />
      <Box m={2} />

      <Grid container direction="column" justifyContent="center">
        <Typography variant="subtitle2" align="right" color="textSecondary">
          전체: {boostListTotal || 0}
        </Typography>
        <Box m={1} />
        <TableContainer style={{ backgroundColor: 'white' }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <colgroup>
              <col></col>
              <col style={{ width: '150px' }}></col>
              <col style={{ width: '150px' }}></col>
              <col style={{ width: '130px' }}></col>
              <col style={{ width: '200px' }}></col>
              <col style={{ width: '150px' }}></col>
              <col style={{ width: '200px' }}></col>
              <col style={{ width: '200px' }}></col>
              <col style={{ width: '100px' }}></col>
              <col></col>
            </colgroup>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {boostList.map((row: IBoostPromotionListItem) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map(column => {
                      const value = getValue(row, column.id);
                      let content;
                      if (column.formatDate) {
                        content = format(parseISO(value), column.formatDate);
                      } else if (column.format) {
                        content = column.format(value, row);
                      } else if (column.link) {
                        content = (
                          <Link to={`/boost/${brandId}/promotion/${row.id}`}>
                            {value || `${brandInformation?.name} 부스트 (${row.id})`}
                          </Link>
                        );
                      } else if (column.copy) {
                        content = (
                          <Icon
                            style={{ height: 24, verticalAlign: 'bottom', cursor: 'pointer' }}
                            color="primary"
                            onClick={e => history.push(`/boost/${brandId}/promotion/${row.id}/copy`)}
                          >
                            add_to_photos
                          </Icon>
                        );
                      } else {
                        content = value;
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {content}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={boostListTotal || rowsPerPage * page + 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          maxWidth="lg"
          open={promotionDialogOpen}
          fullWidth
          scroll="paper"
          onClose={(_, reason) => reason !== 'backdropClick' && history.push(`/boost/${brandId}`)}
        >
          <BoostPromotionDialog brandInformation={brandInformation} onDialogSubmit={() => refetchBoostList()} />
        </Dialog>
      </Grid>
    </>
  );
};

// const styles = {} as React.CSSProperties;

interface Column {
  id:
    | 'name'
    | 'promotion.createdAt'
    | 'promotion.updatedAt'
    | 'type'
    | 'promotion.discount'
    | 'benefitAmount'
    | 'boltPrice'
    | 'promotion.maxDiscountAmount'
    | 'promotion.priceMin'
    | 'copy';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: string | number, row: IBoostPromotionListItem) => string | JSX.Element;
  formatDate?: string;
  link?: boolean;
  copy?: boolean;
}

const columns: Column[] = [
  {
    id: 'name',
    label: '타이틀',
    minWidth: 170,
    link: true,
  },
  {
    id: 'promotion.createdAt',
    label: '생성 날짜',
    formatDate: 'yy-MM-dd',
  },
  { id: 'promotion.updatedAt', label: '마지막 수정 날짜', formatDate: 'yy-MM-dd' },
  {
    id: 'type',
    label: '부스트 타입',
    format: value => BOOST_TYPE_OPTIONS.find(item => item.value === value)?.label || `${value}`,
  },
  {
    id: 'promotion.discount',
    label: '할인',
    align: 'right',
    format: value => (`${value}`.indexOf('%') > 0 ? `${value}` : `${Number(value)?.toLocaleString() || 0}원`),
  },
  { id: 'boltPrice', label: '필요 번개 수', align: 'right', format: value => `${value?.toLocaleString() || 0}개` },
  {
    id: 'promotion.maxDiscountAmount',
    label: '최대 할인 금액',
    align: 'right',
    format: value => `${value?.toLocaleString() || 0}원`,
  },
  {
    id: 'promotion.priceMin',
    label: '최소 구매 금액',
    align: 'right',
    format: value => `${value?.toLocaleString() || 0}원`,
  },
  {
    id: 'copy',
    label: '복사',
    copy: true,
  },
];

export default BoostPromotionContainer;
