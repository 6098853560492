import { useQuery } from '@apollo/client';
import { alpha, Autocomplete, AppBar, Box, Grid, Paper, TextField, Theme, Toolbar, CssBaseline } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { brandListQuery } from 'api/brandGql';
import { appBarHeight, drawerWidth } from 'page/Menu/Menu';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import BoostContentContainer from './container/BoostContentContainer';
import { BoostListContainer } from './container/BoostListContainer';

interface BrandListData {
  id: number;
  name: string;
  status: string;
}

export const BoostRouter: React.FC<{}> = props => {
  return (
    <Switch>
      <Route
        exact
        path="/boost/:brandId/budget/:budgetId"
        render={() => (
          <BoostContainer>
            <BoostContentContainer />
          </BoostContainer>
        )}
      />
      <Route
        exact
        path="/boost/:brandId/promotion/:promotionId/:action"
        render={() => (
          <BoostContainer>
            <BoostContentContainer />
          </BoostContainer>
        )}
      />
      <Route
        exact
        path="/boost/:brandId/promotion/:promotionId"
        render={() => (
          <BoostContainer>
            <BoostContentContainer />
          </BoostContainer>
        )}
      />
      <Route
        exact
        path="/boost/:brandId/campaign/:campaignId/:action"
        render={() => (
          <BoostContainer>
            <BoostContentContainer />
          </BoostContainer>
        )}
      />
      <Route
        exact
        path="/boost/:brandId/campaign/:campaignId"
        render={() => (
          <BoostContainer>
            <BoostContentContainer />
          </BoostContainer>
        )}
      />
      <Route
        path="/boost/:brandId"
        render={() => (
          <BoostContainer>
            <BoostContentContainer />
          </BoostContainer>
        )}
      />
      <Route
        path="/"
        render={() => (
          <BoostContainer>
            <BoostListContainer />
          </BoostContainer>
        )}
      />
    </Switch>
  );
};

export const BoostContainer: React.FC<{}> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  // TODO: searchTarget 이용하여 브랜드리스트 쿼리
  const { control: formControl } = useForm<{
    searchTarget: BrandListData | undefined;
  }>({
    shouldUnregister: false,
  });
  // const searchTarget: BrandListData | undefined = formWatch('searchTarget');

  const [inputStringValue, setInputStringValue] = React.useState<string>();
  const { data } = useQuery(brandListQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { filter: JSON.stringify({ name: inputStringValue }), pageSize: 20 },
  });
  const brandList: BrandListData[] =
    data?.brandList?.list
      ?.slice()
      .sort((brandA: BrandListData, brandB: BrandListData) => brandA.name.localeCompare(brandB.name)) || [];

  useEffect(() => {}, [inputStringValue]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <CssBaseline />
        <Toolbar variant="dense">
          <Box flexGrow="4">
            <Grid item>{/* <Typography variant="subtitle1">브랜드 목록</Typography> */}</Grid>
          </Box>
          <Box flexGrow="1">
            <Controller
              render={({ field: { onChange, onBlur } }) => (
                <Autocomplete
                  options={brandList}
                  className={classes.inputRoot}
                  autoComplete
                  autoHighlight
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={option => {
                    return option.name || `브랜드 이름 오류 (${option.id})`;
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  filterOptions={options => {
                    if (!inputStringValue?.length) {
                      return [];
                    }
                    return options;
                  }}
                  onBlur={onBlur}
                  onChange={(e, value) => {
                    if (value?.id) {
                      history.push(`/boost/${value.id}/`);
                      onChange(value);
                    }
                  }}
                  onInputChange={(e, value) => {
                    setInputStringValue(value);
                  }}
                  PaperComponent={({ children }) => (
                    <Paper>
                      {inputStringValue?.length !== 0 && children}
                      <Box
                        style={{ padding: 8 }}
                        textAlign="center"
                        onMouseDown={e => {
                          e.preventDefault();
                        }}
                      >
                        <Link to="/boost/new/">+ 신규 브랜드 추가</Link>
                      </Box>
                    </Paper>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="브랜드 검색"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        classes: {
                          root: classes.inputField,
                        },
                      }}
                    />
                  )}
                />
              )}
              // defaultValue={null}
              name="searchTarget"
              control={formControl}
            />
          </Box>
        </Toolbar>
      </AppBar>
      {children}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    boxShadow: 'none',
    paddingLeft: drawerWidth,
    top: appBarHeight,
    backgroundColor: alpha('#1565C0', 0.9),
  },
  inputRoot: {
    padding: '4px',
  },
  inputField: {
    backgroundColor: '#ededed',
  },
}));
