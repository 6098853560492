import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BoltDrawListContainer } from './BoltDrawListContainer';

export const BoltDraw: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/bolt-draw/:boltDrawId" render={() => <BoltDrawListContainer />} />
        <Route path="/" render={() => <BoltDrawListContainer />} />
      </Switch>
    </>
  );
};
