import gql from 'graphql-tag';

export const boostBudgetListQuery = gql`
  query boostBudgetList($skip: Int, $pageSize: Int, $filter: String) {
    boostBudgetList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        brandId
        title
        description
        type
        amount
        dailyCap
        status
        budgetTotal
        remainingTotal
      }
      total
    }
  }
`;

export const boostBudgetQuery = gql`
  query boostBudget($id: Int!) {
    boostBudget(id: $id) {
      id
      brandId
      title
      type
      description
      amount
      dailyCap
      status
      budgetTotal
      remainingTotal
    }
  }
`;

export const boostBudgetAddMutation = gql`
  mutation boostBudgetAdd(
    $brandId: Int!
    $title: String!
    $description: String
    $type: String
    $amount: BigInt
    $dailyCap: BigInt
    $status: String
  ) {
    boostBudgetAdd(
      brandId: $brandId
      title: $title
      description: $description
      type: $type
      amount: $amount
      dailyCap: $dailyCap
      status: $status
    )
  }
`;

export const boostBudgetUpdateMutation = gql`
  mutation boostBudgetUpdate(
    $id: Int!
    $brandId: Int!
    $title: String!
    $description: String
    $type: String
    $amount: BigInt
    $dailyCap: BigInt
    $status: String
  ) {
    boostBudgetUpdate(
      id: $id
      brandId: $brandId
      title: $title
      description: $description
      type: $type
      amount: $amount
      dailyCap: $dailyCap
      status: $status
    )
  }
`;

export interface IBoostBudget {
  id: number;
  brandId: number;
  title: string;
  description: string;
  type: string;
  amount: number;
  dailyCap: number;
  status: string;
  budgetTotal: number;
  remainingTotal: number;
}

export interface IBoostBudgetList {
  list: [IBoostBudget];
  total: number;
}
