import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { ChaiDropdownMenu, ChaiListTableColumn, ChaiSimpleListTable, ChaiTextForm } from 'common';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BoostTagComparisonType, BoostTagLogicalType } from 'type/tag/common';
import {
  BoostCardPaymentTagTargetType,
  BoostPaymentTagForm,
  BoostSimplePaymentTagTargetType,
  IPaymentTagConditionGroupItem,
  IPaymentTagConditionSingleItem,
} from 'type/tag/paymentTag';
import styles from './boostPaymentTag.module.scss';
import { BoostPaymentTagDialogGroupItem } from './BoostPaymentTagDialogGroupItem';
import { BoostPaymentTagDialogSingleItem } from './BoostPaymentTagDialogSingleItem';

const columns: ChaiListTableColumn[] = [
  {
    id: 'id',
    label: '구분',
    width: '50%',
    searchTarget: true,
  },
  {
    id: 'name',
    label: '이름',
    searchTarget: true,
  },
];

export const BoostPaymentTagDialogContent: React.FC<{}> = () => {
  const {
    watch: formWatch,
    control: formControl,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<BoostPaymentTagForm>();
  const [listDialogOpen, setListDialogOpen] = React.useState<boolean>(false);
  const [listDialogContent, setListDialogContent] = React.useState<any[]>([]);
  const addButtonError = errors?.addButton;

  const { register } = formControl;

  const cardPaymentCondition: (IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem)[] =
    formWatch('cardPaymentCondition') || [];
  const paymentCondition: (IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem)[] =
    formWatch('paymentCondition') || [];

  const cardPaymentConditionRootOperator: BoostTagLogicalType = formWatch('cardPaymentConditionRootOperator');
  const paymentConditionRootOperator: BoostTagLogicalType = formWatch('paymentConditionRootOperator');

  const addSingleCondition = (
    type: 'card' | 'payment',
    target: BoostSimplePaymentTagTargetType | BoostCardPaymentTagTargetType
  ) => {
    addCondition(
      { target, comparison: type === 'card' ? BoostTagComparisonType.INCLUDE : BoostTagComparisonType.EQUAL },
      type
    );
    clearErrors();
  };

  const addGroupCondition = (
    type: 'card' | 'payment',
    target: BoostCardPaymentTagTargetType | BoostSimplePaymentTagTargetType
  ) => {
    addCondition(
      {
        operator: BoostTagLogicalType.AND,
        items: [
          { target, comparison: type === 'card' ? BoostTagComparisonType.INCLUDE : BoostTagComparisonType.EQUAL },
        ],
      },
      type
    );
    clearErrors();
  };

  const addCondition = (newCondition: IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem, type: string) => {
    type === 'payment' && setValue(`paymentCondition.${paymentCondition.length}`, newCondition);
    type === 'card' && setValue(`cardPaymentCondition.${cardPaymentCondition.length}`, newCondition);
  };

  const openListDialog = (list: any[]) => {
    if (!list?.length) {
      return;
    }
    setListDialogContent(list);
    setListDialogOpen(true);
  };

  return (
    <>
      <ChaiTextForm label="타이틀" name="name" register={register} errors={errors} required shrink={true} />
      <Box m={2} />
      <Typography variant="subtitle2" color={addButtonError ? 'error' : 'textSecondary'}>
        카드*{addButtonError && ` (${addButtonError.message})`}
      </Typography>
      <Box m={2} />
      <Box className={styles.conditionBackground}>
        <Grid className={styles.noPadding} container>
          {cardPaymentCondition.map((condition, idx) => {
            // 일반
            if ('target' in condition) {
              return (
                <Grid item xs={12} key={`payment-tag-card-item-${idx}`}>
                  <BoostPaymentTagDialogSingleItem
                    type="cardPaymentCondition"
                    nameBase={`cardPaymentCondition.${idx}`}
                    operator={cardPaymentConditionRootOperator}
                    idx={idx}
                    openListDialog={openListDialog}
                  />
                  <Box m={2} />
                </Grid>
              );
            }
            // 그룹
            return (
              <Grid item xs={12} key={`payment-tag-card-item-${idx}`}>
                <BoostPaymentTagDialogGroupItem
                  type="cardPaymentCondition"
                  nameBase={`cardPaymentCondition.${idx}`}
                  operator={cardPaymentConditionRootOperator}
                  idx={idx}
                  subConditions={condition.items}
                  openListDialog={openListDialog}
                />
                <Box m={2} />
              </Grid>
            );
          })}
        </Grid>
        <ChaiDropdownMenu
          name="condition-card-add"
          buttonText="조건 추가"
          startIcon="add"
          menuList={[
            {
              icon: 'add_box',
              title: '단일 조건 추가',
              action: () => addSingleCondition('card', BoostCardPaymentTagTargetType.MERCHANT_NAME),
            },
            {
              icon: 'library_add',
              title: '조건 그룹 추가',
              subtitle: '더 많은 조건 중첩을 위한 그룹',
              action: () => addGroupCondition('card', BoostCardPaymentTagTargetType.MERCHANT_NAME),
            },
          ]}
        />
      </Box>
      <Box m={2} />
      <Typography variant="subtitle2" color={addButtonError ? 'error' : 'textSecondary'}>
        간편결제*{addButtonError && ` (${addButtonError.message})`}
      </Typography>
      <Box m={2} />
      <Box className={styles.conditionBackground}>
        <Grid className={styles.noPadding} container>
          {paymentCondition.map((condition: any, idx) => {
            // 일반
            if ('target' in condition) {
              return (
                <Grid item xs={12} key={`payment-tag-payment-item-${idx}`}>
                  <BoostPaymentTagDialogSingleItem
                    type="paymentCondition"
                    nameBase={`paymentCondition.${idx}`}
                    operator={paymentConditionRootOperator}
                    idx={idx}
                    openListDialog={openListDialog}
                  />
                  <Box m={2} />
                </Grid>
              );
            }
            // 그룹
            return (
              <Grid item xs={12} key={`payment-tag-payment-item-${idx}`}>
                <BoostPaymentTagDialogGroupItem
                  type="paymentCondition"
                  nameBase={`paymentCondition.${idx}`}
                  operator={paymentConditionRootOperator}
                  idx={idx}
                  subConditions={condition.items}
                  openListDialog={openListDialog}
                />
                <Box m={2} />
              </Grid>
            );
          })}
        </Grid>
        <ChaiDropdownMenu
          name="condition-payment-add"
          buttonText="조건 추가"
          startIcon="add"
          menuList={[
            {
              icon: 'add_box',
              title: '단일 조건 추가',
              action: () => addSingleCondition('payment', BoostSimplePaymentTagTargetType.MERCHANT_ID),
            },
            {
              icon: 'library_add',
              title: '조건 그룹 추가',
              subtitle: '더 많은 조건 중첩을 위한 그룹',
              action: () => addGroupCondition('payment', BoostSimplePaymentTagTargetType.MERCHANT_ID),
            },
          ]}
        />
      </Box>
      <Dialog maxWidth="md" open={listDialogOpen} fullWidth>
        <DialogTitle>전체 목록</DialogTitle>
        <DialogContent>
          <ChaiSimpleListTable name="merchantListTable" columns={columns} list={listDialogContent} size="small" />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item></Grid>
            <Grid item>
              <Button onClick={() => setListDialogOpen(false)} color="primary">
                닫기
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
