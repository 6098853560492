import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BoostRequiredImportantListContainer } from './BoostRequiredImportantListContainer';

export const BoostRequiredImportant: React.FC = () => {
  return (
    <>
      <Switch>
        <Route
          path="/important/required/:boostRequiredImportantId"
          render={() => <BoostRequiredImportantListContainer />}
        />
        <Route path="/" render={() => <BoostRequiredImportantListContainer />} />
      </Switch>
    </>
  );
};
