import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { brandAddMutation, brandUpdateMutation, brandUploadImageMutation, IBrand } from 'api/brandGql';
import { ChaiTextForm } from 'common';
import ChaiDivider from 'common/component/ChaiDivider';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import apolloClient from 'util/apolloClient';
import BoostPreviewComponent from '../component/BoostPreviewComponent';
import { BoostInformationDialogMediaContent } from './BoostInformationDialogMediaContent';
import { store as reducers } from 'reducers';
import { addError, addSuccess } from 'state/notistackState';

interface BoostBrandInfoDialogForm {
  name: string;
  color: string;
  logoImageUrl: string;
  typoImageUrl: string;
  logoFile: FileList;
}

const BoostInfoDialogContainer: React.FC<{ id?: string; data: IBrand; onDialogSubmit: () => void }> = props => {
  const history = useHistory();
  const { id } = props;
  const {
    register,
    setValue,
    // control: formControl,
    watch: formWatch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<BoostBrandInfoDialogForm>({
    shouldUnregister: false,
  });
  const [openMediaContentDialog, setOpenMediaContentDialog] = React.useState(false);

  // useFieldArray({
  //   control: formControl,
  //   name: 'brandInformation',
  // });

  useEffect(() => {
    if (props.data) {
      reset(props.data);
    }
  }, [reset, props.data]);

  const name: string = formWatch('name');
  const color: string = formWatch('color');
  const logoFile: FileList = formWatch('logoFile');
  const logoImageUrl: string = formWatch('logoImageUrl');

  useEffect(() => {
    const uploadImageFile = async (file: File) => {
      try {
        const result = await apolloClient.mutate({
          mutation: brandUploadImageMutation,
          variables: {
            imageFile: file,
          },
        });
        const { brandUploadImage: uploadedFile } = result.data;
        const newFilePath = `https://static.chai.finance/${uploadedFile.path}/${uploadedFile.filename}`;
        setValue('logoImageUrl', newFilePath);
        return newFilePath;
      } catch {}
      return;
    };

    if (logoFile?.[0]) {
      uploadImageFile(logoFile?.[0]);
    }
  }, [logoFile, setValue]);

  const handleClose = () => {
    if (id !== 'new') {
      history.push(`/boost/${id}/`);
    } else {
      history.push(`/boost/`);
    }
  };

  const handleOpenMediaContent = (open: boolean) => {
    setOpenMediaContentDialog(open);
  };

  const [addOrUpdateBrand, { loading }] = useMutation(id === 'new' ? brandAddMutation : brandUpdateMutation, {
    onCompleted: ({ brandAdd }) => {
      reducers.dispatch(addSuccess(`부스트가 ${id === 'new' ? '추가' : '수정'}되었습니다.`));
      id === 'new' ? history.push(`/boost/`) : props.onDialogSubmit();
    },
    onError: err => {
      reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
      history.push(`/boost/`);
    },
  });

  const onSubmit = (brandInfo: IBrand) => {
    addOrUpdateBrand({ variables: { ...brandInfo, typoImageUrl: '', id: id === 'new' ? undefined : Number(id) } });
    handleClose();
  };

  return (
    <>
      <DialogTitle>브랜드 정보 수정</DialogTitle>
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={8}>
            <Typography variant="subtitle2" color="textSecondary">
              브랜드 정보 (*필수 입력사항)
            </Typography>
            <ChaiDivider />
            <ChaiTextForm
              label="브랜드 ID"
              name="id"
              defaultValue={id || '브랜드 ID는 자동으로 부여됩니다.'}
              disabled
            />
            <ChaiTextForm register={register} errors={errors} label="브랜드 이름" name="name" required />
            <ChaiTextForm register={register} errors={errors} label="브랜드 색상" name="color" required />
            <ChaiTextForm
              register={register}
              errors={errors}
              label="라운드 로고 이미지"
              name="logoImageUrl"
              required
              readOnly
              shrink
              endAdornment={
                <InputAdornment position="end">
                  <input {...register('logoFile')} id="brand-info-logo-file" type="file" accept="image/*" hidden />
                  <label htmlFor="brand-info-logo-file">
                    <IconButton component="span">
                      <CloudUploadIcon fontSize="small" />
                    </IconButton>
                  </label>
                </InputAdornment>
              }
            />
            <Box m={6} />
            <Typography variant="subtitle2" color="textSecondary">
              브랜드 URL
            </Typography>
            <ChaiDivider />
            <ChaiTextForm register={register} errors={errors} label="Landing URL" name="landingUrl" />
            <ChaiTextForm register={register} errors={errors} label="Fallback URL" name="fallbackUrl" />
            <ChaiTextForm register={register} errors={errors} label="iOS 앱 아이디" name="iosAppId" />
            <ChaiTextForm register={register} errors={errors} label="안드로이드 패키지" name="androidPackage" />
            <Box m={2} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" color="textSecondary">
              미리보기
            </Typography>
            <Box m={1} />
            <BoostPreviewComponent
              subText="NN% 캐시백"
              smallText="최대 MMMM원"
              bolt={'N'}
              name={name}
              color={color}
              logoImg={logoImageUrl}
            />
          </Grid>
        </Grid>
        <Box m={4} />
        <Dialog maxWidth="lg" open={openMediaContentDialog} fullWidth>
          <BoostInformationDialogMediaContent handleClose={() => handleOpenMediaContent(false)} />
        </Dialog>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button onClick={() => handleOpenMediaContent(true)} color="primary">
              미디어 콘텐츠 관리
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleClose} color="inherit">
              취소
            </Button>
            <Button disabled={loading} onClick={handleSubmit(onSubmit)} color="primary">
              저장
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default BoostInfoDialogContainer;
