import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import { boostCampaignListQuery, IBoostCampaignListItem } from 'api/boostCampaignGql';
import {
  BoostBoltPolicyListItem,
  boostMissionPolicyAddMutation,
  BoostMissionPolicyConditionTypeEnum,
  BoostMissionPolicyStatusEnum,
  BoostMissionPolicyTypeEnum,
  boostMissionPolicyUpdateMutation,
  getBoltPolicyListQuery,
  getBoostMissionPolicyQuery,
  IUpdateBoostMissionPolicyInput,
} from 'api/missionPolicyGql';
import { ChaiAutoCompleteList, ChaiSelectForm, ChaiTextContextForm } from 'common';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { store as reducers } from 'reducers';
import { addError, addSuccess } from 'state/notistackState';

export interface IBoostMissionForm {
  title: string;
  description?: string;
  status: BoostMissionPolicyStatusEnum;
  type: BoostMissionPolicyTypeEnum;
  code?: string;
  criteria?: number;
  startAtDate: string;
  startAtTime: string;
  endAtDate: string;
  endAtTime: string;
  boostCampaignId?: number;
  boltPolicyId?: number;
  visibleToOffsetHour?: number;
  usableToOffsetHour?: number;
  buyableToOffsetHour?: number;
  missionUrl?: string;
}

export const BoostMissionDialog: React.FC<{ id: string }> = ({ id }) => {
  // const alert = useAlert();
  const history = useHistory();
  const [title, setTitle] = React.useState('');
  const [hasCampaignReward, setHasCampaignReward] = React.useState<boolean>(false);
  const [hasBoltReward, setHasBoltReward] = React.useState<boolean>(false);

  const form = useForm<IBoostMissionForm>({ shouldUnregister: false });
  const { reset, watch, control, handleSubmit, clearErrors, setError } = form;

  const code = watch('code');
  const criteria = watch('criteria');
  const visibleToOffsetHour = watch('visibleToOffsetHour') || 0;
  const usableToOffsetHour = watch('usableToOffsetHour') || 0;
  const buyableToOffsetHour = watch('buyableToOffsetHour') || 0;

  const isAddForm = id === 'new';

  const [getBoostMission, { data }] = useLazyQuery(getBoostMissionPolicyQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { id: Number(id) },
    fetchPolicy: isAddForm || !id ? 'cache-only' : 'network-only',
  });

  useEffect(() => {
    if (!isAddForm) {
      getBoostMission();
    } else {
      setTitle('부스트 미션 추가');
      setHasCampaignReward(false);
      setHasBoltReward(false);
      reset({
        title: '',
        status: BoostMissionPolicyStatusEnum.ENABLED,
        type: BoostMissionPolicyTypeEnum.WELCOME_TUTORIAL,
        code: undefined,
        criteria: undefined,
        startAtTime: '00:00',
        endAtTime: '23:59',
      });
    }
  }, [id, setTitle, reset, getBoostMission, isAddForm]);

  useEffect(() => {
    if (data?.boostMissionPolicy) {
      const missionData = data?.boostMissionPolicy;
      const {
        title,
        description,
        status,
        type,
        code,
        criteria,
        startAt,
        endAt,
        boostCampaignId,
        visibleToOffsetHour,
        usableToOffsetHour,
        buyableToOffsetHour,
        boltPolicyId,
        missionUrl,
      } = missionData;
      setTitle(`${missionData.title} (${id})`);

      setHasCampaignReward(false);
      setHasBoltReward(false);

      if (boostCampaignId) {
        setHasCampaignReward(true);
      }
      if (boltPolicyId) {
        setHasBoltReward(true);
      }

      reset({
        title,
        description,
        status,
        type,
        code,
        criteria,
        boostCampaignId,
        visibleToOffsetHour,
        usableToOffsetHour,
        buyableToOffsetHour,
        boltPolicyId,
        missionUrl,
        startAtDate: format(new Date(startAt), 'yyyy-MM-dd'),
        startAtTime: format(new Date(startAt), 'HH:mm'),
        endAtDate: format(new Date(endAt), 'yyyy-MM-dd'),
        endAtTime: format(new Date(endAt), 'HH:mm'),
      });
    }
  }, [data, id, reset]);

  useEffect(() => {}, [code, criteria]);

  // 다이얼로그가 입력받을 준비되었는지 확인
  const isDialogReady = () => {
    return isAddForm || (data && title);
  };

  const handleClose = () => {
    history.push('/boost/mission/');
  };

  const handleCancel = () => {
    handleClose();
  };

  const [addOrUpdateBoostMissionPolicy, { loading }] = useMutation(
    isAddForm ? boostMissionPolicyAddMutation : boostMissionPolicyUpdateMutation,
    {
      onCompleted: () => {
        reducers.dispatch(addSuccess(`부스트 미션이 업데이트 되었습니다.`));
        handleClose();
      },
      onError: err => {
        reducers.dispatch(addError('서버와 통신 과정에서 에러가 발생했습니다.'));
      },
    }
  );

  const onSubmit = (values: IBoostMissionForm) => {
    if (validate(values)) {
      return false;
    }
    addOrUpdateBoostMissionPolicy({ variables: createSubmitPayload(values) });
  };

  const createSubmitPayload = (values: IBoostMissionForm) => {
    const {
      title,
      description,
      status,
      type,
      code,
      criteria,
      startAtDate,
      startAtTime,
      endAtDate,
      endAtTime,
      boostCampaignId,
      visibleToOffsetHour,
      usableToOffsetHour,
      buyableToOffsetHour,
      boltPolicyId,
      missionUrl,
    } = values;
    const payload: Partial<IUpdateBoostMissionPolicyInput> = {
      title,
      description,
      status,
      type,
      missionUrl,
    };

    if (!isAddForm) {
      payload.id = +id;
    }

    // code, criteria 택일
    if (code && code.length > 0) {
      payload.conditionType = BoostMissionPolicyConditionTypeEnum.CODE;
      payload.code = code;
      payload.criteria = null;
    } else if (criteria && criteria > 0) {
      payload.conditionType = BoostMissionPolicyConditionTypeEnum.CRITERIA;
      payload.criteria = criteria;
      payload.code = null;
    } else {
      // 둘 다 내용이 없는 경우 null 처리
      payload.conditionType = BoostMissionPolicyConditionTypeEnum.CODE;
      payload.code = null;
      payload.criteria = null;
    }

    payload.startAt = new Date(`${startAtDate} ${startAtTime}`);
    payload.endAt = new Date(`${endAtDate} ${endAtTime}`);

    if (hasCampaignReward) {
      payload.boostCampaignId = boostCampaignId ? +boostCampaignId : null;
      payload.visibleToOffsetHour = visibleToOffsetHour;
      payload.usableToOffsetHour = usableToOffsetHour;
      payload.buyableToOffsetHour = buyableToOffsetHour;
    } else {
      payload.boostCampaignId = null;
    }

    if (hasBoltReward) {
      payload.boltPolicyId = boltPolicyId ? +boltPolicyId : null;
    } else {
      payload.boltPolicyId = null;
    }

    if (payload.criteria === null) {
      delete payload.criteria;
    }

    return payload;
  };

  const validate = (values: IBoostMissionForm) => {
    // 캠페인 리워드 있는 경우
    // boostCampaignId, visibleToOffsetHour, usableToOffsetHour, buyableToOffsetHour 체크
    if (hasCampaignReward) {
      if (!values.boostCampaignId) {
        setError('boostCampaignId', { message: '필수 값 입니다.' });
        return true;
      }

      if (!values.visibleToOffsetHour) {
        setError('visibleToOffsetHour', { message: '필수 값 입니다.' });
        return true;
      }
      if (!values.usableToOffsetHour) {
        setError('usableToOffsetHour', { message: '필수 값 입니다.' });
        return true;
      }
      if (!values.buyableToOffsetHour) {
        setError('buyableToOffsetHour', { message: '필수 값 입니다.' });
        return true;
      }
    }

    if (hasBoltReward) {
      if (!values.boltPolicyId) {
        setError('boltPolicyId', { message: '필수 값 입니다.' });
        return true;
      }
    }

    return false;
  };

  // 데이터 로딩 중
  if (!isDialogReady()) {
    return (
      <FormProvider {...form}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item></Grid>
            <Grid item>
              <Button onClick={() => handleCancel()}>취소</Button>
              <Button disabled={true} color="primary">
                저장
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </FormProvider>
    );
  }

  return (
    <FormProvider {...form}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ChaiTextContextForm name="title" label="부스트 미션 타이틀" required defaultValue="" />
        <ChaiTextContextForm name="description" label="부스트 미션 설명" defaultValue="" />
        <ChaiSelectForm
          name="status"
          required
          control={control}
          label="미션 상태"
          defaultValue={BoostMissionPolicyStatusEnum.ENABLED}
        >
          <MenuItem value={BoostMissionPolicyStatusEnum.ENABLED}>활성</MenuItem>
          <MenuItem value={BoostMissionPolicyStatusEnum.DISABLED}>비활성</MenuItem>
        </ChaiSelectForm>
        <ChaiSelectForm
          context
          name="type"
          label="미션 타입"
          defaultValue={BoostMissionPolicyTypeEnum.WELCOME_TUTORIAL}
        >
          <MenuItem value={BoostMissionPolicyTypeEnum.WELCOME_TUTORIAL}>신규 유저 (WELCOME_TUTORIAL)</MenuItem>
          <MenuItem value={BoostMissionPolicyTypeEnum.WELCOME_RED_CARD}>
            신규 차이 체크카드 유저(WELCOME_RED_CARD)
          </MenuItem>
          <MenuItem value={BoostMissionPolicyTypeEnum.WELCOME_CREDIT_CARD}>
            신규 차이 신용카드 유저(WELCOME_CREDIT_CARD)
          </MenuItem>
          <MenuItem value={BoostMissionPolicyTypeEnum.CREDIT_CARD_APPLY_CONFIRM}>
            신용카드 심사 완료(CREDIT_CARD_APPLY_CONFIRM)
          </MenuItem>
        </ChaiSelectForm>
        <Box m={4} />
        <Typography variant="subtitle2">노출 기간</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container justifyContent="space-between">
              <Grid item xs={6} style={{ paddingRight: 16 }}>
                <ChaiTextContextForm label="시작일" name="startAtDate" type="date" required />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 16 }}>
                <ChaiTextContextForm label="" name="startAtTime" type="time" required />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="space-between">
              <Grid item xs={6} style={{ paddingRight: 16 }}>
                <ChaiTextContextForm label="종료일" name="endAtDate" type="date" required />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 16 }}>
                <ChaiTextContextForm label="" name="endAtTime" type="time" required />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box m={4} />
        <Typography variant="subtitle2">컨디션 타입</Typography>
        <ChaiTextContextForm label="code (유저 행동)" name="code" disabled={!!criteria && criteria > 0} />
        <ChaiTextContextForm
          label="criteria (기준-횟수)"
          name="criteria"
          type="number"
          placeholder="숫자를 입력해주세요"
          disabled={!!code && code?.length > 0}
        />
        <Box m={4} />
        <Typography variant="subtitle2">기타</Typography>
        <ChaiTextContextForm label="미션 URL" name="missionUrl" />
        <Box m={4} />
        <Typography variant="subtitle2">보상 방법</Typography>
        <Box m={1} />
        <>
          <Accordion expanded={hasCampaignReward}>
            <AccordionSummary>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography style={{ lineHeight: '34px' }}>부스트</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={hasCampaignReward}
                    color="primary"
                    onClick={() => {
                      clearErrors('boostCampaignId');
                      clearErrors('boltPolicyId');
                      setHasCampaignReward(!hasCampaignReward);
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box flexGrow="1">
                <ChaiAutoCompleteList<IBoostCampaignListItem>
                  name="boostCampaignId"
                  label="강화 폴리시 선택"
                  query={boostCampaignListQuery}
                  getId={(item: IBoostCampaignListItem) => +item.id}
                  getLabel={(item: IBoostCampaignListItem) => `${item.title}`}
                  getName={(item: IBoostCampaignListItem) => `${item.title} (${item.id})`}
                  makeFirstQueryVariable={(currentId?: number) => ({
                    pageSize: 10,
                    filter: currentId ? JSON.stringify({ id: currentId }) : undefined,
                  })}
                  makeQueryVariable={(searchString?: string) => ({
                    pageSize: 10,
                    filter: searchString?.trim() ? JSON.stringify({ title: searchString?.trim() }) : undefined,
                  })}
                />
                <ChaiTextContextForm
                  label="visibleToOffset (시간)"
                  name="visibleToOffsetHour"
                  type="number"
                  defaultValue={168}
                  min={0}
                  endAdornment={
                    <InputAdornment position="end">
                      {`(${`${Math.floor(visibleToOffsetHour / 24)}일 ` || ''}${visibleToOffsetHour % 24}시간)`}
                    </InputAdornment>
                  }
                />
                <ChaiTextContextForm
                  label="usableToOffset (시간)"
                  name="usableToOffsetHour"
                  type="number"
                  min={0}
                  defaultValue={168}
                  endAdornment={
                    <InputAdornment position="end">
                      {`(${`${Math.floor(usableToOffsetHour / 24)}일 ` || ''}${usableToOffsetHour % 24}시간)`}
                    </InputAdornment>
                  }
                />
                <ChaiTextContextForm
                  label="buyableToOffset (시간)"
                  name="buyableToOffsetHour"
                  type="number"
                  min={0}
                  defaultValue={168}
                  endAdornment={
                    <InputAdornment position="end">
                      {`(${`${Math.floor(buyableToOffsetHour / 24)}일 ` || ''}${buyableToOffsetHour % 24}시간)`}
                    </InputAdornment>
                  }
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={hasBoltReward}>
            <AccordionSummary>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography style={{ lineHeight: '34px' }}>번개</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={hasBoltReward}
                    color="primary"
                    onClick={() => {
                      clearErrors('boostCampaignId');
                      clearErrors('boltPolicyId');
                      setHasBoltReward(!hasBoltReward);
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box flexGrow="1">
                <ChaiAutoCompleteList<BoostBoltPolicyListItem>
                  name="boltPolicyId"
                  label="볼트 ID 찾기"
                  query={getBoltPolicyListQuery}
                  getId={(item: BoostBoltPolicyListItem) => +item.id}
                  getLabel={(item: BoostBoltPolicyListItem) => `${item.title}`}
                  getName={(item: BoostBoltPolicyListItem) => `${item.title} (${item.id})`}
                  makeFirstQueryVariable={(currentId?: number) => ({
                    pageSize: 10,
                    filter: currentId ? JSON.stringify({ id: currentId }) : undefined,
                  })}
                  makeQueryVariable={(searchString?: string) => ({
                    pageSize: 10,
                    filter: searchString?.trim() ? JSON.stringify({ title: searchString?.trim() }) : undefined,
                  })}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button onClick={() => handleCancel()}>취소</Button>
            <Button disabled={loading} onClick={handleSubmit(onSubmit)} color="primary">
              저장
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </FormProvider>
  );
};
