import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Box,
  Grid,
  Paper,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
  TextField,
  Checkbox,
  Button,
  Input,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import { IBrand } from 'api/brandGql';
import { getBoostImportantListQuery, IBoostImportant } from 'api/boostImportantGql';
import { ChaiRadioForm, ChaiTextContextForm, ChaiTextForm } from 'common';
import { ChaiRichTextEditor } from 'common/component/ChaiRichTextEditor';
import ChaiDivider from 'common/component/ChaiDivider';
import styles from '../boost.module.scss';
import BoostPreviewComponent from '../component/BoostPreviewComponent';
import { BoostParams } from '../container/BoostContentContainer';
import { BoostPromotionBoostTag } from './BoostPromotionDialogBoostTag';
import { BoostPromotionBrandInformation } from './BoostPromotionDialogBrandInfo';
import { BoostPromotionBoostSetting, BOOST_TYPE_OPTIONS } from './BoostPromotionDialogBoostSetting';
import { isValidUrl } from 'util/isValidUrl';

export interface BoostImportantForm {
  description?: string;
  landingUrl?: string;
  editorContent?: string;
  promotion: any;
  subTitle: string;
  type: string;
  isEditing: boolean;
  selectedSortKeyList: string[];
}

const ONOFFLINE_TYPE_OPTIONS = [
  { label: '온라인', value: 'online' },
  { label: '오프라인', value: 'offline' },
  { label: '온/오프라인', value: 'onoffline' },
];

const checkBoxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const BoostPromotionDialogContent: React.FC<{
  page: number;
  brandInformation?: IBrand;
}> = ({ page, brandInformation }) => {
  const { promotionId, action } = useParams<BoostParams>();
  const { getValues, setValue, watch } = useFormContext<any>();
  const formValues = getValues();

  const PageContent = () => {
    switch (page) {
      case 0:
        return <RenderPageBoostInformation promotionId={promotionId} />;
      case 1:
        return <BoostPromotionBrandInformation />;
      case 2:
        return <BoostPromotionBoostSetting />;
      case 3:
        return <RenderPageBoostImportant />;
      case 4:
        return <BoostPromotionBoostTag />;
      case 5:
        return <RenderPageSummary values={formValues} />;
    }
  };

  const { type } = formValues;

  const discountType = watch('discountType');
  const benefitDescription = watch('benefitDescription');
  const boltPrice = watch('boltPrice');
  const title = watch('title');
  const logoFileInput: string = watch('logoImageUrl');
  const subTitle = watch('subTitle');

  let previewProps: any = {
    name: subTitle || brandInformation?.name || '',
    color: brandInformation?.color,
    logoImg: logoFileInput || brandInformation?.logoImageUrl || '',
  };

  if (page >= 2 || action === 'copy') {
    previewProps = {
      ...previewProps,
      type: type,
      subText: title,
      smallText: benefitDescription,
      bolt: boltPrice || '0',
    };
  }

  // 부스트 값 보정
  useEffect(() => {
    const promotionDiscount = getValues('promotion.discount');
    if (promotionDiscount) {
      const discount = `${promotionDiscount}`;
      setValue('promotion.discount', discount.replace(/%/g, ''));
    }
  }, [discountType, getValues, setValue]);

  // 복사
  if (action === 'copy') {
    return (
      <>
        <Grid container spacing={6}>
          <Grid item xs={8}>
            {/* 신규 부스트로 간주 */}
            <RenderPageBoostInformation promotionId="new" />
            <BoostPromotionBrandInformation />
            <Box m={1} />
            <BoostPromotionBoostSetting />
            <Box m={1} />
            <RenderPageBoostImportant />
            <Box m={1} />
            <BoostPromotionBoostTag />
          </Grid>
          <Grid item xs={4}>
            <Box className={styles.sticky} style={{ top: 0, right: 0 }}>
              <Typography variant="subtitle2" color="textSecondary">
                미리보기
              </Typography>
              <Box m={1} />
              <BoostPreviewComponent {...previewProps} />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={page === 3 ? 11 : 8}>
        {PageContent()}
      </Grid>
      {page !== 3 && (
        <Grid item xs={4}>
          <>
            <Typography variant="subtitle2" color="textSecondary">
              미리보기
            </Typography>
            <Box m={1} />
            <BoostPreviewComponent {...previewProps} />
          </>
        </Grid>
      )}
    </Grid>
  );
};

// 1페이지: 부스트 정보
// 프로모션 ID, 타이틀
const RenderPageBoostInformation: React.FC<{
  promotionId: string | undefined;
}> = ({ promotionId }) => (
  <>
    <ChaiTextForm
      label="부스트 프로모션 ID"
      value={promotionId === 'new' ? '프로모션 ID는 자동으로 부여됩니다' : promotionId}
      disabled
    />
    <ChaiTextContextForm label="타이틀" name="boostName" required shrink />
  </>
);

// 4페이지: 유의사항
const RenderPageBoostImportant: React.FC<{}> = () => {
  const { watch, setValue, getValues } = useFormContext<BoostImportantForm>();

  const { data: optionalBoostImportantList, loading } = useQuery(getBoostImportantListQuery, {
    variables: { table: 'importantOptional' },
    notifyOnNetworkStatusChange: true,
  });

  const { data: requiredBoostImportantList } = useQuery(getBoostImportantListQuery, {
    variables: { table: 'importantRequired' },
    notifyOnNetworkStatusChange: true,
  });

  const optionalImportantList = optionalBoostImportantList?.boostImportantList?.list || [];
  const requiredImportantList = requiredBoostImportantList?.boostImportantList?.list || [];

  const filteredRequiredImportantList = requiredImportantList.filter((important: IBoostImportant) => {
    const defaultImportantType = ['title', 'default'];
    const additionalImportantType = ['time_attack', 'fcfs', 'recurring'];

    additionalImportantType.forEach(type => {
      if (getValues('type') === type) {
        defaultImportantType.push(type);
      }
    });

    return important.type && defaultImportantType.includes(important.type);
  });

  const defaultImportantSortKey = filteredRequiredImportantList.map((important: IBoostImportant) => important.sortKey);

  const selectedSortKeyList = watch('selectedSortKeyList') || defaultImportantSortKey;
  const description = getValues('description') || '';
  const isEditing = watch('isEditing');

  const importantList: IBoostImportant[] = optionalImportantList.concat(filteredRequiredImportantList);
  const targetImportantList = importantList
    .filter(important => selectedSortKeyList?.find(sortKey => important.sortKey === sortKey))
    .sort((a, b) => +a.priority - +b.priority);

  const handleReset = useCallback(() => {
    setValue('selectedSortKeyList', defaultImportantSortKey);
    setValue('editorContent', '');
    setValue('description', '');
    setValue('isEditing', false);
  }, [setValue, defaultImportantSortKey]);

  if (loading) return null;

  return (
    <Grid>
      <ChaiRadioForm context name="onOffLineStatus" label="온/오프라인" required values={ONOFFLINE_TYPE_OPTIONS} />
      <Box m={2} />
      <ChaiTextContextForm label="바깥 주요 유의사항" name="important" multiline required />
      <Box m={2} />
      {isEditing || description ? (
        <Grid>
          <BoostImportantTextEditor description={description} />
          <Box m={4} />
          <Grid container justifyContent="center">
            <Button variant="outlined" startIcon={<HistoryIcon />} onClick={() => handleReset()}>
              초기화
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          <BoostImportantAutocomplete list={importantList} targetList={targetImportantList} />
          <Grid container justifyContent="center">
            <Button variant="outlined" startIcon={<EditIcon />} onClick={() => setValue('isEditing', true)}>
              유의사항 편집하기
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const BoostImportantAutocomplete = ({
  list,
  targetList,
}: {
  list: IBoostImportant[];
  targetList: IBoostImportant[];
}) => {
  const { getValues, setValue } = useFormContext<BoostImportantForm>();
  const brandName = getValues('subTitle');
  const brandLink = getValues('landingUrl');
  const targetUrl = (brandLink && isValidUrl(brandLink) && new URL(brandLink).origin) || 'Brand Link';
  const promotion = getValues('promotion');
  const priceMin = promotion?.priceMin.toLocaleString();

  const editorContent = targetList.reduce((acc, curr, index) => {
    const content = curr.content
      .replaceAll('{brandName}', brandName)
      .replaceAll('{priceMin}', priceMin)
      .replaceAll('{brandLink}', targetUrl);

    return index !== 0 ? acc + '\n\n' + content : acc + content;
  }, '');

  useEffect(() => {
    setValue('editorContent', editorContent);
  }, [setValue, editorContent]);

  return (
    <>
      <Grid>
        <Autocomplete
          multiple
          options={list}
          disableCloseOnSelect
          getOptionLabel={(option: IBoostImportant) => option?.title}
          onChange={(e, values) =>
            setValue(
              'selectedSortKeyList',
              values.map(important => important?.sortKey)
            )
          }
          value={targetList}
          renderInput={params => <TextField {...params} value="" variant="standard" label="프로모션 유의사항" />}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option?.sortKey}>
              <Checkbox icon={checkBoxIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option?.title}
            </li>
          )}
        />
      </Grid>
      <Grid sx={{ overflowY: 'auto', maxHeight: 200, borderBottom: '1px solid #e9e9e9' }}>
        <Box>
          {targetList.map((importantItem, index) => (
            <Paper key={importantItem?.sortKey || index}>
              <BoostImportantItem importantItem={importantItem} />
            </Paper>
          ))}
        </Box>
      </Grid>
      <Box m={2} />
    </>
  );
};

const BoostImportantItem = ({ importantItem }: { importantItem: IBoostImportant }) => {
  const { getValues } = useFormContext<BoostImportantForm>();
  if (!importantItem) {
    return null;
  }

  const brandName = getValues('subTitle');
  const brandLink = getValues('landingUrl');
  const targetUrl = (brandLink && isValidUrl(brandLink) && new URL(brandLink).origin) || 'Brand Link';
  const promotion = getValues('promotion');
  const priceMin = promotion?.priceMin.toLocaleString();

  const { title, content } = importantItem;
  const editorContent = content
    .replaceAll('{brandName}', brandName)
    .replaceAll('{priceMin}', priceMin)
    .replaceAll('{brandLink}', targetUrl);

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={2}>
          <Box textAlign="center" style={{ overflow: 'hidden' }}>
            <Typography variant="subtitle2" className={styles.conditionOperator}>
              {title.length > 10 ? title.slice(0, 10) + '...' : title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Input
            fullWidth
            multiline
            disableUnderline
            readOnly
            sx={{ paddingTop: '8px', fontSize: 14 }}
            value={editorContent || ''}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const BoostImportantTextEditor: React.FC<{ description: string }> = ({ description }) => {
  const { watch, setValue } = useFormContext<BoostImportantForm>();
  const editorContent = watch('editorContent');

  return (
    <Grid>
      <Typography variant="caption">프로모션 유의사항</Typography>
      <Box m={1} />
      <Box style={{ height: '280px', overflowY: 'auto' }}>
        <TextField
          fullWidth
          multiline
          minRows="10"
          value={editorContent || description || ''}
          onChange={e => setValue('editorContent', e.target.value)}
        />
      </Box>
    </Grid>
  );
};

export const RenderPageSummary = ({ values }: { values: any }) => (
  <>
    <Typography variant="subtitle1" color="textSecondary">
      부스트 프로모션 정보
    </Typography>
    <ChaiDivider />
    <Box m={2} />
    <Grid container spacing={6}>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          부스트 프로모션 ID
        </Typography>
        <Box m={1} />
        <Typography>{values?.id}</Typography>
      </Grid>
    </Grid>
    <Grid container spacing={6}>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          타이틀*
        </Typography>
        <Box m={1} />
        <Typography>{values?.subTitle}</Typography>
      </Grid>
    </Grid>
    <Box m={6} />
    <Typography variant="subtitle1" color="textSecondary">
      부스트 설정
    </Typography>
    <ChaiDivider />
    <Box m={2} />
    <Grid container spacing={6}>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          부스트 타입
        </Typography>
        <Box m={1} />
        <ToggleButtonGroup exclusive aria-label="text alignment" value="normal">
          <ToggleButton value="normal" aria-label="left aligned" size="small" className={styles.toggleButton}>
            {BOOST_TYPE_OPTIONS.find(item => item.value === values?.type)?.label || ''}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {values?.type === 'fcfs' && (
        <Grid item>
          <Typography variant="subtitle2" color="textSecondary">
            선착순 구매 가능 수
          </Typography>
          <Box m={1} />
          <ToggleButtonGroup exclusive aria-label="text alignment" value="normal">
            <ToggleButton value="normal" aria-label="left aligned" size="small" className={styles.toggleButton}>
              {values?.countCap.toLocaleString()}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          {values?.discountType === 'percent' || String(values?.promotion?.discount)?.indexOf('%') > 0
            ? '할인율'
            : '할인액'}
        </Typography>
        <Box m={1} />
        <ToggleButtonGroup exclusive aria-label="text alignment" value="normal">
          <ToggleButton value="normal" aria-label="left aligned" size="small" className={styles.toggleButton}>
            {typeof values?.promotion?.discount === 'string'
              ? (+values?.promotion?.discount?.replace('%', '')).toLocaleString()
              : (+values?.promotion?.discount).toLocaleString()}
            {values?.discountType === 'percent' || String(values?.promotion?.discount)?.indexOf('%') > 0 ? '%' : '원'}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          최대할인금액
        </Typography>
        <Box m={1} />
        <ToggleButtonGroup exclusive aria-label="text alignment" value="normal">
          <ToggleButton value="normal" aria-label="left aligned" size="small" className={styles.toggleButton}>
            {values?.promotion?.maxDiscountAmount?.toLocaleString() || 0}원
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          최소결제금액
        </Typography>
        <Box m={1} />
        <ToggleButtonGroup exclusive aria-label="text alignment" value="normal">
          <ToggleButton value="normal" aria-label="left aligned" size="small" className={styles.toggleButton}>
            {values?.promotion?.priceMin?.toLocaleString() || 0}원
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          필요 번개 수
        </Typography>
        <Box m={1} />
        <ToggleButtonGroup exclusive aria-label="text alignment" value="normal">
          <ToggleButton value="normal" aria-label="left aligned" size="small" className={styles.toggleButton}>
            {values?.boltPrice || '0'}개
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
    <Box m={6} />
    <Typography variant="subtitle1" color="textSecondary">
      유의사항
    </Typography>
    <ChaiDivider />
    <Box m={1} />
    <Typography variant="subtitle2" color="textSecondary">
      온/오프라인
    </Typography>
    <Box m={1} />
    <ToggleButtonGroup exclusive aria-label="text alignment" value="normal">
      <ToggleButton value="normal" aria-label="left aligned" size="small" className={styles.toggleButton}>
        {ONOFFLINE_TYPE_OPTIONS.find(item => item.value === values?.onOffLineStatus)?.label || ''}
      </ToggleButton>
    </ToggleButtonGroup>
    <Box m={1} />
    <ChaiTextForm label="바깥 주요 유의사항" readOnly value={values?.important || ''} shrink />
    <Box m={1} />
    <Typography variant="caption">프로모션 유의사항</Typography>
    <ChaiRichTextEditor theme="bubble" value={values?.editorContent || values?.description || ''} readOnly />
  </>
);
