import { FormControl, FormGroup } from '@mui/material';

const ChaiFormGroup: React.FC<{}> = ({ children }) => {
  return (
    <FormControl required component="fieldset">
      <FormGroup row>{children}</FormGroup>
    </FormControl>
  );
};

export default ChaiFormGroup;
