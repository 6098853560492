import gql from 'graphql-tag';

export const boostTargetConditionListQuery = gql`
  query boostTargetConditionList($skip: Int, $pageSize: Int, $filter: String) {
    boostTargetConditionList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        title
        condition
      }
      total
    }
  }
`;

export const boostTargetConditionQuery = gql`
  query boostTargetCondition($id: Int!) {
    boostTargetCondition(id: $id) {
      id
      title
      description
      condition
    }
  }
`;

export const paymentTagListQuery = gql`
  query paymentTagList($skip: Int, $pageSize: Int, $filter: String) {
    paymentTagList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        name
        # description
        condition
      }
      total
    }
  }
`;

export const paymentTagQuery = gql`
  query paymentTag($id: Int!) {
    paymentTag(id: $id) {
      id
      name
      condition
    }
  }
`;

export const merchantListQuery = gql`
  query merchantList($filter: String, $skip: Int, $pageSize: Int) {
    merchantList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        name
        displayName
      }
      total
    }
  }
`;

export const categoryTagQuery = gql`
  query paymentCategoryTag($id: Int!) {
    paymentCategoryTag(id: $id) {
      id
      name
      paymentTags {
        id
        name
      }
    }
  }
`;

export const categoryTagListQuery = gql`
  query paymentCategoryTagList($filter: String, $skip: Int, $pageSize: Int) {
    paymentCategoryTagList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        name
        paymentTags {
          id
          name
        }
      }
      total
    }
  }
`;

export const selectGroupMerchantMgmtListMutation = gql`
  mutation selectGroupMerchantMgmtList(
    $inputGroupMerchantNo: String
    $inputGroupMerchantName: String
    $inputMerchantNo: String
    $inputGubun: String
    $inputCancelYn: String
  ) {
    selectGroupMerchantMgmtList(
      groupMerchantNo: $inputGroupMerchantNo
      groupMerchantName: $inputGroupMerchantName
      merchantNo: $inputMerchantNo
      gubun: $inputGubun
      cancelYn: $inputCancelYn
    ) {
      groupMerchantNo
      groupMerchantName
    }
  }
`;

export const businessCodeListQuery = gql`
  mutation selectCommonCodeInfoList($typeCode: String, $groupCode: String, $codeName: String) {
    selectCommonCodeInfoList(typeCode: $typeCode, groupCode: $groupCode, codeName: $codeName) {
      codeName
      codeId
    }
  }
`;

export const boostTagAddMutate = gql`
  mutation boostTagAddMutate($title: String!, $description: String, $condition: JSON!) {
    boostTargetConditionAdd(title: $title, description: $description, condition: $condition)
  }
`;

export const boostTagUpdateMutate = gql`
  mutation boostTagUpdateMutate($id: Int!, $title: String!, $description: String, $condition: JSON!) {
    boostTargetConditionUpdate(id: $id, title: $title, description: $description, condition: $condition)
  }
`;

export const categoryTagAddMutate = gql`
  mutation paymentCategoryTagAdd($name: String!, $paymentTagIds: [Int]) {
    paymentCategoryTagAdd(name: $name, paymentTagIds: $paymentTagIds)
  }
`;

export const categoryTagUpdateMutate = gql`
  mutation paymentCategoryTagUpdate($id: Int!, $name: String!, $paymentTagIds: [Int]) {
    paymentCategoryTagUpdate(id: $id, name: $name, paymentTagIds: $paymentTagIds)
  }
`;
export const boostPaymentTagAddMutate = gql`
  mutation paymentTagAdd($name: String!, $condition: JSON!) {
    paymentTagAdd(name: $name, condition: $condition)
  }
`;

export const boostPaymentTagUpdateMutate = gql`
  mutation paymentTagUpdate($id: Int!, $name: String!, $condition: JSON!) {
    paymentTagUpdate(id: $id, name: $name, condition: $condition)
  }
`;

export interface IBoostPromotion {
  id: number;
  title: string;
  subTitle: string;
  type: string;
  boltPrice: number;
  status: string;
  promotion: {
    createdAt: Date;
    updatedAt: Date;
    discount: number;
    priceMin: number;
    maxDiscountAmount: number;
  };
}

export interface IBoostPromotionListItem {
  id: number;
  title: string;
  subTitle: string;
  type: string;
  boltPrice: number;
  status: string;
  promotion: {
    createdAt: Date;
    updatedAt: Date;
    discount: number;
    priceMin: number;
    maxDiscountAmount: number;
  };
}

export interface IBoostList {
  list: [IBoostPromotionListItem];
  total: number;
}
