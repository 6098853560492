import gql from 'graphql-tag';

export const authMutation = gql`
  mutation auth($publicKey: String) {
    auth(publicKey: $publicKey) {
      id
      uid
      phone
      fullname
      passwordReset
      token
      otp
      groups {
        id
        name
        code
        description
        actions {
          id
          name
          code
          description
        }
      }
    }
  }
`;

export const logoutMutation = gql`
  mutation logout {
    logout
  }
`;

export const extendSessionMutation = gql`
  mutation extendSession {
    extendSession
  }
`;
