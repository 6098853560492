import { BoostTagComparisonType, BoostTagLogicalType } from 'type/tag/common';
import { IPaymentTagConditionGroupItem, IPaymentTagConditionSingleItem } from 'type/tag/paymentTag';

// form value를 서버에 전송하기 위해 변환
export const makePayloadArray = (condition: IPaymentTagConditionSingleItem | IPaymentTagConditionGroupItem) => {
  if ('operator' in condition && 'items' in condition) {
    return {
      [condition.operator || BoostTagLogicalType.AND]: condition.items.map(value => {
        if (value.comparison === BoostTagComparisonType.NOTEQUAL) {
          return {
            ...value,
            comparison: BoostTagComparisonType.EQUAL,
            not: true,
          };
        }
        if (value.comparison === BoostTagComparisonType.NOTINCLUDE) {
          return {
            ...value,
            comparison: BoostTagComparisonType.INCLUDE,
            not: true,
          };
        }
        return value;
      }),
    };
  }
  if (condition.comparison === BoostTagComparisonType.NOTEQUAL) {
    return {
      ...condition,
      comparison: BoostTagComparisonType.EQUAL,
      not: true,
    };
  }
  if (condition.comparison === BoostTagComparisonType.NOTINCLUDE) {
    return {
      ...condition,
      comparison: BoostTagComparisonType.INCLUDE,
      not: true,
    };
  }
  return condition;
};
