import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { boostBudgetListQuery, IBoostBudget } from 'api/boostBudgetGql';
import { IBrand } from 'api/brandGql';
import ChaiDivider from 'common/component/ChaiDivider';
import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getValue } from 'util/get';
import { BoostParams } from './BoostContentContainer';
import { BoostContentTitle } from '../component/BoostContentTitle';
import { BoostBudgetDialogContainer } from './BoostBudgetDialogContainer';

interface Column {
  id: string;
  label: string;
  type?: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: string | number, row: IBoostBudget) => string | JSX.Element;
  formatDate?: string;
  link?: boolean;
}

function convertType(type: string) {
  return (
    {
      unit_price: '단가',
      ratio: '분담',
      chai_only: '차이 부담',
    }[type] || type
  );
}

const columns: Column[] = [
  {
    id: 'title',
    label: '타이틀',
    minWidth: 170,
    link: true,
  },
  {
    id: 'type',
    label: '타입',
    format: type => convertType(type as string),
  },
  {
    id: 'budgetTotal',
    label: '전체 금액',
    align: 'right',
    format: value => `${value?.toLocaleString() || 0}원`,
  },
  {
    id: 'dailyCap',
    label: '하루 최대 금액',
    align: 'right',
    format: value => `${value?.toLocaleString() || 0}원`,
  },
  {
    id: 'remainingTotal',
    label: '남은 금액',
    align: 'right',
    format: value => `${value?.toLocaleString() || 0}원`,
  },
  {
    id: 'status',
    label: '상태',
    align: 'center',
    format: value => (value === 'enabled' ? '활성' : <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>비활성</span>),
  },
];

export const BoostBudgetContainer: React.FC<{ brandInformation?: IBrand }> = () => {
  const history = useHistory();
  const { brandId, budgetId } = useParams<BoostParams>();
  const budgetDialogOpen = Boolean(budgetId);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data, refetch: refetchBudgetList } = useQuery(boostBudgetListQuery, {
    variables: { skip: page * rowsPerPage, pageSize: rowsPerPage, filter: JSON.stringify({ brandId }) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: brandId === 'new' ? 'cache-only' : 'network-only', // apollo-client 버그 walk-around
  });

  const budgetList: IBoostBudget[] = data?.boostBudgetList?.list || [];
  const budgetListTotal: number = data?.boostBudgetList?.total;

  const handleAddBudget = () => {
    history.push(`/boost/${brandId}/budget/new`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (brandId && brandId !== 'new' && !budgetId) {
      refetchBudgetList();
    }
  }, [brandId, budgetId, refetchBudgetList]);

  return (
    <>
      <BoostContentTitle title="예산">
        <Button variant="contained" size="small" onClick={handleAddBudget}>
          추가
        </Button>
      </BoostContentTitle>
      <ChaiDivider />
      <Box m={2} />
      <Grid container direction="column" justifyContent="center">
        <Typography variant="subtitle2" align="right" color="textSecondary">
          전체: {budgetListTotal || 0}
        </Typography>
        <Box m={1} />
        <TableContainer style={{ backgroundColor: 'white' }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={`boost-budget-table-header-${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetList.map((row: IBoostBudget) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`boost-budget-table-row-${row.id}`}>
                    {columns.map(column => {
                      const value = getValue(row, column.id);
                      let content;
                      if (column.formatDate) {
                        content = format(parseISO(value), column.formatDate);
                      } else if (column.format) {
                        content = column.format(value, row);
                      } else if (column.link) {
                        content = (
                          <Link to={`/boost/${brandId}/budget/${row.id}`} style={{ lineHeight: '24px' }}>
                            {value}
                          </Link>
                        );
                      } else {
                        content = value;
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {content}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 100]}
          count={budgetListTotal || rowsPerPage * page + 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
          maxWidth="md"
          open={budgetDialogOpen}
          fullWidth
          onClose={(_, reason) => reason !== 'backdropClick' && history.push(`/boost/${brandId}`)}
        >
          <BoostBudgetDialogContainer onDialogSubmit={refetchBudgetList} />
        </Dialog>
      </Grid>
    </>
  );
};
